import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import CardContent from "@mui/material/CardContent";
import { Paper } from "@mui/material";
import { styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import { ColorButton, IOSSwitch } from "../common/CustomElement";
import { Button } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CommonTable from "../common/CommonTable";
import { useNavigate } from "react-router-dom";
import CustomImageModal from "../common/CustomImageModal";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { IsApprove, Url } from "../global";
import CommonFilter from "../common/CommonFilter";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import NewDate from "../common/NewDate";
import "./cashEntry.css";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(0),
  },
  "& .MuiInputBase-input": {
    borderRadius: 9,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "8px 26px 8px 10px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 9,
      borderColor: "#80bdff",
    },
  },
}));
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};
const CashEntryList = (prop) => {
  // console.log(prop);
  // const [param, setParam] = useState({
  //   start_date: "",
  //   end_date: "",
  // });
  const navigate = useNavigate();
  const [searchKeyword, setSearchKeyword] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [checkStatus, setcheckStatus] = useState("");
  const [open, setOpen] = useState(false);
  const [dialogTitle, setDialogTitle] = useState("");
  const [fullWidth, setFullWidth] = useState(true);
  const [resData, setResData] = useState({});
  const [arrayCash, setArrayCash] = useState({
    cash: {},
    isLoader: false,
  });
  const [param, setParam] = useState({
    entry_type: "1,2",
  });
  const [maxWidth, setMaxWidth] = useState("sm");
  const [searchBy, setSearchBy] = useState([
    {
      label: "ROLE NAME",
      value: false,
      name: "role",
    },
    {
      label: "NAME",
      value: false,
      name: "user_first_name",
    },
    {
      label: "Email",
      value: false,
      name: "user_email",
    },
    {
      label: "PAYMENT METHOD",
      value: false,
      name: "payment_method",
    },
    {
      label: "Phone Number",
      value: false,
      name: "user_phone",
    },
    {
      label: "MANAGER",
      value: false,
      name: "master_manager_name",
    },
    {
      label: "ACCOUNT TARGET",
      value: false,
      name: "ac_target",
    },
    {
      label: "MONEY IN TARGET",
      value: false,
      name: "master_manager_name",
    },
  ]);
  const re = /^[A-Za-z_ ]*$/;
  const [deleteCompany, setDeleteCopany] = useState({
    isLoader: false,
    company_id: "",
  });
  const handleClose = () => {
    setOpen(false);
  };

  toast.configure();

  const chanestatus = (p1, id) => {
    const param = new FormData();
    if (IsApprove !== "") {
      param.append("is_app", IsApprove.is_app);
      param.append("AADMIN_LOGIN_ID", IsApprove.AADMIN_LOGIN_ID);
      param.append("role_id", IsApprove.AADMIN_LOGIN_ROLE_ID);
    }
    param.append("ledger_id", id);
    param.append("is_active", p1 == false ? 0 : 1);

    axios
      .post(Url + "/ajaxfiles/ledger_status_change.php", param)
      .then((res) => {
        if (res.data.message == "Session has been expired") {
          localStorage.setItem("login", true);
          navigate("/login");
        }
        if (res.data.status == "error") {
          toast.error(res.data.message);
        } else {
          toast.success(res.data.message);
        }
      });
  };

  const getTableView = (entry_id) => {
    const param = new FormData();

    if (IsApprove !== "") {
      param.append("is_app", IsApprove.is_app);
      param.append("AADMIN_LOGIN_ID", IsApprove.AADMIN_LOGIN_ID);
      param.append("role_id", IsApprove.AADMIN_LOGIN_ROLE_ID);
    }
    param.append("entry_id", entry_id);

    arrayCash.isLoader = true;
    setArrayCash({ ...arrayCash });
    axios
      .post(Url + "/ajaxfiles/view_receipt_payment_entry.php", param)
      .then((res) => {
        if (res.data.message == "Session has been expired") {
          localStorage.setItem("login", true);
          navigate("/");
        }
        if (res.data.status == "error") {
        } else {
          arrayCash.cash = res.data.data;
          arrayCash.isLoader = false;
          setArrayCash({ ...arrayCash });
        }
      });
  };
  const formDelete = () => {
    const param = new FormData();

    if (IsApprove !== "") {
      param.append("is_app", IsApprove.is_app);
      param.append("AADMIN_LOGIN_ID", IsApprove.AADMIN_LOGIN_ID);
      param.append("role_id", IsApprove.AADMIN_LOGIN_ROLE_ID);
    }
    param.append("entry_id", deleteCompany.company_id);
    deleteCompany.isLoader = true;
    setDeleteCopany({ ...deleteCompany });
    axios.post(Url + "/ajaxfiles/receipt_delete.php", param).then((res) => {
      if (res.data.message == "Session has been expired") {
        localStorage.setItem("login", true);
        navigate("/");
      }
      if (res.data.status == "error") {
        toast.error(res.data.message);
        deleteCompany.isLoader = false;
        setDeleteCopany({ ...deleteCompany });
      } else {
        toast.success(res.data.message);
        deleteCompany.isLoader = false;
        setDeleteCopany({ ...deleteCompany });
        setOpen(false);
        setRefresh(!refresh);
      }
    });
  };
  const manageContent = () => {
    if (dialogTitle == "Delete Cash/Bank Entry") {
      return (
        <>
          <div>
            <h1>Are you sure ?</h1>
            Do you want to sure delete this Cash/Bank Entry ?
          </div>
        </>
      );
    } else if (dialogTitle == "Receipt" || dialogTitle == "Payment") {
      return (
        <>
          {arrayCash.isLoader == true ? (
            <span class="poploader"></span>
          ) : (
            <div>
              <div className="cashView1">
                <div>
                  <div
                    style={{
                      fontSize: "24px",
                      textAlign: "center",
                      fontWeight: 700,
                      color: "white",
                    }}
                  >
                    {arrayCash.cash.company_name}
                  </div>
                  <div
                    style={{
                      fontSize: "13px",
                      textAlign: "center",
                      color: "grey",
                    }}
                  >
                    {" "}
                    {arrayCash.cash.location_name}
                  </div>
                </div>
              </div>
              <div className="cashView">
                <div>Ledger Name :- {arrayCash.cash.ledger_name}</div>
                <div>Date :- {arrayCash.cash.added_datetime}</div>
              </div>
              <div className="cashView">
                <div>Document Number :- {arrayCash.cash.document_no}</div>
                <div>
                  Entry Date :- <NewDate newDate={arrayCash.cash.entry_date} />
                </div>
              </div>
              <div className="cashView">
                <div>
                  Document refrence :- {arrayCash.cash.document_refrence}
                </div>
                <div>Entry No :- #{arrayCash.cash.entry_id}</div>
              </div>
              <table className="tableforriskscroe w-100">
                <thead>
                  <tr>
                    <th>PARTICULARS</th>
                    <th>AMOUNT({arrayCash.cash.currency_name})</th>
                    <th>RATE</th>
                    <th>BASE AMOUNT</th>
                  </tr>
                </thead>
                <tbody>
                  {arrayCash.cash.perticulars.map((item) => {
                    return (
                      <>
                        <tr>
                          <td>{item.Particulars.ledger_name}</td>
                          <td>{item.amount}</td>
                          <td>{item.rate}</td>

                          <td>{item.base_amount}</td>
                          <td></td>
                        </tr>
                      </>
                    );
                  })}
                  <tr>
                    <td>
                      <b>TOTAL RECEIVED AMT</b>
                    </td>
                    <td>
                      <b>
                        {" "}
                        {arrayCash.cash.perticulars
                          .reduce(
                            (a, v) => (a = Number(a) + Number(v.amount)),
                            ""
                          )
                          .toFixed(2)}
                      </b>
                    </td>
                    <td></td>
                    <td>
                      <b>
                        {" "}
                        {arrayCash.cash.perticulars
                          .reduce(
                            (a, v) => (a = Number(a) + Number(v.base_amount)),
                            ""
                          )
                          .toFixed(2)}
                      </b>
                    </td>
                  </tr>{" "}
                </tbody>
              </table>
            </div>
          )}
        </>
      );
    }
  };
  const manageDialogActionButton = () => {
    if (dialogTitle == "Delete Cash/Bank Entry") {
      return (
        <div className="dialogMultipleActionButton">
          <Button
            variant="contained"
            className="cancelButton"
            onClick={handleClose}
          >
            Cancel
          </Button>
          {deleteCompany.isLoader ? (
            <Button
              tabindex="0"
              size="large"
              sx={{ padding: "12px 50px" }}
              className=" btn-gradient  btn-danger"
              disabled
            >
              <svg class="spinner" viewBox="0 0 50 50">
                <circle
                  class="path"
                  cx="25"
                  cy="25"
                  r="20"
                  fill="none"
                  stroke-width="5"
                ></circle>
              </svg>
            </Button>
          ) : (
            <Button
              variant="contained"
              className="btn-gradient btn-danger"
              onClick={formDelete}
            >
              Delete
            </Button>
          )}
        </div>
      );
    }
  };
  const columns = [
    {
      name: "SR.NO",
      selector: (row) => {
        return <span>{row.sr_no}</span>;
      },
      reorder: true,
      wrap: true,
      grow: 0.1,
    },
    {
      name: "entry id",
      selector: (row) => {
        return <span title={row.entry_id_no}>{row.entry_id_no}</span>;
      },
      sortable: true,
      reorder: true,
      grow: 0.3,
      wrap: true,
    },

    {
      name: "entry date",
      selector: (row) => {
        return (
          <span title={row.entry_date}>
            {" "}
            <NewDate newDate={row.entry_date} />
          </span>
        );
      },
      sortable: true,
      reorder: true,
      grow: 1,
      wrap: true,
    },

    {
      name: "ledger name",
      selector: (row) => {
        return <span title={row.opp_ledger_name}> {row.opp_ledger_name} </span>;
      },
      sortable: true,
      reorder: true,
      grow: 0.7,
      wrap: true,
    },
    {
      name: "salesman",
      selector: (row) => {
        return <span title={row.manager_name}> {row.manager_name} </span>;
      },
      sortable: true,
      reorder: true,
      grow: 0.7,
      wrap: true,
    },
    {
      name: "company",
      selector: (row) => {
        return <span title={row.ledger_company}>{row.ledger_company}</span>;
      },
      sortable: true,
      reorder: true,
      grow: 0.9,
      wrap: true,
    },
    {
      name: "amount",
      selector: (row) => {
        return <span title={row.amount}>{row.amount}</span>;
      },
      sortable: true,
      reorder: true,
      grow: 0.7,
      wrap: true,
    },
    {
      name: "document Number",
      selector: (row) => {
        return <span title={row.document_no}>{row.document_no}</span>;
      },
      // sortable: true,
      reorder: true,
      grow: 0.1,
      //   wrap: true,
    },

    {
      name: "document refrence",
      selector: (row) => {
        return (
          <span title={row.document_refrence}>{row.document_refrence}</span>
        );
      },
      sortable: true,
      reorder: true,
      grow: 2,
      //   wrap: true,
    },
    {
      name: "remarks",
      selector: (row) => {
        return <span title={row.remarks}>{row.remarks}</span>;
      },
      sortable: true,
      reorder: true,
      grow: 0.3,
      wrap: true,
    },
    {
      name: "added datetime",
      selector: (row) => {
        return <span title={row.added_datetime}>{row.added_datetime}</span>;
      },
      sortable: true,
      reorder: true,
      grow: 0.5,
      wrap: true,
    },
    {
      name: "View",
      selector: (row) => {
        return (
          <span>
            <Button
              onClick={() => {
                getTableView(row.entry_id);
                if (row.entry_type == "1") {
                  setDialogTitle("Receipt");
                } else {
                  setDialogTitle("Payment");
                }
                setOpen(true);
              }}
            >
              <VisibilityIcon />
            </Button>
          </span>
        );
      },
      sortable: true,
      reorder: true,
      grow: 0.5,
      wrap: true,
    },
    {
      name: "Action",
      selector: (row) => {
        return (
          <span style={{ display: "flex" }}>
            <Button>
              <span
                className="material-icons  icon_Mar"
                style={{ color: "green" }}
                onClick={() => {
                  navigate(`/edit_reciipt_form/${row.entry_id}`);
                }}
              >
                edit
              </span>
            </Button>
            <Button>
              <span
                className="material-icons  icon_Mar"
                style={{ color: "red" }}
                onClick={() => {
                  setDialogTitle("Delete Cash/Bank Entry");
                  deleteCompany.isLoader = false;
                  deleteCompany.company_id = row.entry_id;
                  setDeleteCopany({ ...deleteCompany });
                  setOpen(true);
                }}
              >
                delete
              </span>
            </Button>
          </span>
        );
      },
      sortable: true,
      reorder: true,
      grow: 0.3,
      wrap: true,
    },
  ];

  return (
    <div>
      <div className="app-content--inner">
        <div className="app-content--inner__wrapper mh-100-vh">
          <div style={{ opacity: 1 }}>
            <Grid container>
              <Grid item md={12} lg={12} xl={12}>
                <p className="main-heading">Cash/Bank Entry</p>
                {/* <CommonFilter
                  search={searchBy}
                  setParam={setParam}
                  searchWord={setSearchKeyword}
                  setcheckStatus={setcheckStatus}
                  lastUpdatedBy={resData.modified_by_users}
                />
                <br /> */}

                <Paper
                  elevation={2}
                  style={{ borderRadius: "10px" }}
                  className="pending-all-15px"
                >
                  <CardContent className="py-3">
                    <Grid container spacing={2}>
                      <Grid item sm={12} md={12} lg={12}>
                        <ColorButton
                          onClick={() => {
                            navigate("/reciipt_form");
                          }}
                        >
                          Add Cash/Bank Entry
                        </ColorButton>

                        <CommonTable
                          url={`${Url}/datatable/cash_entry_list.php`}
                          column={columns}
                          sort="0"
                          refresh={refresh}
                          search={searchBy}
                          param={param}
                          searchWord={searchKeyword}
                          checkStatus={checkStatus}
                          setResData={setResData}
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Paper>
              </Grid>
              <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                className="modalWidth100"
                fullWidth={fullWidth}
                maxWidth={maxWidth}
              >
                <BootstrapDialogTitle
                  id="customized-dialog-title"
                  className="dialogTitle"
                  onClose={handleClose}
                >
                  {dialogTitle}
                </BootstrapDialogTitle>
                <DialogContent dividers>{manageContent()}</DialogContent>
                <DialogActions>{manageDialogActionButton()}</DialogActions>
              </BootstrapDialog>
            </Grid>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CashEntryList;
