import { Check, Image, Info } from "@mui/icons-material";
import {
  Autocomplete,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Checkbox,
  Select,
  TextareaAutosize,
  TextField,
  DialogContent,
  DialogActions,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import axios from "axios";
import CreateIcon from "@mui/icons-material/Create";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  BootstrapDialog,
  BootstrapDialogTitle,
  ColorButton,
} from "../common/CustomElement";
import { IsApprove, Url } from "../global";
import { useSelector, useDispatch } from "react-redux";
import { checkLogin } from "../redux/Action";
import CommonLogin from "../common/CommonLogin";
import "./Ledger_master.css";

const Ledger_master = () => {
  // const changeLogin = useSelector((state) => state.changeLogin);
  const dispatch = useDispatch();
  const { id } = useParams();
  const navigate = useNavigate();
  const defult = true;

  const [mainLoader, setMainLoader] = useState({
    country: false,
    city: false,
    currencyName: false,
    commonFormet: false,
    state: false,
    editPage: false,
  });
  const [address, setAddress] = useState({
    add1: "",
    add: "",
    country: "",
    state: "",
    city: "",
  });
  const [addresst, setAddresst] = useState({
    add1: false,
    add: false,
    country: false,
    state: false,
    city: false,
  });
  const [countryData, setCountryData] = useState({
    country: [],
    city: [],
    state: [],
    currencyName: [],
    commonFormet: [],
  });
  const [companyArray, setCompanyArray] = useState({
    data: {},
    id: [],
    id1: [],
    onEdit: false,
    isLoader: false,
  });
  const trueFalse2 = (event) => {
    var { name, value } = event.target;
    setAddresst((prevalue) => {
      return {
        ...prevalue,
        [name]: true,
      };
    });
  };
  const [arrData, setArrData] = useState({
    companyList: [],
    location: [],
    leadType: [],
  });
  const input2 = (event) => {
    var { name, value } = event.target;
    setAddress((prevalue) => {
      return {
        ...prevalue,
        [name]: value,
      };
    });
  };

  const [info, setInfo] = useState({
    name: "",
    alias: "",
    alias2: "",
    leadType: "",
    company: "",
    location: "",
    email: "",
    adharCardFront: "",
    adharCardBack: "",
    panCard: "",
    mobilenumber: "",
    address: [],
    gstnumber: "",
    gstType: "",
    gstState: "",
    pancardNumber: "",
    tanNumber: "",
    remarks: "",
    isLodear: false,
    openingBalance: "credit",
    balance: "",
  });
  const [dialogTitle, setDialogTitle] = useState("");
  const [fullWidth, setFullWidth] = useState(true);
  const [maxWidth, setMaxWidth] = useState("xs");
  const [imagePreview, setimagePreview] = useState({
    panCard: "",
    adharCardFront: "",
    adharCardBack: "",
  });
  const input1 = (event) => {
    var { name, value } = event.target;
    setInfo((prevalue) => {
      return {
        ...prevalue,
        [name]: value,
      };
    });
  };
  const [open, setOpen] = useState(false);
  const [infoTrue, setinfoTrue] = useState({
    name: false,
    alias: false,
    alias2: false,
    leadType: false,
    company: false,
    location: false,
    email: false,
    mobilenumber: false,
    address: [],
    gstnumber: false,
    gstType: false,
    gstState: false,
    pancardNumber: false,
    tanNumber: false,
    remarks: false,
    balance: false,
    openingBalance: false,
  });
  const handleClose = () => {
    setOpen(false);
  };
  const trueFalse = (event) => {
    var { name, value } = event.target;
    setinfoTrue((prevalue) => {
      return {
        ...prevalue,
        [name]: true,
      };
    });
  };
  const changeImage = (event) => {
    var { name, files } = event.target;
    setInfo((prevalue) => {
      return {
        ...prevalue,
        [name]: files[0],
      };
    });
    setimagePreview((prevalue) => {
      return {
        ...prevalue,
        [name]: URL.createObjectURL(files[0]),
      };
    });
  };
  useEffect(() => {
    if (id) {
    } else {
      fatchCompanyArray();
    }

    getCompanyData();
    const param = new FormData();
    mainLoader.country = true;
    if (IsApprove !== "") {
      param.append("is_app", IsApprove.is_app);
      param.append("AADMIN_LOGIN_ID", IsApprove.AADMIN_LOGIN_ID);
      param.append("role_id", IsApprove.AADMIN_LOGIN_ROLE_ID);
    }
    setMainLoader({ ...mainLoader });
    axios.post(Url + "/datatable/get_countries.php", param).then((res) => {
      if (res.data.message == "Session has been expired") {
        localStorage.setItem("login", true);
        navigate("/login");
      }
      if (res.data.status == "error") {
        // toast.error(res.data.message);
      } else {
        mainLoader.country = false;
        setMainLoader({ ...mainLoader });
        countryData.country = res.data.aaData;
        setCountryData({ ...countryData });
      }
    });
  }, []);
  console.log("compnay", id);
  const fatchCompanyArray = () => {
    const param1 = new FormData();

    if (IsApprove !== "") {
      param1.append("is_app", IsApprove.is_app);
      param1.append("AADMIN_LOGIN_ID", IsApprove.AADMIN_LOGIN_ID);
      param1.append("role_id", IsApprove.AADMIN_LOGIN_ROLE_ID);
    }
    param1.append("action", "get_all_location");

    axios.post(Url + "/ajaxfiles/common_api.php", param1).then((res) => {
      if (res.data.message == "Session has been expired") {
        localStorage.setItem("login", true);
        navigate("/login");
      }
      if (res.data.status == "error") {
      } else {
        var checkBoxCId = [];
        companyArray.data = res.data.data;
        Object.keys(res.data.data).map((item, index) => {
          var checkBoxLId = [];
          res.data.data[item].location.map((item1, index1) => {
            checkBoxLId = [
              ...checkBoxLId,
              {
                location_id: item1.location_id,
                isChecked: false,
                location_name: item1.location_name,
              },
            ];
          });
          checkBoxCId = [
            ...checkBoxCId,
            {
              company: {
                company_id: item,
                isChecked: false,
                company_name: res.data.data[item].company_name.company_name,
              },
              location: checkBoxLId,
            },
          ];
        });

        companyArray.id = JSON.parse(JSON.stringify(checkBoxCId));
        companyArray.id1 = JSON.parse(JSON.stringify(checkBoxCId));
        setCompanyArray({ ...companyArray });
      }
    });
  };
  const submitCompany = () => {
    // var CompanyError = true;
    var newCompanyArray = JSON.parse(JSON.stringify(companyArray.id));

    var error = () => {
      var test = true;
      companyArray.id.map((item, index) => {
        if (item.company.isChecked == true) {
          var removeCheckedMark = false;
          item.location.map((item1, index1) => {
            if (item1.isChecked == true) {
              removeCheckedMark = true;
            }
          });
          newCompanyArray[index].company.isChecked = removeCheckedMark;
        }
        if (item.company.isChecked == true && test == true) {
          item.location.map((item1, index1) => {
            if (item1.isChecked == true && test == true) {
              test = false;
            }
          });
        }
      });
      return test;
    };
    if (error() == true) {
      toast.error("Company and Location  is required");
    } else {
      companyArray.id1 = JSON.parse(JSON.stringify(newCompanyArray));
      companyArray.onEdit = true;
      setCompanyArray({ ...companyArray });
      setOpen(false);
    }
  };
  useEffect(() => {
    if (id == undefined) {
    } else {
      const param = new FormData();
      if (IsApprove !== "") {
        param.append("is_app", IsApprove.is_app);
        param.append("AADMIN_LOGIN_ID", IsApprove.AADMIN_LOGIN_ID);
        param.append("role_id", IsApprove.AADMIN_LOGIN_ROLE_ID);
      }
      param.append("ledger_id", id);
      mainLoader.editPage = true;
      setMainLoader({ ...mainLoader });
      axios
        .post(Url + "/ajaxfiles/get_ledger_master.php", param)
        .then((res) => {
          if (res.data.status == "error") {
            toast.error(res.data.message);
          } else {
            // toast.success(res.data.message);
            info.name = res.data.data.ledger_name;
            info.alias = res.data.data.ledger_alias;
            info.alias2 = res.data.data.ledger_alias2;
            info.leadType = {
              ledger_group_id: res.data.data.ledger_group_id,
              ledger_group_name: res.data.data.ledger_type_name,
            };
            info.company = {
              company_id: res.data.data.ledger_company,
              company_name: res.data.data.ledger_company_name,
            };
            info.location = {
              location_id: res.data.data.ledger_location,
              location_name: res.data.data.ledger_location_name,
            };
            info.email = res.data.data.ledger_email;
            info.mobilenumber = res.data.data.ledger_mob_no;
            info.address = res.data.data.ledger_address;
            info.gstnumber = res.data.data.ledger_gst_no;
            info.gstState = res.data.data.ledger_gst_state;
            info.gstType = res.data.data.ledger_gst_type;
            info.pancardNumber = res.data.data.ledger_pan_no;
            info.remarks = res.data.data.remarks;
            info.adharCardFront = "";
            info.adharCardBack = "";
            info.panCard = "";
            info.isLodear = false;
            imagePreview.adharCardBack = res.data.data.aadhar_card_back_image;
            imagePreview.adharCardFront = res.data.data.aadhar_card_front_image;
            imagePreview.panCard = res.data.data.pancard_image;
            getLocations(info.company);
            setInfo({ ...info });
            mainLoader.editPage = false;
            setMainLoader({ ...mainLoader });
            setimagePreview({ ...imagePreview });
          }
        });
    }
  }, [id]);
  const getStateData = (prop, index) => {
    if (prop == null) {
      countryData.state = [];
      setCountryData({ ...countryData });
      address.state = "";
      address.city = "";
      setAddress({ ...address });
    } else {
      const param = new FormData();
      param.append("action", "get_states");
      param.append("country", prop.nicename);
      if (IsApprove !== "") {
        param.append("is_app", IsApprove.is_app);
        param.append("AADMIN_LOGIN_ID", IsApprove.AADMIN_LOGIN_ID);
        param.append("role_id", IsApprove.AADMIN_LOGIN_ROLE_ID);
      }
      axios.post(Url + "/ajaxfiles/common_api.php", param).then((res) => {
        if (res.data.message == "Session has been expired") {
          localStorage.setItem("login", true);
          navigate("/login");
        }
        if (res.data.status == "error") {
          // toast.error(res.data.message);
        } else {
          if (index != true) {
            address.state = "";
            address.city = "";
            setAddress({ ...address });
          }

          countryData.state = res.data.data;
          setCountryData({ ...countryData });
        }
      });
    }
  };
  const getCityData = (prop, index) => {
    if (prop == null) {
      countryData.city = [];
      setCountryData({ ...countryData });
      address.state = "";
      address.city = "";
      setAddress({ ...address });
    } else {
      const param = new FormData();
      param.append("action", "get_cities");
      if (IsApprove !== "") {
        param.append("is_app", IsApprove.is_app);
        param.append("AADMIN_LOGIN_ID", IsApprove.AADMIN_LOGIN_ID);
        param.append("role_id", IsApprove.AADMIN_LOGIN_ROLE_ID);
      }
      param.append("state", prop);
      axios.post(Url + "/ajaxfiles/common_api.php", param).then((res) => {
        if (res.data.message == "Session has been expired") {
          localStorage.setItem("login", true);
          navigate("/login");
        }
        if (res.data.status == "error") {
          // toast.error(res.data.message);
        } else {
          if (index != true) {
            address.city = "";
            setAddress({ ...address });
          }

          countryData.city = res.data.data;
          setCountryData({ ...countryData });
        }
      });
    }
  };
  const getCompanyData = () => {
    const param = new FormData();

    if (IsApprove !== "") {
      param.append("is_app", IsApprove.is_app);
      param.append("AADMIN_LOGIN_ID", IsApprove.AADMIN_LOGIN_ID);
      param.append("role_id", IsApprove.AADMIN_LOGIN_ROLE_ID);
    }

    axios.post(Url + "/ajaxfiles/get_company_list.php", param).then((res) => {
      if (res.data.message == "Session has been expired") {
        localStorage.setItem("login", true);
        navigate("/login");
      }
      if (res.data.status == "error") {
      } else {
        arrData.companyList = res.data.data;
        setArrData({ ...arrData });
      }
    });
  };
  const getLeadType = (prop) => {
    const param = new FormData();
    if (IsApprove !== "") {
      param.append("is_app", IsApprove.is_app);
      param.append("AADMIN_LOGIN_ID", IsApprove.AADMIN_LOGIN_ID);
      param.append("role_id", IsApprove.AADMIN_LOGIN_ROLE_ID);
    }
    param.append("action", "get_ledger_group_master");
    param.append("ledger_group_name", prop);

    axios.post(Url + "/ajaxfiles/common_api.php", param).then((res) => {
      if (res.data.message == "Session has been expired") {
        localStorage.setItem("login", true);
        navigate("/login");
      }
      if (res.data.status == "error") {
      } else {
        arrData.leadType = res.data.data;
        setArrData({ ...arrData });
      }
    });
  };
  const getLocations = (prop) => {
    if (prop == "" || prop == null) {
      info.location = "";
      setInfo({ ...info });
      arrData.location = [];
      setArrData({ ...arrData });
    } else {
      const param = new FormData();
      if (IsApprove !== "") {
        param.append("is_app", IsApprove.is_app);
        param.append("AADMIN_LOGIN_ID", IsApprove.AADMIN_LOGIN_ID);
        param.append("role_id", IsApprove.AADMIN_LOGIN_ROLE_ID);
      }
      param.append("company_id", prop.company_id);

      axios
        .post(Url + "/ajaxfiles/get_location_list.php", param)
        .then((res) => {
          if (res.data.message == "Session has been expired") {
            localStorage.setItem("login", true);
            navigate("/login");
          }
          if (res.data.status == "error") {
            toast.error(res.data.message);
          } else {
            arrData.location = res.data.data;
            setArrData({ ...arrData });

            // info.company_list = res.data.data;
            // setInfo({ ...info });
          }
        });
    }
  };
  const submitAddFrom = () => {
    if (info.name == "") {
      toast.error("Name is required");
    } else if (
      info.leadType == "" ||
      info.leadType == [] ||
      info.leadType == null
    ) {
      toast.error("Group Ledger is required");
    } else if (companyArray.onEdit == false) {
      toast.error("Company is required");
    } else if (
      (info.company == "" || info.company == [] || info.company == null) &&
      id
    ) {
      toast.error("company is required");
    } else if (
      (info.location == "" || info.location == [] || info.location == null) &&
      id
    ) {
      toast.error("location is required");
    } else if (
      !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(info.email) &&
      info.email
    ) {
      toast.error("Enter a valid email");
    } else if (
      (info.mobilenumber.toString().length < 4 ||
        info.mobilenumber.toString().length > 12) &&
      info.mobilenumber
    ) {
      toast.error("Phone number is not valid");
    } else {
      const param = new FormData();
      if (IsApprove !== "") {
        param.append("is_app", IsApprove.is_app);
        param.append("AADMIN_LOGIN_ID", IsApprove.AADMIN_LOGIN_ID);
        param.append("role_id", IsApprove.AADMIN_LOGIN_ROLE_ID);
      }
      param.append("ledger_name", info.name);
      param.append("ledger_alias", info.alias);
      param.append("ledger_alias2", info.alias2);
      param.append("ledger_group_id", info.leadType.ledger_group_id);

      if (!imagePreview.adharCardBack) {
        param.append("aadhar_card_front_image", info.adharCardFront);
      }
      if (!imagePreview.adharCardBack) {
        param.append("aadhar_card_back_image", info.adharCardBack);
      }
      if (!imagePreview.panCard) {
        param.append("pan_card_image", info.panCard);
      }
      param.append("ledger_gst_no", info.gstnumber);
      param.append("ledger_pan_no", info.pancardNumber);
      param.append("ledger_gst_state", info.gstState);
      param.append("ledger_gst_type", info.gstType);
      param.append("ledger_mob_no", info.mobilenumber);
      param.append("ledger_email", info.email);
      param.append("remarks", info.remarks);
      param.append("ledger_address", JSON.stringify(info.address));

      if (id == undefined) {
        var company_array = [];

        companyArray.id1.map((item, index) => {
          if (item.company.isChecked == true) {
            var location_array = [];

            item.location.map((item1, index1) => {
              if (item1.isChecked == true) {
                location_array = [...location_array, item1.location_id];
                console.log("location_id", item1.location_id);
              }
            });
            company_array = [
              ...company_array,
              {
                company_id: item.company.company_id,
                location_id: [...location_array],
              },
            ];
          }
        });
        console.log("companyArray", company_array);

        param.append("company_array", JSON.stringify(company_array));
      } else {
        param.append("ledger_id", id);
        param.append("ledger_company", info.company.company_id);
        param.append("ledger_location", info.location.location_id);
      }
      info.isLodear = true;
      setInfo({ ...info });
      axios
        .post(
          Url +
            `${
              id == undefined
                ? "/ajaxfiles/ledger_master_add.php"
                : "/ajaxfiles/ledger_master_update.php"
            }`,
          param
        )
        .then((res) => {
          if (res.data.status == "error") {
            toast.error(res.data.message);
            info.isLodear = false;
            setInfo({ ...info });
          } else {
            toast.success(res.data.message);
            info.isLodear = false;
            setInfo({ ...info });
            navigate("/ledgerMasterList");
          }
        });
    }
  };
  const addAddress = () => {
    if (address.add == "") {
      toast.error("Address is required");
    } else if (address.add1 == "") {
      toast.error("Address1 is required");
    } else if (address.country == "" || address.country == null) {
      toast.error("Country is required");
    } else if (address.state == "" || address.state == null) {
      toast.error("State is required");
    } else if (address.city == "" || address.city == null) {
      toast.error("City is required");
    } else {
      if (dialogTitle == "Edit Address") {
        info.address.splice(address.index, 1, address);

        // const newAddress = info.address.filter((e, ind) => {
        //   var update = [];
        //   if (address.index === ind) {
        //     e = address;
        //     update += e;
        //   }
        //   return update;
        // });
        // info.address = newAddress;
        setInfo({ ...info });
        setOpen(false);
      } else {
        info.address = [...info.address, address];
        setInfo({ ...info });
        setOpen(false);
      }
    }
  };
  const manageContent = () => {
    if (dialogTitle == "Add Address" || dialogTitle == "Edit Address") {
      return (
        <Grid container spacing={1}>
          <Grid item md={6}>
            <TextField
              id="standard-basic"
              label="Address"
              name="add"
              error={address.add == "" && addresst.add ? true : false}
              value={address.add}
              onChange={input2}
              onBlur={trueFalse2}
              helperText={
                address.add == "" && addresst.add ? "Address1 is required" : ""
              }
              className="w-100"
              variant="outlined"
            />
          </Grid>
          <Grid item md={6}>
            <TextField
              id="standard-basic"
              label="Address1"
              name="add1"
              error={address.add1 == "" && addresst.add1 ? true : false}
              value={address.add1}
              onChange={input2}
              onBlur={trueFalse2}
              helperText={
                address.add1 == "" && addresst.add1
                  ? "Address1 is required"
                  : ""
              }
              className="w-100"
              variant="outlined"
            />
          </Grid>
          <Grid item md={6}>
            <Autocomplete
              disablePortal
              options={countryData.country}
              value={address.country}
              getOptionLabel={(option) => (option ? option.nicename : "")}
              onChange={(event, newValue) => {
                getStateData(newValue);
                if (newValue == null) {
                  address.country = newValue;
                  setAddress({ ...address });
                } else {
                  address.country = newValue;
                  setAddress({ ...address });
                }
              }}
              sx={{ padding: "0px" }}
              className="w-100"
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Country"
                  size="small"
                  onBlur={trueFalse2}
                  name="country"
                  helperText={
                    (address.country == null || address.country == "") &&
                    addresst.country
                      ? "Country is required"
                      : ""
                  }
                  error={
                    (address.country == null || address.country == "") &&
                    addresst.country
                      ? true
                      : false
                  }
                  sx={{ padding: "0px" }}
                  variant="outlined"
                />
              )}
            />
          </Grid>
          <Grid item md={6}>
            <Autocomplete
              disablePortal
              options={countryData.state}
              value={address.state}
              getOptionLabel={(option) => (option ? option : "")}
              onChange={(event, newValue) => {
                getCityData(newValue);
                if (newValue == null) {
                  address.state = newValue;
                  setAddress({ ...address });
                } else {
                  address.state = newValue;
                  setAddress({ ...address });
                }
              }}
              sx={{ padding: "0px" }}
              className="w-100"
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="State"
                  onBlur={trueFalse2}
                  helperText={
                    (address.state == null || address.state == "") &&
                    addresst.state
                      ? "State is required"
                      : ""
                  }
                  error={
                    (address.state == null || address.state == "") &&
                    addresst.state
                      ? true
                      : false
                  }
                  name="state"
                  size="small"
                  sx={{ padding: "0px" }}
                  variant="outlined"
                />
              )}
            />
          </Grid>{" "}
          <Grid item md={6}>
            <Autocomplete
              disablePortal
              options={countryData.city}
              value={address.city}
              getOptionLabel={(option) => (option ? option : "")}
              onChange={(event, newValue) => {
                if (newValue == null) {
                  address.city = newValue;
                  setAddress({ ...address });
                } else {
                  address.city = newValue;
                  setAddress({ ...address });
                }
              }}
              sx={{ padding: "0px" }}
              className="w-100"
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="City"
                  size="small"
                  onBlur={trueFalse2}
                  helperText={
                    (address.city == null || address.city == "") &&
                    addresst.city
                      ? "City is required"
                      : ""
                  }
                  error={
                    (address.city == null || address.city == "") &&
                    addresst.city
                      ? true
                      : false
                  }
                  name="city"
                  sx={{ padding: "0px" }}
                  variant="outlined"
                />
              )}
            />
          </Grid>
        </Grid>
      );
    } else if (dialogTitle == "Add Company" || dialogTitle == "Edit Company") {
      return (
        <Grid container spacing={1}>
          <Grid item md={12}>
            {Object.keys(companyArray.data).map((index, item) => {
              return (
                <div>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={companyArray.id[item]?.company.isChecked}
                        onChange={(e) => {
                          companyArray.id[item].company.isChecked =
                            e.target.checked;
                          if (e.target.checked == false) {
                            companyArray.data[index].location.map(
                              (item1, index1) => {
                                companyArray.id[item].location[
                                  index1
                                ].isChecked = false;
                              }
                            );
                          }
                          setCompanyArray({ ...companyArray });
                        }}
                      />
                    }
                    label={companyArray.data[index].company_name.company_name}
                  />
                  {companyArray.id[item]?.company.isChecked == true ? (
                    <div style={{ marginLeft: "43px" }}>
                      {companyArray.data[index].location.map(
                        (item1, index1) => {
                          return (
                            <div>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={
                                      companyArray.id[item]?.location[index1]
                                        .isChecked
                                    }
                                    onChange={(e) => {
                                      companyArray.id[item].location[
                                        index1
                                      ].isChecked = e.target.checked;
                                      setCompanyArray({ ...companyArray });
                                    }}
                                  />
                                }
                                label={item1.location_name}
                              />
                            </div>
                          );
                        }
                      )}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              );
            })}
            <div></div>
          </Grid>
        </Grid>
      );
    }
  };
  const manageDialogActionButton = () => {
    if (dialogTitle == "Add Address" || dialogTitle == "Edit Address") {
      return (
        <div className="dialogMultipleActionButton">
          <Button
            variant="contained"
            className="cancelButton"
            onClick={handleClose}
          >
            Cancel
          </Button>
          {address.isLoader ? (
            <Button
              tabindex="0"
              size="large"
              sx={{ padding: "12px 50px" }}
              className=" btn-gradient  btn-danger"
              disabled
            >
              <svg class="spinner" viewBox="0 0 50 50">
                <circle
                  class="path"
                  cx="25"
                  cy="25"
                  r="20"
                  fill="none"
                  stroke-width="5"
                ></circle>
              </svg>
            </Button>
          ) : (
            <Button
              variant="contained"
              color="success"
              // className="btn-gradient "
              onClick={addAddress}
            >
              Submit
            </Button>
          )}
        </div>
      );
    } else if (dialogTitle == "Add Company" || dialogTitle == "Edit Company") {
      return (
        <div className="dialogMultipleActionButton">
          <Button
            variant="contained"
            className="cancelButton"
            onClick={handleClose}
          >
            Cancel
          </Button>

          <Button
            variant="contained"
            color="success"
            // className="btn-gradient "
            onClick={submitCompany}
          >
            Submit
          </Button>
        </div>
      );
    }
  };
  const addressDetete = (index) => {
    const address = info.address.filter((e, ind) => {
      return index != ind;
    });
    info.address = address;
    setInfo({ ...info });
  };
  const imageDelete = (porp) => {
    setimagePreview((prevalue) => {
      return {
        ...prevalue,
        [porp]: "",
      };
    });
  };
  return (
    <div className="app-content--inner">
      <div className="app-content--inner__wrapper mh-100-vh">
        {mainLoader.country ||
        mainLoader.commonFormet ||
        mainLoader.currencyName ||
        (id !== undefined && mainLoader.editPage) ? (
          <div>
            {" "}
            <span class="loader1"></span>
          </div>
        ) : (
          <div style={{ opacity: 1 }}>
            <Grid container>
              <Grid item md={12}>
                <p className="main-heading">
                  {id == undefined ? "Ledger Master" : "Modified Ledger Master"}
                  &nbsp;
                </p>
                <Paper
                  elevation={1}
                  style={{ borderRadius: "10px" }}
                  className="w-100 "
                >
                  <div className="card-header d-flex align-items-center justify-content-between card-header-alt p-3">
                    <h5 className="font-weight-bold mb-0 text-dark">
                      Basic details
                    </h5>
                  </div>
                  <div className="divider"></div>
                  <div className="card-body position-relative">
                    <Grid container spacing={1}>
                      <Grid item md={3}>
                        <TextField
                          id="standard-basic"
                          label="Name"
                          name="name"
                          error={
                            info.name == "" && infoTrue.name ? true : false
                          }
                          value={info.name}
                          onChange={input1}
                          onBlur={trueFalse}
                          helperText={
                            info.name == "" && infoTrue.name
                              ? "Name is required"
                              : ""
                          }
                          className="w-100"
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item md={3}>
                        <TextField
                          id="standard-basic"
                          label="Alias (Optional)"
                          name="alias"
                          value={info.alias}
                          // error={
                          //   info.alias == "" && infoTrue.alias ? true : false
                          // }
                          // helperText={
                          //   info.alias == "" && infoTrue.alias
                          //     ? "Alias is required"
                          //     : ""
                          // }
                          onChange={input1}
                          onBlur={trueFalse}
                          className="w-100"
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item md={3}>
                        <TextField
                          id="standard-basic"
                          name="alias2"
                          label="Alias 2 (Optional)"
                          value={info.alias2}
                          // error={
                          //   info.alias2 == "" && infoTrue.alias2 ? true : false
                          // }
                          // helperText={
                          //   info.alias2 == "" && infoTrue.alias2
                          //     ? "Alias2 is required"
                          //     : ""
                          // }
                          onChange={input1}
                          onBlur={trueFalse}
                          className="w-100"
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item md={3}>
                        <Autocomplete
                          disablePortal
                          options={arrData.leadType}
                          value={info.leadType}
                          getOptionLabel={(option) =>
                            option ? option.ledger_group_name : ""
                          }
                          onChange={(event, newValue) => {
                            if (newValue == null) {
                              info.leadType = newValue;
                              setInfo({ ...info });
                            } else {
                              info.leadType = newValue;
                              setInfo({ ...info });
                            }
                          }}
                          sx={{ padding: "0px" }}
                          className="w-100"
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Group Ledger"
                              size="small"
                              onBlur={trueFalse}
                              onChange={(e) => {
                                if (e.target.value.length > 3) {
                                  getLeadType(e.target.value);
                                }
                                // getLeadType(e.target.value)
                              }}
                              name="leadType"
                              helperText={
                                (info.leadType == null ||
                                  info.leadType == "") &&
                                infoTrue.leadType
                                  ? "Group Ledger is required"
                                  : ""
                              }
                              error={
                                (info.leadType == null ||
                                  info.leadType == "") &&
                                infoTrue.leadType
                                  ? true
                                  : false
                              }
                              sx={{ padding: "0px" }}
                              variant="outlined"
                            />
                          )}
                        />
                      </Grid>
                      {id ? (
                        <>
                          <Grid item md={3}>
                            <Autocomplete
                              disablePortal
                              options={arrData.companyList}
                              value={info.company}
                              getOptionLabel={(option) =>
                                option ? option.company_name : ""
                              }
                              onChange={(event, newValue) => {
                                getLocations(newValue);
                                if (newValue == null) {
                                  info.company = newValue;
                                  setInfo({ ...info });
                                } else {
                                  info.company = newValue;
                                  setInfo({ ...info });
                                }
                              }}
                              sx={{ padding: "0px" }}
                              className="w-100"
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Select Company"
                                  size="small"
                                  onBlur={trueFalse}
                                  name="company"
                                  helperText={
                                    (info.company == null ||
                                      info.company == "") &&
                                    infoTrue.company
                                      ? "Company is required"
                                      : ""
                                  }
                                  error={
                                    (info.company == null ||
                                      info.company == "") &&
                                    infoTrue.company
                                      ? true
                                      : false
                                  }
                                  sx={{ padding: "0px" }}
                                  variant="outlined"
                                />
                              )}
                            />
                          </Grid>
                          <Grid item md={3}>
                            <Autocomplete
                              disablePortal
                              options={arrData.location}
                              value={info.location}
                              getOptionLabel={(option) =>
                                option ? option.location_name : ""
                              }
                              onChange={(event, newValue) => {
                                if (newValue == null) {
                                  info.location = newValue;
                                  setInfo({ ...info });
                                } else {
                                  info.location = newValue;
                                  setInfo({ ...info });
                                }
                              }}
                              sx={{ padding: "0px" }}
                              className="w-100"
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Location"
                                  size="small"
                                  onBlur={trueFalse}
                                  name="location"
                                  helperText={
                                    (info.location == null ||
                                      info.location == "") &&
                                    infoTrue.location
                                      ? "location is required"
                                      : ""
                                  }
                                  error={
                                    (info.location == null ||
                                      info.location == "") &&
                                    infoTrue.location
                                      ? true
                                      : false
                                  }
                                  sx={{ padding: "0px" }}
                                  variant="outlined"
                                />
                              )}
                            />
                          </Grid>
                        </>
                      ) : (
                        ""
                      )}

                      <Grid item md={3}>
                        <TextField
                          id="standard-basic"
                          error={
                            !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
                              info.email
                            ) &&
                            info.email &&
                            infoTrue.email == true
                              ? true
                              : false
                          }
                          label="Email (Optional)"
                          name="email"
                          value={info.email}
                          className="w-100"
                          variant="outlined"
                          onChange={input1}
                          onBlur={trueFalse}
                          helperText={
                            !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
                              info.email
                            ) &&
                            info.email &&
                            infoTrue.email
                              ? "Enter a valid email"
                              : ""
                          }
                        />
                      </Grid>
                      <Grid item md={3}>
                        <TextField
                          id="standard-basic"
                          label="Mobile Number(Optional)"
                          name="mobilenumber"
                          className="w-100"
                          type="text"
                          variant="outlined"
                          value={info.mobilenumber}
                          onChange={(e) => {
                            if (
                              Number(e.target.value) > 0 ||
                              e.target.value == ""
                            ) {
                              input1(e);
                            }
                          }}
                          onBlur={trueFalse}
                          error={
                            (info.mobilenumber.toString().length < 4 ||
                              info.mobilenumber.toString().length > 12) &&
                            info.mobilenumber &&
                            infoTrue.mobilenumber
                              ? true
                              : false
                          }
                          helperText={
                            (info.mobilenumber.toString().length < 4 ||
                              info.mobilenumber.toString().length > 12) &&
                            info.mobilenumber &&
                            infoTrue.mobilenumber
                              ? "Mobile Number is not valid"
                              : ""
                          }
                        />
                      </Grid>
                    </Grid>
                  </div>
                </Paper>
              </Grid>
              {id ? (
                ""
              ) : (
                <Grid container spacing={1} className="mt-10">
                  <Grid item md={12}>
                    <Paper
                      elevation={1}
                      style={{ borderRadius: "10px" }}
                      className="w-100"
                    >
                      <div className="card-header d-flex align-items-center justify-content-between card-header-alt p-3">
                        <h5 className="font-weight-bold mb-0 text-dark">
                          Company
                        </h5>
                        <Grid item md={3} style={{ width: "auto" }}>
                          <ColorButton
                            onClick={() => {
                              if (companyArray.onEdit) {
                                setDialogTitle("Edit Company");
                              } else {
                                setDialogTitle("Add Company");
                              }
                              companyArray.id = JSON.parse(
                                JSON.stringify(companyArray.id1)
                              );
                              setCompanyArray({ ...companyArray });
                              setMaxWidth("xl");
                              setOpen(true);
                            }}
                          >
                            {companyArray.onEdit
                              ? "Edit Company"
                              : "Add Company"}
                          </ColorButton>
                        </Grid>
                      </div>
                      <div className="divider"></div>
                      <div className="card-body position-relative">
                        <Grid container spacing={1}>
                          <Grid item md={12}>
                            {companyArray.onEdit == false ? (
                              <div style={{ textAlign: "center" }}>
                                No address available
                              </div>
                            ) : (
                              <div>
                                <Table aria-label="simple table">
                                  <TableHead>
                                    <TableRow>
                                      <TableCell align="center">
                                        Company
                                      </TableCell>
                                      <TableCell align="center">
                                        Location{" "}
                                      </TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {companyArray.id1.map((item, index) => {
                                      if (item.company.isChecked == true) {
                                        return (
                                          <TableRow key={index}>
                                            <TableCell align="center">
                                              {item.company.company_name}
                                            </TableCell>
                                            <TableCell align="center">
                                              {item.location.map(
                                                (item1, index1) => {
                                                  if (item1.isChecked == true) {
                                                    return (
                                                      <div>
                                                        {item1.location_name}
                                                      </div>
                                                    );
                                                  }
                                                }
                                              )}
                                            </TableCell>
                                          </TableRow>
                                        );
                                      }
                                    })}
                                  </TableBody>
                                </Table>
                              </div>
                            )}
                          </Grid>
                        </Grid>
                      </div>
                    </Paper>
                  </Grid>
                </Grid>
              )}

              <Grid container spacing={1} className="mt-10">
                <Grid item md={12}>
                  <Paper
                    elevation={1}
                    style={{ borderRadius: "10px" }}
                    className="w-100 "
                  >
                    <div className="card-header d-flex align-items-center justify-content-between card-header-alt p-3">
                      <h5 className="font-weight-bold mb-0 text-dark">
                        KYC(Optional)
                      </h5>
                    </div>
                    <div className="divider"></div>
                    <div className="card-body position-relative">
                      <Grid
                        container
                        spacing={1}
                        className="align-items-center justify-content-between"
                      >
                        <Grid item md={6} className="d-flex align-items-center">
                          <Grid md={3} className="d-flex align-items-center">
                            <div>Adhar Card</div>
                          </Grid>
                          <Grid
                            md={9}
                            className="d-flex align-items-center justify-content-between "
                          >
                            <Grid md={6} className="dropZoneContainer ">
                              {imagePreview.adharCardFront == "" ? (
                                <>
                                  <input
                                    class="FileUpload w-100"
                                    type="file"
                                    name="adharCardFront"
                                    onChange={changeImage}
                                    placeholder="Uplode Adhar Card Front"
                                  />
                                  <div className="dropZoneOverlay">UPLOAD</div>
                                </>
                              ) : (
                                <div className="perviewImageSize">
                                  <div style={{ position: "relative" }}>
                                    <CloseIcon
                                      className="imageClose"
                                      onClick={() => {
                                        imageDelete("adharCardFront");
                                      }}
                                    />
                                    <img src={imagePreview.adharCardFront} />
                                  </div>
                                </div>
                              )}
                            </Grid>
                            <Grid md={6} className="dropZoneContainer">
                              {imagePreview.adharCardBack == "" ? (
                                <>
                                  <input
                                    class="FileUpload w-100"
                                    type="file"
                                    name="adharCardBack"
                                    onChange={changeImage}
                                    placeholder="Uplode Adhar Card Back"
                                  />
                                  <div className="dropZoneOverlay">UPLOAD</div>
                                </>
                              ) : (
                                <div className="perviewImageSize">
                                  <div style={{ position: "relative" }}>
                                    <CloseIcon
                                      className="imageClose"
                                      onClick={() => {
                                        imageDelete("adharCardBack");
                                      }}
                                    />
                                    <img src={imagePreview.adharCardBack} />
                                  </div>
                                </div>
                              )}
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          md={6}
                          className="d-flex justify-content-between"
                        >
                          <Grid md={6} className="d-flex align-items-center">
                            <div>Pan Card</div>
                          </Grid>
                          <Grid
                            md={7}
                            className="d-flex align-items-center justify-content-between"
                          >
                            <Grid md={12} className="dropZoneContainer">
                              {imagePreview.panCard == "" ? (
                                <>
                                  <input
                                    class="FileUpload w-100"
                                    type="file"
                                    name="panCard"
                                    onChange={changeImage}
                                    placeholder="Uplode Pan Card"
                                  />
                                  <div className="dropZoneOverlay">UPLOAD</div>
                                </>
                              ) : (
                                <div className="perviewImageSize">
                                  <div style={{ position: "relative" }}>
                                    <CloseIcon
                                      className="imageClose"
                                      onClick={() => {
                                        imageDelete("panCard");
                                      }}
                                    />
                                    <img src={imagePreview.panCard} />
                                  </div>
                                </div>
                              )}
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </div>
                  </Paper>
                </Grid>
              </Grid>
              <Grid container spacing={1} className="mt-10">
                <Grid item md={12}>
                  <Paper
                    elevation={1}
                    style={{ borderRadius: "10px" }}
                    className="w-100"
                  >
                    <div className="card-header d-flex align-items-center justify-content-between card-header-alt p-3">
                      <h5 className="font-weight-bold mb-0 text-dark">
                        Address(Optional)
                      </h5>
                      <Grid item md={3} style={{ width: "auto" }}>
                        <ColorButton
                          onClick={() => {
                            setDialogTitle("Add Address");
                            setAddress({
                              add1: "",
                              add: "",
                              country: "",
                              state: "",
                              city: "",
                            });
                            setAddresst({
                              add1: false,
                              add: false,
                              country: false,
                              state: false,
                              city: false,
                            });
                            setOpen(true);
                          }}
                        >
                          Add Address
                        </ColorButton>
                      </Grid>
                    </div>
                    <div className="divider"></div>
                    <div className="card-body position-relative">
                      <Grid container spacing={1}>
                        <Grid item md={12}>
                          {info.address == [] || info.address == "" ? (
                            <div style={{ textAlign: "center" }}>
                              No address available
                            </div>
                          ) : (
                            <div style={{ overflowY: "scroll" }}>
                              <Table aria-label="simple table">
                                <TableHead>
                                  <TableRow>
                                    <TableCell align="center">
                                      Address
                                    </TableCell>
                                    <TableCell align="center">
                                      Address1{" "}
                                    </TableCell>
                                    <TableCell align="center">
                                      Country
                                    </TableCell>
                                    <TableCell align="center">State</TableCell>
                                    <TableCell align="center">city</TableCell>
                                    <TableCell align="center">Action</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {info.address.map((item, index) => {
                                    return (
                                      <TableRow key={index}>
                                        <TableCell align="center">
                                          {item.add}
                                        </TableCell>
                                        <TableCell align="center">
                                          {item.add1}
                                        </TableCell>
                                        <TableCell align="center">
                                          {item.country == "" ||
                                          item.country == null
                                            ? ""
                                            : item.country.nicename}
                                        </TableCell>
                                        <TableCell align="center">
                                          {item.state}
                                        </TableCell>
                                        <TableCell align="center">
                                          {item.city}
                                        </TableCell>
                                        <TableCell align="center">
                                          <Button className="cursor-pointer p-0 p-md-2 rounded-circle text-muted">
                                            <DeleteIcon
                                              sx={{ color: "red" }}
                                              onClick={() => {
                                                addressDetete(index);
                                              }}
                                            />
                                          </Button>
                                          <a>
                                            <Button
                                              type="submit"
                                              className="cursor-pointer mx-3 p-0 p-md-2 rounded-circle text-muted"
                                              onClick={() => {
                                                getStateData(
                                                  item.country,
                                                  defult
                                                );
                                                getCityData(item.state, defult);
                                                setDialogTitle("Edit Address");
                                                setAddress({
                                                  add1: item.add1,
                                                  add: item.add,
                                                  country: item.country,
                                                  state: item.state,
                                                  city: item.city,
                                                  index: index,
                                                });
                                                setOpen(true);
                                              }}
                                            >
                                              <CreateIcon
                                                sx={{ color: "#3D9730" }}
                                              />
                                            </Button>
                                          </a>
                                        </TableCell>
                                      </TableRow>
                                    );
                                  })}
                                </TableBody>
                              </Table>
                            </div>
                          )}
                        </Grid>
                      </Grid>
                    </div>
                  </Paper>
                </Grid>
              </Grid>
              <Grid container spacing={1} className="mt-10">
                <Grid item md={6}>
                  <Paper
                    elevation={1}
                    style={{ borderRadius: "10px" }}
                    className="w-100"
                  >
                    <div className="card-header d-flex align-items-center justify-content-between card-header-alt p-3">
                      <h5 className="font-weight-bold mb-0 text-dark">
                        Tax Information
                      </h5>
                    </div>
                    <div className="divider"></div>
                    <div className="card-body position-relative">
                      <Grid container spacing={1}>
                        <Grid item md={12}>
                          <TextField
                            id="standard-basic"
                            label="GST Number (Optional)"
                            name="gstnumber"
                            value={info.gstnumber}
                            error={
                              info.gstnumber.length > 0 &&
                              infoTrue.gstnumber &&
                              info.gstnumber.length != 14
                                ? true
                                : false
                            }
                            helperText={
                              info.gstnumber.length != 14 &&
                              info.gstnumber.length > 0 &&
                              infoTrue.gstnumber
                                ? "GST Number must be in 14 digit"
                                : ""
                            }
                            className="w-100"
                            variant="outlined"
                            onChange={input1}
                            onBlur={trueFalse}
                          />
                        </Grid>
                        <Grid item md={12}>
                          <TextField
                            id="standard-basic"
                            label="PanCard Number(Optional)"
                            name="pancardNumber"
                            className="w-100"
                            variant="outlined"
                            value={info.pancardNumber}
                            onChange={input1}
                            onBlur={trueFalse}
                            error={
                              info.pancardNumber.length > 0 &&
                              infoTrue.pancardNumber &&
                              info.pancardNumber.length != 10
                                ? true
                                : false
                            }
                            helperText={
                              info.pancardNumber.length != 10 &&
                              info.pancardNumber.length > 0 &&
                              infoTrue.pancardNumber
                                ? "PanCard Number must be in 10 digit"
                                : ""
                            }
                          />
                        </Grid>
                        <Grid item md={12}>
                          <TextField
                            id="standard-basic"
                            label="GST Type (Optional)"
                            name="gstType"
                            className="w-100"
                            value={info.gstType}
                            variant="outlined"
                            onChange={input1}
                            onBlur={trueFalse}
                            // error={
                            //   info.gstType.length > 0 &&
                            //   infoTrue.gstType &&
                            //   info.gstType.length != 10
                            //     ? true
                            //     : false
                            // }
                            // helperText={
                            //   info.gstType.length != 10 &&
                            //   info.gstType.length > 0 &&
                            //   infoTrue.gstType
                            //     ? "Tan Number must be in 10 digit"
                            //     : ""
                            // }
                          />
                        </Grid>
                        <Grid item md={12}>
                          <TextField
                            id="standard-basic"
                            label="GST State (Optional)"
                            name="gstState"
                            className="w-100"
                            value={info.gstState}
                            variant="outlined"
                            onChange={input1}
                            // onBlur={trueFalse}
                            // error={
                            //   info.tanNumber.length > 0 &&
                            //   infoTrue.tanNumber &&
                            //   info.tanNumber.length != 10
                            //     ? true
                            //     : false
                            // }
                            // helperText={
                            //   info.tanNumber.length != 10 &&
                            //   info.tanNumber.length > 0 &&
                            //   infoTrue.tanNumber
                            //     ? "Tan Number must be in 10 digit"
                            //     : ""
                            // }
                          />
                        </Grid>
                      </Grid>
                    </div>
                  </Paper>
                </Grid>
                <Grid item md={6}>
                  <Paper
                    elevation={1}
                    style={{ borderRadius: "10px" }}
                    className="w-100"
                  >
                    <div className="card-header d-flex align-items-center justify-content-between card-header-alt p-3">
                      <h5 className="font-weight-bold mb-0 text-dark">
                        Opening Balance
                      </h5>
                    </div>
                    <div className="divider"></div>
                    <div className="card-body position-relative">
                      <Grid container spacing={1}>
                        <Grid item md={12}>
                          <FormControl>
                            <RadioGroup
                              aria-labelledby="demo-radio-buttons-group-label"
                              defaultValue="credit"
                              row
                              value={info.openingBalance}
                              name="openingBalance"
                              onChange={input1}
                            >
                              <FormControlLabel
                                value="credit"
                                control={<Radio />}
                                label="Credit"
                              />
                              <FormControlLabel
                                value="debit"
                                control={<Radio />}
                                label="Debit"
                              />
                            </RadioGroup>
                          </FormControl>
                        </Grid>
                        <Grid item md={12}>
                          <TextField
                            id="standard-basic"
                            label="Balance"
                            name="balance"
                            className="w-100"
                            variant="outlined"
                            value={info.balance}
                            onChange={(e) => {
                              if (
                                Number(e.target.value) > 0 ||
                                e.target.value == ""
                              ) {
                                input1(e);
                              }
                            }}
                          />
                        </Grid>
                      </Grid>
                    </div>
                  </Paper>
                </Grid>
              </Grid>

              <Grid container spacing={1} className="mt-10">
                <Grid item md={12}>
                  <Paper
                    elevation={1}
                    style={{ borderRadius: "10px" }}
                    className="w-100"
                  >
                    <div className="card-header d-flex align-items-center justify-content-between card-header-alt p-3">
                      <h5 className="font-weight-bold mb-0 text-dark">
                        Remarks
                      </h5>
                    </div>
                    <div className="divider"></div>
                    <div className="card-body position-relative">
                      <Grid container spacing={1} className="w-100">
                        <Grid md={12} className="w-100">
                          <TextareaAutosize
                            aria-label="empty textarea"
                            placeholder="Remarks"
                            name="remarks"
                            value={info.remarks}
                            onChange={input1}
                            className="w-100 H-80"
                          />
                          <div className="text-right">
                            <Button
                              variant="contained"
                              color="error"
                              onClick={() => {
                                navigate("/companyDetails");
                              }}
                            >
                              CANCEL
                            </Button>
                            {info.isLodear ? (
                              <Button
                                tabindex="0"
                                size="large"
                                sx={{
                                  padding: "16.5px 50px",
                                  marginLeft: "10px",
                                }}
                                className=" btn-gradient  btn-success "
                                disabled
                              >
                                <svg class="spinner" viewBox="0 0 50 50">
                                  <circle
                                    class="path"
                                    cx="25"
                                    cy="25"
                                    r="20"
                                    fill="none"
                                    stroke-width="5"
                                  ></circle>
                                </svg>
                              </Button>
                            ) : (
                              <Button
                                variant="contained"
                                color="success"
                                onClick={submitAddFrom}
                                sx={{ marginLeft: "10px" }}
                              >
                                SUBMIT
                              </Button>
                            )}
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                  </Paper>
                </Grid>
              </Grid>
              <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                className="modalWidth100"
                fullWidth={fullWidth}
                maxWidth={maxWidth}
              >
                <BootstrapDialogTitle
                  id="customized-dialog-title"
                  className="dialogTitle"
                  onClose={handleClose}
                >
                  {dialogTitle}
                </BootstrapDialogTitle>
                <DialogContent dividers>{manageContent()}</DialogContent>
                <DialogActions>{manageDialogActionButton()}</DialogActions>
              </BootstrapDialog>
            </Grid>
          </div>
        )}
      </div>
    </div>
  );
};

export default Ledger_master;
