import React, { useState } from "react";
import {
  Autocomplete,
  FormControlLabel,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Checkbox,
} from "@mui/material";
import CardContent from "@mui/material/CardContent";
import { Paper } from "@mui/material";
import { styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import { ColorButton, IOSSwitch } from "../common/CustomElement";
import { Button } from "@mui/material";
import CommonTable from "../common/CommonTable";
import { useNavigate } from "react-router-dom";
import CustomImageModal from "../common/CustomImageModal";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { IsApprove, Url } from "../global";
import CommonFilter from "../common/CommonFilter";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import NewDate from "../common/NewDate";
import { Add, EditRoadRounded, Info } from "@mui/icons-material";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(0),
  },
  "& .MuiInputBase-input": {
    borderRadius: 9,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "8px 26px 8px 10px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 9,
      borderColor: "#80bdff",
    },
  },
}));
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};
const GroupLedger = (prop) => {
  // console.log(prop);
  const [param, setParam] = useState({
    start_date: "",
    end_date: "",
  });
  const navigate = useNavigate();
  const [searchKeyword, setSearchKeyword] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [checkStatus, setcheckStatus] = useState("");
  const [open, setOpen] = useState(false);
  const [dialogTitle, setDialogTitle] = useState("");
  const [fullWidth, setFullWidth] = useState(true);
  const [resData, setResData] = useState({});
  const [maxWidth, setMaxWidth] = useState("xs");
  const [info, setInfo] = useState({
    groupName: "",
    lName: "",
    childname: "",
    l_type: "",
    l_type_list: [],
    childList: [],
    groupList: [],
    isLoader: false,
  });
  const input1 = (event) => {
    var { name, value } = event.target;
    setInfo((prevalue) => {
      return {
        ...prevalue,
        [name]: value,
      };
    });
  };
  const [infoTrue, setinfoTrue] = useState({
    groupName: false,
    lName: false,
    childname: false,
  });
  const trueFalse = (event) => {
    var { name, value } = event.target;
    setinfoTrue((prevalue) => {
      return {
        ...prevalue,
        [name]: true,
      };
    });
  };
  const [searchBy, setSearchBy] = useState([
    {
      label: "ROLE NAME",
      value: false,
      name: "role",
    },
    {
      label: "NAME",
      value: false,
      name: "user_first_name",
    },
    {
      label: "Email",
      value: false,
      name: "user_email",
    },
    {
      label: "PAYMENT METHOD",
      value: false,
      name: "payment_method",
    },
    {
      label: "Phone Number",
      value: false,
      name: "user_phone",
    },
    {
      label: "MANAGER",
      value: false,
      name: "master_manager_name",
    },
    {
      label: "ACCOUNT TARGET",
      value: false,
      name: "ac_target",
    },
    {
      label: "MONEY IN TARGET",
      value: false,
      name: "master_manager_name",
    },
  ]);
  const re = /^[A-Za-z_ ]*$/;
  const [deleteCompany, setDeleteCopany] = useState({
    isLoader: false,
    company_id: "",
  });
  const handleClose = () => {
    setOpen(false);
  };
  toast.configure();
  console.log(info.groupId, "cinsole12");

  const formAdd = () => {
    const param = new FormData();
    if (info.lName == "") {
      toast.error("Group Ledger Name is required");
    } else if (info.groupName == "") {
      toast.error("Group Name is required");
    } else {
      if (IsApprove !== "") {
        param.append("is_app", IsApprove.is_app);
        param.append("AADMIN_LOGIN_ID", IsApprove.AADMIN_LOGIN_ID);
        param.append("role_id", IsApprove.AADMIN_LOGIN_ROLE_ID);
      }
      param.append("group_id", info.groupName.group_id);
      if (
        info.childname == "" ||
        info.childname == null ||
        info.childname == undefined
      ) {
      } else {
        param.append("child_group_id", info.childname.group_id);
      }

      param.append("ledger_group_name", info.lName);
      param.append("ledger_type", info.l_type);

      info.isLoader = true;
      setInfo({ ...info });
      axios.post(Url + "/ajaxfiles/ledger_group_add.php", param).then((res) => {
        if (res.data.message == "Session has been expired") {
          localStorage.setItem("login", true);
          navigate("/login");
        }
        if (res.data.status == "error") {
          toast.error(res.data.message);
          info.isLoader = false;
          setInfo({ ...info });
        } else {
          toast.success(res.data.message);
          info.isLoader = false;
          setInfo({ ...info });
          setRefresh(!refresh);
          setOpen(false);
        }
      });
    }
  };
  const formDelete = () => {
    const param = new FormData();

    if (IsApprove !== "") {
      param.append("is_app", IsApprove.is_app);
      param.append("AADMIN_LOGIN_ID", IsApprove.AADMIN_LOGIN_ID);
      param.append("role_id", IsApprove.AADMIN_LOGIN_ROLE_ID);
    }
    param.append("ledger_group_id", deleteCompany.company_id);
    deleteCompany.isLoader = true;
    setDeleteCopany({ ...deleteCompany });
    axios
      .post(Url + "/ajaxfiles/ledger_group_delete.php", param)
      .then((res) => {
        if (res.data.message == "Session has been expired") {
          localStorage.setItem("login", true);
          navigate("/login");
        }
        if (res.data.status == "error") {
          toast.error(res.data.message);
          deleteCompany.isLoader = false;
          setDeleteCopany({ ...deleteCompany });
        } else {
          toast.success(res.data.message);
          deleteCompany.isLoader = false;
          setDeleteCopany({ ...deleteCompany });
          setOpen(false);
          setRefresh(!refresh);
        }
      });
  };

  const getGroupList = () => {
    const param = new FormData();
    if (IsApprove !== "") {
      param.append("is_app", IsApprove.is_app);
      param.append("AADMIN_LOGIN_ID", IsApprove.AADMIN_LOGIN_ID);
      param.append("role_id", IsApprove.AADMIN_LOGIN_ROLE_ID);
    }
    param.append("is_parent", 1);

    axios.post(Url + "/ajaxfiles/get_group_list.php", param).then((res) => {
      if (res.data.message == "Session has been expired") {
        localStorage.setItem("login", true);
        navigate("/login");
      }
      if (res.data.status == "error") {
        toast.error(res.data.message);
      } else {
        info.groupList = res.data.data;
        setInfo({ ...info });

        // info.company_list = res.data.data;
        // setInfo({ ...info });
      }
    });
  };
  const getLedgerType = () => {
    const param = new FormData();
    if (IsApprove !== "") {
      param.append("is_app", IsApprove.is_app);
      param.append("AADMIN_LOGIN_ID", IsApprove.AADMIN_LOGIN_ID);
      param.append("role_id", IsApprove.AADMIN_LOGIN_ROLE_ID);
    }
    param.append("action", "ledger_type");

    axios.post(Url + "/ajaxfiles/common_api.php", param).then((res) => {
      if (res.data.message == "Session has been expired") {
        localStorage.setItem("login", true);
        navigate("/login");
      }
      if (res.data.status == "error") {
        toast.error(res.data.message);
      } else {
        info.l_type_list = res.data.data;
        setInfo({ ...info });
      }
    });
  };

  const getchildList = (prop) => {
    if (prop == null) {
      info.childname = "";
      info.childList = [];
      setInfo({ ...info });
    } else {
      const param = new FormData();
      if (IsApprove !== "") {
        param.append("is_app", IsApprove.is_app);
        param.append("AADMIN_LOGIN_ID", IsApprove.AADMIN_LOGIN_ID);
        param.append("role_id", IsApprove.AADMIN_LOGIN_ROLE_ID);
      }
      param.append("is_parent", 0);
      param.append("parent_group_id", prop.group_id);

      axios.post(Url + "/ajaxfiles/get_group_list.php", param).then((res) => {
        if (res.data.message == "Session has been expired") {
          localStorage.setItem("login", true);
          navigate("/login");
        }
        if (res.data.status == "error") {
          // toast.error(res.data.message);
        } else {
          info.childList = res.data.data;
          setInfo({ ...info });
        }
      });
    }
  };

  const manageContent = () => {
    if (dialogTitle == "Delete Ledger Group ") {
      return (
        <>
          <div>
            <h1>Are you sure ?</h1>
            Do you want to sure delete this Ledger Group ?
          </div>
        </>
      );
    } else if (dialogTitle == "Add Ledger Group ") {
      return (
        <>
          <Grid container spacing={1}>
            <Grid item md={12}>
              <FormControl
                fullWidth
                error={info.l_type == "" && infoTrue.l_type ? true : false}
              >
                <InputLabel id="demo-simple-select-label">
                  Ledger type
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Ledger type"
                  name="l_type"
                  value={info.l_type}
                  onBlur={trueFalse}
                  onChange={input1}
                >
                  {info.l_type_list.map((item) => {
                    return <MenuItem value={item}>{item}</MenuItem>;
                  })}
                </Select>
                {info.l_type == "" && infoTrue.l_type ? (
                  <FormHelperText> Ledger type</FormHelperText>
                ) : (
                  ""
                )}
              </FormControl>
            </Grid>
            <Grid item md={12}>
              <TextField
                id="standard-basic"
                label="Group Ledger Name"
                name="lName"
                error={info.lName == "" && infoTrue.lName ? true : false}
                value={info.lName}
                onChange={input1}
                onBlur={trueFalse}
                helperText={
                  info.lName == "" && infoTrue.lName
                    ? "Group Ledger Name is required"
                    : ""
                }
                className="w-100"
                variant="outlined"
              />
            </Grid>

            <Grid item md={12}>
              <Autocomplete
                disablePortal
                options={info.groupList}
                value={info.groupName}
                getOptionLabel={(option) => (option ? option.group_name : "")}
                onChange={(event, newValue) => {
                  getchildList(newValue);
                  if (newValue == null) {
                    info.groupName = newValue;
                    setInfo({ ...info });
                  } else {
                    info.groupName = newValue;
                    setInfo({ ...info });
                  }
                }}
                sx={{ padding: "0px" }}
                className="w-100"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Group Name"
                    onBlur={trueFalse}
                    helperText={
                      (info.groupName == null || info.groupName == "") &&
                      infoTrue.groupName
                        ? "Group Name is required"
                        : ""
                    }
                    error={
                      (info.groupName == "" || info.groupName == null) &&
                      infoTrue.groupName
                        ? true
                        : false
                    }
                    name="groupName"
                    size="small"
                    sx={{ padding: "0px" }}
                    variant="outlined"
                  />
                )}
              />
            </Grid>
            <Grid item md={12}>
              <Autocomplete
                disablePortal
                options={info.childList}
                value={info.childname}
                getOptionLabel={(option) => (option ? option.group_name : "")}
                onChange={(event, newValue) => {
                  if (newValue == null) {
                    info.childname = newValue;
                    setInfo({ ...info });
                  } else {
                    info.childname = newValue;
                    setInfo({ ...info });
                  }
                }}
                sx={{ padding: "0px" }}
                className="w-100"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Child Group Name"
                    onBlur={trueFalse}
                    // helperText={
                    //   (info.childname == null || info.childname == "") &&
                    //   infoTrue.childname
                    //     ? "Child Group Name is required"
                    //     : ""
                    // }
                    // error={
                    //   (info.childname == "" || info.childname == null) &&
                    //   infoTrue.childname
                    //     ? true
                    //     : false
                    // }
                    name="childname"
                    size="small"
                    sx={{ padding: "0px" }}
                    variant="outlined"
                  />
                )}
              />
            </Grid>
          </Grid>
        </>
      );
    }
  };
  const manageDialogActionButton = () => {
    if (dialogTitle == "Delete Ledger Group ") {
      return (
        <div className="dialogMultipleActionButton">
          <Button
            variant="contained"
            className="cancelButton"
            onClick={handleClose}
          >
            Cancel
          </Button>
          {deleteCompany.isLoader ? (
            <Button
              tabindex="0"
              size="large"
              sx={{ padding: "12px 50px" }}
              className=" btn-gradient  btn-danger"
              disabled
            >
              <svg class="spinner" viewBox="0 0 50 50">
                <circle
                  class="path"
                  cx="25"
                  cy="25"
                  r="20"
                  fill="none"
                  stroke-width="5"
                ></circle>
              </svg>
            </Button>
          ) : (
            <Button
              variant="contained"
              className="btn-gradient btn-danger"
              onClick={formDelete}
            >
              Delete
            </Button>
          )}
        </div>
      );
    } else if (dialogTitle == "Add Ledger Group ") {
      return (
        <div className="dialogMultipleActionButton">
          <Button
            variant="contained"
            className="cancelButton"
            onClick={handleClose}
          >
            Cancel
          </Button>
          {info.isLoader ? (
            <Button
              tabindex="0"
              size="large"
              sx={{ padding: "12px 40px" }}
              className=" btn-gradient  btn-danger"
              disabled
            >
              <svg class="spinner" viewBox="0 0 50 50">
                <circle
                  class="path"
                  cx="25"
                  cy="25"
                  r="20"
                  fill="none"
                  stroke-width="5"
                ></circle>
              </svg>
            </Button>
          ) : (
            <Button variant="contained" color="success" onClick={formAdd}>
              Add
            </Button>
          )}
        </div>
      );
    }
  };
  const columns = [
    {
      name: "SR.NO",
      selector: (row) => {
        return <span>{row.sr_no}</span>;
      },
      reorder: true,
      wrap: true,
      grow: 0.1,
    },
    {
      name: "Ledger Group Name",
      selector: (row) => {
        return (
          <span title={row.ledger_group_name}>{row.ledger_group_name}</span>
        );
      },
      sortable: true,
      reorder: true,
      grow: 1,
      wrap: true,
    },
    {
      name: "group name",
      selector: (row) => {
        return <span title={row.group_name}>{row.group_name}</span>;
      },
      sortable: true,
      reorder: true,
      grow: 1,
      wrap: true,
    },
    {
      name: "Main Part name",
      selector: (row) => {
        return <span title={row.part_name}>{row.part_name}</span>;
      },
      sortable: true,
      reorder: true,
      grow: 1,
      wrap: true,
    },
    {
      name: "Ledger type",
      selector: (row) => {
        return <span title={row.ledger_type}>{row.ledger_type}</span>;
      },
      sortable: true,
      reorder: true,
      grow: 1,
      wrap: true,
    },
    {
      name: "Action",
      selector: (row) => {
        return (
          <span style={{ display: "flex" }}>
            {" "}
            <Button>
              <span
                className="material-icons  icon_Mar"
                style={{ color: "red" }}
                onClick={() => {
                  setDialogTitle("Delete Ledger Group ");
                  deleteCompany.isLoader = false;
                  deleteCompany.company_id = row.ledger_group_id;
                  setDeleteCopany({ ...deleteCompany });
                  setOpen(true);
                }}
              >
                delete
              </span>
            </Button>
          </span>
        );
      },
      sortable: true,
      reorder: true,
      grow: 0.3,
      wrap: true,
    },
  ];

  return (
    <div>
      <div className="app-content--inner">
        <div className="app-content--inner__wrapper mh-100-vh">
          <div style={{ opacity: 1 }}>
            <Grid container>
              <Grid item md={12} lg={12} xl={12}>
                <p className="main-heading">Ledger Group</p>
                {/* <CommonFilter
                  search={searchBy}
                  setParam={setParam}
                  searchWord={setSearchKeyword}
                  setcheckStatus={setcheckStatus}
                  lastUpdatedBy={resData.modified_by_users}
                />
                <br /> */}

                <Paper
                  elevation={2}
                  style={{ borderRadius: "10px" }}
                  className="pending-all-15px"
                >
                  <CardContent className="py-3">
                    <Grid container spacing={2}>
                      <Grid item sm={12} md={12} lg={12}>
                        <ColorButton
                          onClick={() => {
                            getGroupList();
                            getLedgerType();
                            info.isLoader = false;
                            info.groupName = "";
                            info.lName = "";
                            info.childname = "";
                            info.l_type = "";

                            setInfo({ ...info });
                            setinfoTrue({
                              groupName: false,
                              lName: false,
                              childname: false,
                            });
                            setDialogTitle("Add Ledger Group ");

                            setOpen(true);
                          }}
                        >
                          Add Ledger Group
                        </ColorButton>

                        <CommonTable
                          url={`${Url}/datatable/ledger_group_list.php`}
                          column={columns}
                          sort="0"
                          refresh={refresh}
                          search={searchBy}
                          param={param}
                          searchWord={searchKeyword}
                          checkStatus={checkStatus}
                          setResData={setResData}
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Paper>
              </Grid>
              <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                className="modalWidth100"
                fullWidth={fullWidth}
                maxWidth={maxWidth}
              >
                <BootstrapDialogTitle
                  id="customized-dialog-title"
                  className="dialogTitle"
                  onClose={handleClose}
                >
                  {dialogTitle}
                </BootstrapDialogTitle>
                <DialogContent dividers>{manageContent()}</DialogContent>
                <DialogActions>{manageDialogActionButton()}</DialogActions>
              </BootstrapDialog>
            </Grid>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GroupLedger;
