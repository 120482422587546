import React, { useState } from "react";
import {
  Autocomplete,
  FormControlLabel,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Checkbox,
} from "@mui/material";
import CardContent from "@mui/material/CardContent";
import { Paper } from "@mui/material";
import { styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import { ColorButton, IOSSwitch } from "../common/CustomElement";
import { Button } from "@mui/material";
import CommonTable from "../common/CommonTable";
import { useNavigate } from "react-router-dom";
import CustomImageModal from "../common/CustomImageModal";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { IsApprove, Url } from "../global";
import CommonFilter from "../common/CommonFilter";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import NewDate from "../common/NewDate";
import { Add, EditRoadRounded, Info } from "@mui/icons-material";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(0),
  },
  "& .MuiInputBase-input": {
    borderRadius: 9,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "8px 26px 8px 10px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 9,
      borderColor: "#80bdff",
    },
  },
}));
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};
const GroupDetails = (prop) => {
  // console.log(prop);
  const [param, setParam] = useState({
    start_date: "",
    end_date: "",
  });
  const navigate = useNavigate();
  const [searchKeyword, setSearchKeyword] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [checkStatus, setcheckStatus] = useState("");
  const [open, setOpen] = useState(false);
  const [dialogTitle, setDialogTitle] = useState("");
  const [fullWidth, setFullWidth] = useState(true);
  const [resData, setResData] = useState({});
  const [maxWidth, setMaxWidth] = useState("xs");
  const [info, setInfo] = useState({
    groupName: "",
    mainPartList: [],
    groupList: [],
    groupId: "",
    mainPart: "",
    childYesNo: false,
    // childGroupName: "",
    isLoader: false,
  });
  const input1 = (event) => {
    var { name, value } = event.target;
    setInfo((prevalue) => {
      return {
        ...prevalue,
        [name]: value,
      };
    });
  };
  const [infoTrue, setinfoTrue] = useState({
    groupName: "",
    mainPart: "",
    childGroupName: "",
  });
  const trueFalse = (event) => {
    var { name, value } = event.target;
    setinfoTrue((prevalue) => {
      return {
        ...prevalue,
        [name]: true,
      };
    });
  };
  const [searchBy, setSearchBy] = useState([
    {
      label: "ROLE NAME",
      value: false,
      name: "role",
    },
    {
      label: "NAME",
      value: false,
      name: "user_first_name",
    },
    {
      label: "Email",
      value: false,
      name: "user_email",
    },
    {
      label: "PAYMENT METHOD",
      value: false,
      name: "payment_method",
    },
    {
      label: "Phone Number",
      value: false,
      name: "user_phone",
    },
    {
      label: "MANAGER",
      value: false,
      name: "master_manager_name",
    },
    {
      label: "ACCOUNT TARGET",
      value: false,
      name: "ac_target",
    },
    {
      label: "MONEY IN TARGET",
      value: false,
      name: "master_manager_name",
    },
  ]);
  const re = /^[A-Za-z_ ]*$/;
  const [deleteCompany, setDeleteCopany] = useState({
    isLoader: false,
    company_id: "",
  });
  const handleClose = () => {
    setOpen(false);
  };
  toast.configure();
  console.log(info.groupId, "cinsole12");

  const formAdd = () => {
    const param = new FormData();
    if (info.mainPart == "") {
      toast.error("Main Part is required");
    } else if (
      (info.groupId == "" || info.groupId == null) &&
      info.childYesNo == true
    ) {
      toast.error("Parent Group Name is required");
    } else if (info.groupName == "") {
      toast.error("Group Name is required");
    } else {
      if (IsApprove !== "") {
        param.append("is_app", IsApprove.is_app);
        param.append("AADMIN_LOGIN_ID", IsApprove.AADMIN_LOGIN_ID);
        param.append("role_id", IsApprove.AADMIN_LOGIN_ROLE_ID);
      }
      param.append("part_id", info.mainPart);
      param.append("group_name", info.groupName);
      param.append("is_child", info.childYesNo ? 1 : 0);
      if (info.childYesNo) {
        console.log(info.groupId, "cinsole");
        param.append("parent_group_id", info.groupId.group_id);
      }

      info.isLoader = true;
      setInfo({ ...info });
      axios.post(Url + "/ajaxfiles/group_add.php", param).then((res) => {
        if (res.data.message == "Session has been expired") {
          localStorage.setItem("login", true);
          navigate("/login");
        }
        if (res.data.status == "error") {
          toast.error(res.data.message);
          info.isLoader = false;
          setInfo({ ...info });
        } else {
          toast.success(res.data.message);
          info.isLoader = false;
          setInfo({ ...info });
          setRefresh(!refresh);
          setOpen(false);
        }
      });
    }
  };
  const formDelete = () => {
    const param = new FormData();

    if (IsApprove !== "") {
      param.append("is_app", IsApprove.is_app);
      param.append("AADMIN_LOGIN_ID", IsApprove.AADMIN_LOGIN_ID);
      param.append("role_id", IsApprove.AADMIN_LOGIN_ROLE_ID);
    }
    param.append("group_id", deleteCompany.company_id);
    deleteCompany.isLoader = true;
    setDeleteCopany({ ...deleteCompany });
    axios.post(Url + "/ajaxfiles/group_delete.php", param).then((res) => {
      if (res.data.message == "Session has been expired") {
        localStorage.setItem("login", true);
        navigate("/login");
      }
      if (res.data.status == "error") {
        toast.error(res.data.message);
        deleteCompany.isLoader = false;
        setDeleteCopany({ ...deleteCompany });
      } else {
        toast.success(res.data.message);
        deleteCompany.isLoader = false;
        setDeleteCopany({ ...deleteCompany });
        setOpen(false);
        setRefresh(!refresh);
      }
    });
  };

  const getGroupList = () => {
    const param = new FormData();
    if (IsApprove !== "") {
      param.append("is_app", IsApprove.is_app);
      param.append("AADMIN_LOGIN_ID", IsApprove.AADMIN_LOGIN_ID);
      param.append("role_id", IsApprove.AADMIN_LOGIN_ROLE_ID);
    }
    param.append("is_parent", 1);

    axios.post(Url + "/ajaxfiles/get_group_list.php", param).then((res) => {
      if (res.data.message == "Session has been expired") {
        localStorage.setItem("login", true);
        navigate("/login");
      }
      if (res.data.status == "error") {
        toast.error(res.data.message);
      } else {
        info.groupList = res.data.data;
        setInfo({ ...info });

        // info.company_list = res.data.data;
        // setInfo({ ...info });
      }
    });
  };
  const getMainPartList = () => {
    const param = new FormData();
    if (IsApprove !== "") {
      param.append("is_app", IsApprove.is_app);
      param.append("AADMIN_LOGIN_ID", IsApprove.AADMIN_LOGIN_ID);
      param.append("role_id", IsApprove.AADMIN_LOGIN_ROLE_ID);
    }
    axios.post(Url + "/ajaxfiles/get_main_part_list.php", param).then((res) => {
      if (res.data.message == "Session has been expired") {
        localStorage.setItem("login", true);
        navigate("/login");
      }
      if (res.data.status == "error") {
        toast.error(res.data.message);
      } else {
        info.mainPartList = res.data.data;
        setInfo({ ...info });

        // info.company_list = res.data.data;
        // setInfo({ ...info });
      }
    });
  };
  const manageContent = () => {
    if (dialogTitle == "Delete Group ") {
      return (
        <>
          <div>
            <h1>Are you sure ?</h1>
            Do you want to sure delete this Group Details ?
          </div>
        </>
      );
    } else if (dialogTitle == "Add Group") {
      return (
        <>
          <Grid container spacing={1}>
            <Grid item md={12}>
              <FormControlLabel
                control={<Checkbox checked={info.childYesNo} />}
                label="Child Group"
                onClick={(e) => {
                  if (e.target.checked) {
                    getGroupList();
                  }
                  console.log(e.target.checked);
                  info.childYesNo = e.target.checked;
                  setInfo({ ...info });
                }}
              />
            </Grid>

            <Grid item md={12}>
              <FormControl
                fullWidth
                error={info.mainPart == "" && infoTrue.mainPart ? true : false}
              >
                <InputLabel id="demo-simple-select-label">Main Part</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Main Part"
                  name="mainPart"
                  value={info.mainPart}
                  onBlur={trueFalse}
                  onChange={input1}
                >
                  {info.mainPartList.map((item) => {
                    return (
                      <MenuItem value={item.part_id}>{item.part_name}</MenuItem>
                    );
                  })}
                </Select>
                {info.mainPart == "" && infoTrue.mainPart ? (
                  <FormHelperText> Main Part is required</FormHelperText>
                ) : (
                  ""
                )}
              </FormControl>
            </Grid>
            {info.childYesNo ? (
              <Grid item md={12}>
                <Autocomplete
                  disablePortal
                  options={info.groupList}
                  value={info.groupId}
                  getOptionLabel={(option) => (option ? option.group_name : "")}
                  onChange={(event, newValue) => {
                    if (newValue == null) {
                      info.groupId = newValue;
                      setInfo({ ...info });
                    } else {
                      info.groupId = newValue;
                      setInfo({ ...info });
                    }
                  }}
                  sx={{ padding: "0px" }}
                  className="w-100"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Parent Group Name"
                      onBlur={trueFalse}
                      helperText={
                        (info.groupId == null || info.groupId == "") &&
                        infoTrue.groupId
                          ? "Parent Group Name is required"
                          : ""
                      }
                      error={
                        (info.groupId == "" || info.groupId == null) &&
                        infoTrue.groupId &&
                        info.childYesNo
                          ? true
                          : false
                      }
                      name="state"
                      size="small"
                      sx={{ padding: "0px" }}
                      variant="outlined"
                    />
                  )}
                />
                {/* <FormControl
                  fullWidth
                  error={
                    info.groupId == "" && infoTrue.groupId && info.childYesNo
                      ? true
                      : false
                  }
                >
                  <InputLabel id="demo-simple-select-label">
                    Parent Group Name
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Parent Group Name"
                    name="groupId"
                    value={info.groupId}
                    onBlur={trueFalse}
                    onChange={input1}
                  >
                    {info.groupList.map((item) => {
                      return (
                        <MenuItem value={item.group_id}>
                          {item.group_name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                  {info.groupId == "" && infoTrue.groupId ? (
                    <FormHelperText>
                      {" "}
                      Parent Group Name is required
                    </FormHelperText>
                  ) : (
                    ""
                  )}
                </FormControl> */}
              </Grid>
            ) : (
              ""
            )}
            <Grid item md={12}>
              <TextField
                id="standard-basic"
                label="Group Name"
                name="groupName"
                error={
                  info.groupName == "" && infoTrue.groupName ? true : false
                }
                value={info.groupName}
                onChange={input1}
                onBlur={trueFalse}
                helperText={
                  info.groupName == "" && infoTrue.groupName
                    ? "Group Name is required"
                    : ""
                }
                className="w-100"
                variant="outlined"
              />
            </Grid>
          </Grid>
        </>
      );
    }
  };
  const manageDialogActionButton = () => {
    if (dialogTitle == "Delete Group ") {
      return (
        <div className="dialogMultipleActionButton">
          <Button
            variant="contained"
            className="cancelButton"
            onClick={handleClose}
          >
            Cancel
          </Button>
          {deleteCompany.isLoader ? (
            <Button
              tabindex="0"
              size="large"
              sx={{ padding: "12px 50px" }}
              className=" btn-gradient  btn-danger"
              disabled
            >
              <svg class="spinner" viewBox="0 0 50 50">
                <circle
                  class="path"
                  cx="25"
                  cy="25"
                  r="20"
                  fill="none"
                  stroke-width="5"
                ></circle>
              </svg>
            </Button>
          ) : (
            <Button
              variant="contained"
              className="btn-gradient btn-danger"
              onClick={formDelete}
            >
              Delete
            </Button>
          )}
        </div>
      );
    } else if (dialogTitle == "Add Group") {
      return (
        <div className="dialogMultipleActionButton">
          <Button
            variant="contained"
            className="cancelButton"
            onClick={handleClose}
          >
            Cancel
          </Button>
          {info.isLoader ? (
            <Button
              tabindex="0"
              size="large"
              sx={{ padding: "12px 40px" }}
              className=" btn-gradient  btn-danger"
              disabled
            >
              <svg class="spinner" viewBox="0 0 50 50">
                <circle
                  class="path"
                  cx="25"
                  cy="25"
                  r="20"
                  fill="none"
                  stroke-width="5"
                ></circle>
              </svg>
            </Button>
          ) : (
            <Button variant="contained" color="success" onClick={formAdd}>
              Add
            </Button>
          )}
        </div>
      );
    }
  };
  const columns = [
    {
      name: "SR.NO",
      selector: (row) => {
        return <span>{row.sr_no}</span>;
      },
      reorder: true,
      wrap: true,
      grow: 0.1,
    },

    {
      name: "Main Part name",
      selector: (row) => {
        return <span title={row.part_name}>{row.part_name}</span>;
      },
      sortable: true,
      reorder: true,
      grow: 1,
      wrap: true,
    },
    {
      name: "group name",
      selector: (row) => {
        return <span title={row.group_name}>{row.group_name}</span>;
      },
      sortable: true,
      reorder: true,
      grow: 1,
      wrap: true,
    },
    {
      name: "Parent Group name",
      selector: (row) => {
        return (
          <span title={row.parent_group_name}>{row.parent_group_name}</span>
        );
      },
      sortable: true,
      reorder: true,
      grow: 1,
      wrap: true,
    },

    {
      name: "Action",
      selector: (row) => {
        return (
          <span style={{ display: "flex" }}>
            {" "}
            <Button>
              <span
                className="material-icons  icon_Mar"
                style={{ color: "red" }}
                onClick={() => {
                  setDialogTitle("Delete Group ");
                  deleteCompany.isLoader = false;
                  deleteCompany.company_id = row.group_id;
                  setDeleteCopany({ ...deleteCompany });
                  setOpen(true);
                }}
              >
                delete
              </span>
            </Button>
          </span>
        );
      },
      sortable: true,
      reorder: true,
      grow: 0.3,
      wrap: true,
    },
  ];

  return (
    <div>
      <div className="app-content--inner">
        <div className="app-content--inner__wrapper mh-100-vh">
          <div style={{ opacity: 1 }}>
            <Grid container>
              <Grid item md={12} lg={12} xl={12}>
                <p className="main-heading">Group Details</p>
                {/* <CommonFilter
                  search={searchBy}
                  setParam={setParam}
                  searchWord={setSearchKeyword}
                  setcheckStatus={setcheckStatus}
                  lastUpdatedBy={resData.modified_by_users}
                />
                <br /> */}

                <Paper
                  elevation={2}
                  style={{ borderRadius: "10px" }}
                  className="pending-all-15px"
                >
                  <CardContent className="py-3">
                    <Grid container spacing={2}>
                      <Grid item sm={12} md={12} lg={12}>
                        <ColorButton
                          onClick={() => {
                            getMainPartList();
                            info.isLoader = false;
                            info.groupName = "";
                            info.mainPart = "";
                            info.groupId = "";
                            info.childYesNo = false;

                            setInfo({ ...info });
                            setinfoTrue({
                              groupName: false,
                              mainPart: false,
                              groupId: false,
                            });
                            setDialogTitle("Add Group");

                            setOpen(true);
                          }}
                        >
                          Add Group
                        </ColorButton>

                        <CommonTable
                          url={`${Url}/datatable/group_list.php`}
                          column={columns}
                          sort="0"
                          refresh={refresh}
                          search={searchBy}
                          param={param}
                          searchWord={searchKeyword}
                          checkStatus={checkStatus}
                          setResData={setResData}
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Paper>
              </Grid>
              <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                className="modalWidth100"
                fullWidth={fullWidth}
                maxWidth={maxWidth}
              >
                <BootstrapDialogTitle
                  id="customized-dialog-title"
                  className="dialogTitle"
                  onClose={handleClose}
                >
                  {dialogTitle}
                </BootstrapDialogTitle>
                <DialogContent dividers>{manageContent()}</DialogContent>
                <DialogActions>{manageDialogActionButton()}</DialogActions>
              </BootstrapDialog>
            </Grid>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GroupDetails;
