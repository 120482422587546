import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import "./group.css";
import { useNavigate, useParams } from "react-router-dom";
import { ColorButton } from "../common/CustomElement";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { IsApprove, Url } from "../global";
import { Grid, Paper, TextField } from "@mui/material";
function GroupMaster() {
  const { id } = useParams();
  console.log(id);
  const navigate = useNavigate();
  const dMain = useRef();
  const dragItem = useRef();
  const dragOverItem = useRef();
  const dragItemSub = useRef();
  const dragOverItemSub = useRef();
  const [mainLoader, setMainLoader] = useState(true);
  const [bLoder, setBLoder] = useState(false);

  const [list, setList] = useState([
    {
      name: "test1",
      sub_group_name: ["Item 2", "Item 3", "Item 4", "Item 5", "Item 6"],
    },
  ]);
  const [info, setInfo] = useState({
    formatName: "",
  });
  const input1 = (event) => {
    var { name, value } = event.target;
    setInfo((prevalue) => {
      return {
        ...prevalue,
        [name]: value,
      };
    });
  };
  const [infoTrue, setinfoTrue] = useState({
    formatName: false,
  });
  const trueFalse = (event) => {
    var { name, value } = event.target;
    setinfoTrue((prevalue) => {
      return {
        ...prevalue,
        [name]: true,
      };
    });
  };
  useEffect(() => {
    getGroupList();
  }, []);
  const getGroupList = (prop) => {
    const param = new FormData();
    if (id == undefined) {
    } else {
      param.append("format_id", id);
    }
    if (IsApprove !== "") {
      param.append("is_app", IsApprove.is_app);
      param.append("AADMIN_LOGIN_ID", IsApprove.AADMIN_LOGIN_ID);
      param.append("role_id", IsApprove.AADMIN_LOGIN_ROLE_ID);
    }

    axios
      .post(
        Url +
          `/ajaxfiles/${
            id == undefined ? "get_group_master.php" : "get_group_format.php"
          }`,

        param
      )
      .then((res) => {
        if (res.data.message == "Session has been expired") {
          localStorage.setItem("login", true);
          navigate("/login");
        }
        if (res.data.status == "error") {
          // toast.error(res.data.message);
        } else {
          if (id == undefined) {
          } else {
            info.formatName = res.data.format_name;
            setInfo({ ...info });
          }
          setList(res.data.data);
          setMainLoader(false);
        }
      });
  };
  const dragStart = (e, position) => {
    if (dMain.current != "submenu") {
      dragItem.current = position;

      console.log("dragItem", dragItem.current);
    }
  };

  const dragEnter = (e, position) => {
    if (dMain.current != "submenu") {
      dragOverItem.current = position;
    }
  };

  const drop = (e) => {
    if (dMain.current != "submenu") {
      const copyListItems = [...list];
      const dragItemContent = copyListItems[dragItem.current];
      copyListItems.splice(dragItem.current, 1);
      copyListItems.splice(dragOverItem.current, 0, dragItemContent);
      dragItem.current = null;
      dragOverItem.current = null;
      setList(copyListItems);
      console.log("dragItemContent", copyListItems);
    } else {
      dMain.current = null;
    }
  };
  const dragStartSub = (e, position, index) => {
    dragItemSub.current = { startPosition: position, startIndex: index };
    dMain.current = "submenu";
    console.log("dragItemsub", dragItemSub.current, index);
  };

  const dragEnterSub = (e, position, index) => {
    dragOverItemSub.current = { endPosition: position, endIndex: index };
  };

  const dropSub = (e, index) => {
    const copyListItems = [...list];
    const dragItemContent =
      copyListItems[dragItemSub.current.startIndex].sub_group_name[
        dragItemSub.current.startPosition
      ];
    copyListItems[dragItemSub.current.startIndex].sub_group_name.splice(
      dragItemSub.current.startPosition,
      1
    );
    copyListItems[dragOverItemSub.current.endIndex].sub_group_name.splice(
      dragOverItemSub.current.endPosition,
      0,
      dragItemContent
    );
    dragItemSub.current = null;
    dragOverItemSub.current = null;

    setList(copyListItems);
  };

  const formAdd = (prop) => {
    if (info.formatName == "") {
      toast.error("Format Name is required");
    } else {
      const param = new FormData();
      if (id == undefined) {
      } else {
        param.append("format_id", id);
      }
      if (IsApprove !== "") {
        param.append("is_app", IsApprove.is_app);
        param.append("AADMIN_LOGIN_ID", IsApprove.AADMIN_LOGIN_ID);
        param.append("role_id", IsApprove.AADMIN_LOGIN_ROLE_ID);
      }
      param.append("format_name", info.formatName);
      param.append("group_master", JSON.stringify(list));

      setBLoder(true);
      axios
        .post(
          Url +
            `/ajaxfiles/${
              id == undefined
                ? "group_formate_add.php"
                : "group_formate_update.php"
            }`,
          param
        )
        .then((res) => {
          if (res.data.message == "Session has been expired") {
            localStorage.setItem("login", true);
            navigate("/login");
          }
          if (res.data.status == "error") {
            toast.error(res.data.message);
            setBLoder(false);
          } else {
            setBLoder(false);
            toast.success(res.data.message);
            navigate("/groupMaster");
          }
        });
    }
  };
  return (
    <>
      <div className="app-content--inner">
        <div className="app-content--inner__wrapper mh-100-vh">
          {mainLoader ? (
            <div>
              {" "}
              <span class="loader1"></span>
            </div>
          ) : (
            <div style={{ opacity: 1 }}>
              <Grid container>
                <Grid item md={12} lg={12} xl={12}>
                  <p className="main-heading">
                    {id == undefined ? "Add Group Format" : "Edit Group Format"}
                  </p>

                  <Paper
                    elevation={2}
                    style={{ borderRadius: "10px" }}
                    className="pending-all-15px"
                  >
                    <TextField
                      id="standard-basic"
                      label="Format Name"
                      name="formatName"
                      error={
                        info.formatName == "" && infoTrue.formatName
                          ? true
                          : false
                      }
                      value={info.formatName}
                      onChange={input1}
                      onBlur={trueFalse}
                      helperText={
                        info.formatName == "" && infoTrue.formatName
                          ? "Format Name is required"
                          : ""
                      }
                      className="w-100"
                      variant="outlined"
                    />
                  </Paper>
                </Grid>
              </Grid>
              <div className="" style={{ display: "flex", flexWrap: "wrap " }}>
                {list &&
                  list.map((item, index) => (
                    <div
                      className="comonDrag  container1"
                      onDragStart={(e) => dragStart(e, index)}
                      onDragEnter={(e) => {
                        dragEnter(e, index);
                      }}
                      onDragEnd={drop}
                      key={index}
                      draggable="true "
                    >
                      <div
                        className="heading"
                        onDragEnter={(e) => dragEnterSub(e, 0, index)}
                        draggable
                      >
                        {item.name}
                      </div>

                      {item.sub_group_name.map((siteml, index1) => {
                        return (
                          <>
                            <p
                              className="ptage"
                              onDragEnd={dropSub}
                              onDragStart={(e) =>
                                dragStartSub(e, index1, index)
                              }
                              onDragEnter={(e) =>
                                dragEnterSub(e, index1, index)
                              }
                              key={index1}
                              draggable
                            >
                              {" "}
                              {siteml.name}
                            </p>
                          </>
                        );
                      })}
                      <p
                        onDragEnter={(e) =>
                          dragEnterSub(e, item.sub_group_name.length, index)
                        }
                        style={{ height: "100%" }}
                      ></p>
                    </div>
                  ))}
              </div>
              <div className="button">
                {bLoder == true ? (
                  <ColorButton
                    tabindex="0"
                    size="large"
                    sx={{
                      padding: "16.5px 50px",
                      marginLeft: "10px",
                    }}
                    disabled
                  >
                    <svg class="spinner" viewBox="0 0 50 50">
                      <circle
                        class="path"
                        cx="25"
                        cy="25"
                        r="20"
                        fill="none"
                        stroke-width="5"
                      ></circle>
                    </svg>
                  </ColorButton>
                ) : (
                  <ColorButton onClick={formAdd}>Submit</ColorButton>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
export default GroupMaster;
