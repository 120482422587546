import React from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { checkLogin } from "../redux/Action";
const CommonLogin = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  dispatch(checkLogin(true));
  navigate("/login");
};

export default CommonLogin;
