import changeLogin from "./Reducer";

import { combineReducers } from "redux";

const reducers = combineReducers({
  //   myNumber:changeTheNumber
  changeLogin,
});

export default reducers;
