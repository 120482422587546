import { Info } from "@mui/icons-material";
import {
  Autocomplete,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextareaAutosize,
  TextField,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ColorButton } from "../common/CustomElement";
import { IsApprove, Url } from "../global";
import { useSelector, useDispatch } from "react-redux";
import { checkLogin } from "../redux/Action";
import CommonLogin from "../common/CommonLogin";

const CreateCompany = () => {
  // const changeLogin = useSelector((state) => state.changeLogin);
  const dispatch = useDispatch();
  const { id } = useParams();
  const navigate = useNavigate();

  const [mainLoader, setMainLoader] = useState({
    country: false,
    city: false,
    currencyName: false,
    commonFormet: false,
    state: false,
    editPage: false,
  });
  const [countryData, setCountryData] = useState({
    country: [],
    city: [],
    state: [],
    currencyName: [],
    commonFormet: [],
  });
  const [info, setInfo] = useState({
    name: "",
    alias: "",
    address: "",
    ownerName: "",
    password: "",
    company_maintain: "",
    address1: "",
    address2: "",
    country: "",
    city: "",
    state: "",
    email: "",
    mobilenumber: "",
    financefrom: "",
    bookfrom: "",
    decimalPoint: "",
    currencyName: "",
    commonFormet: "",
    gstnumber: "",
    pancardNumber: "",
    tanNumber: "",
    remarks: "",
    isLodear: false,
  });
  const input1 = (event) => {
    var { name, value } = event.target;
    setInfo((prevalue) => {
      return {
        ...prevalue,
        [name]: value,
      };
    });
  };

  const [infoTrue, setinfoTrue] = useState({
    name: false,
    alias: false,
    address: false,
    address1: false,
    address2: false,
    country: false,
    company_maintain: false,
    city: false,
    state: false,
    email: false,
    ownerName: false,
    password: false,
    mobilenumber: false,
    financefrom: false,
    bookfrom: false,
    decimalPoint: false,
    currencyName: false,
    commonFormet: false,
    gstnumber: false,
    pancardNumber: false,
    tanNumber: false,
    remarks: false,
  });
  const trueFalse = (event) => {
    var { name, value } = event.target;
    setinfoTrue((prevalue) => {
      return {
        ...prevalue,
        [name]: true,
      };
    });
  };

  useEffect(() => {
    const param = new FormData();
    mainLoader.country = true;
    if (IsApprove !== "") {
      param.append("is_app", IsApprove.is_app);
      param.append("AADMIN_LOGIN_ID", IsApprove.AADMIN_LOGIN_ID);
      param.append("role_id", IsApprove.AADMIN_LOGIN_ROLE_ID);
    }
    setMainLoader({ ...mainLoader });
    axios.post(Url + "/datatable/get_countries.php", param).then((res) => {
      if (res.data.message == "Session has been expired") {
        localStorage.setItem("login", true);
        navigate("/login");
      }
      if (res.data.status == "error") {
        // toast.error(res.data.message);
      } else {
        mainLoader.country = false;
        setMainLoader({ ...mainLoader });
        countryData.country = res.data.aaData;
        setCountryData({ ...countryData });
      }
    });
    const param1 = new FormData();
    if (IsApprove !== "") {
      param1.append("is_app", IsApprove.is_app);
      param1.append("AADMIN_LOGIN_ID", IsApprove.AADMIN_LOGIN_ID);
      param1.append("role_id", IsApprove.AADMIN_LOGIN_ROLE_ID);
    }
    param1.append("action", "get_currency");
    mainLoader.currencyName = true;
    setMainLoader({ ...mainLoader });
    axios.post(Url + "/ajaxfiles/common_api.php", param1).then((res) => {
      if (res.data.message == "Session has been expired") {
        localStorage.setItem("login", true);
        navigate("/login");
      }
      if (res.data.status == "error") {
        // toast.error(res.data.message);
      } else {
        mainLoader.currencyName = false;
        setMainLoader({ ...mainLoader });
        countryData.currencyName = res.data.data;
        setCountryData({ ...countryData });
      }
    });
    const param2 = new FormData();
    if (IsApprove !== "") {
      param2.append("is_app", IsApprove.is_app);
      param2.append("AADMIN_LOGIN_ID", IsApprove.AADMIN_LOGIN_ID);
      param2.append("role_id", IsApprove.AADMIN_LOGIN_ROLE_ID);
    }
    param2.append("action", "get_currency_formate");
    mainLoader.commonFormet = true;
    setMainLoader({ ...mainLoader });
    axios.post(Url + "/ajaxfiles/common_api.php", param2).then((res) => {
      if (res.data.message == "Session has been expired") {
        localStorage.setItem("login", true);
        navigate("/login");
      }
      if (res.data.status == "error") {
        // toast.error(res.data.message);
      } else {
        console.log("dasd");
        // dispatch(checkLogin(true));
        mainLoader.commonFormet = false;
        setMainLoader({ ...mainLoader });
        countryData.commonFormet = res.data.data;
        setCountryData({ ...countryData });
        // CommonLogin();
      }
    });
  }, []);
  useEffect(() => {
    if (id == undefined) {
    } else {
      const param = new FormData();
      if (IsApprove !== "") {
        param.append("is_app", IsApprove.is_app);
        param.append("AADMIN_LOGIN_ID", IsApprove.AADMIN_LOGIN_ID);
        param.append("role_id", IsApprove.AADMIN_LOGIN_ROLE_ID);
      }
      param.append("company_id", id);
      mainLoader.editPage = true;
      setMainLoader({ ...mainLoader });
      axios
        .post(Url + "/ajaxfiles/company_single_details.php", param)
        .then((res) => {
          if (res.data.status == "error") {
            toast.error(res.data.message);
          } else {
            toast.success(res.data.message);
            info.name = res.data.data.company_name;
            info.alias = res.data.data.company_alias;
            info.address = res.data.data.company_address_1;
            info.ownerName = res.data.data.company_owner_name;
            info.password = res.data.data.company_owner_password;
            info.company_maintain = res.data.data.company_maintain;
            info.address1 = res.data.data.company_address_2;
            info.address2 = res.data.data.company_address_3;
            info.country = { nicename: res.data.data.company_country };
            info.city = res.data.data.company_city;
            info.state = res.data.data.company_state;
            info.email = res.data.data.company_email;
            info.mobilenumber = res.data.data.company_mobile_no;
            info.financefrom = res.data.data.company_financial_date;
            info.bookfrom = res.data.data.company_book_date;
            info.decimalPoint = res.data.data.company_currency_decimal;
            info.currencyName = res.data.data.company_currency_id;
            info.commonFormet = res.data.data.company_currency_format;
            info.gstnumber = res.data.data.company_gst_number;
            info.pancardNumber = res.data.data.company_pan_number;
            info.tanNumber = res.data.data.company_tan_number;
            info.remarks = res.data.data.company_remarks;
            info.isLodear = false;
            setInfo({ ...info });
            mainLoader.editPage = false;
            setMainLoader({ ...mainLoader });
            getStateData(info.country);
            getCityData(res.data.data.company_state);
          }
        });
    }
  }, [id]);
  const getStateData = (prop) => {
    if (prop == null) {
      countryData.state = [];
      setCountryData({ ...countryData });
      info.state = "";
      info.city = "";
      setInfo({ ...info });
    } else {
      const param = new FormData();
      param.append("action", "get_states");
      param.append("country", prop.nicename);
      if (IsApprove !== "") {
        param.append("is_app", IsApprove.is_app);
        param.append("AADMIN_LOGIN_ID", IsApprove.AADMIN_LOGIN_ID);
        param.append("role_id", IsApprove.AADMIN_LOGIN_ROLE_ID);
      }
      axios.post(Url + "/ajaxfiles/common_api.php", param).then((res) => {
        if (res.data.message == "Session has been expired") {
          localStorage.setItem("login", true);
          navigate("/login");
        }
        if (res.data.status == "error") {
          // toast.error(res.data.message);
        } else {
          if (id == undefined || id == null || id == "") {
            info.state = "";
            info.city = "";
            setInfo({ ...info });
          }

          countryData.state = res.data.data;
          setCountryData({ ...countryData });
        }
      });
    }
  };
  const getCityData = (prop) => {
    if (prop == null) {
      countryData.city = [];
      setCountryData({ ...countryData });
      info.state = "";
      info.city = "";
      setInfo({ ...info });
    } else {
      const param = new FormData();
      param.append("action", "get_cities");
      if (IsApprove !== "") {
        param.append("is_app", IsApprove.is_app);
        param.append("AADMIN_LOGIN_ID", IsApprove.AADMIN_LOGIN_ID);
        param.append("role_id", IsApprove.AADMIN_LOGIN_ROLE_ID);
      }
      param.append("state", prop);
      axios.post(Url + "/ajaxfiles/common_api.php", param).then((res) => {
        if (res.data.message == "Session has been expired") {
          localStorage.setItem("login", true);
          navigate("/login");
        }
        if (res.data.status == "error") {
          // toast.error(res.data.message);
        } else {
          if (id == undefined || id == null || id == "") {
            info.city = "";
            setInfo({ ...info });
          }

          countryData.city = res.data.data;
          setCountryData({ ...countryData });
        }
      });
    }
  };

  const submitAddFrom = () => {
    if (info.name == "") {
      toast.error("Name is required");
    } else if (info.alias == "") {
      toast.error("Alias is required");
    } else if (info.address == "") {
      toast.error("Address is required");
    } else if (info.address1 == "") {
      toast.error("Address1 is required");
    } else if (info.address2 == "") {
      toast.error("Landmark is required");
    } else if (info.country == "") {
      toast.error("Country is required");
    } else if (info.state == "") {
      toast.error("State is required");
    } else if (info.city == "") {
      toast.error("City is required");
    } else if (info.email == "") {
      toast.error("Email is required");
    } else if (
      !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(info.email)
    ) {
      toast.error("Enter a valid email");
    } else if (info.mobilenumber == "") {
      toast.error("Mobile Number is required");
    } else if (
      info.mobilenumber.toString().length < 4 ||
      info.mobilenumber.toString().length > 12
    ) {
      toast.error("Phone number is not valid");
    } else if (info.company_maintain == "") {
      toast.error("Company Maintain is required");
    } else if (info.financefrom == "") {
      toast.error("Finance From is required");
    } else if (info.bookfrom == "") {
      toast.error("Book From is required");
    } else if (info.decimalPoint == "") {
      toast.error("Decimal Point is required");
    } else if (info.currencyName == "") {
      toast.error("Currency Name is required");
    } else if (info.commonFormet == "") {
      toast.error("Currency Formate is required");
    } else if (info.gstnumber.length > 0 && info.gstnumber.length != 14) {
      toast.error("GST Number must be in 14 digit");
    } else if (
      info.pancardNumber.length > 0 &&
      info.pancardNumber.length != 10
    ) {
      toast.error("PanCard Number must be in 10 digit");
    } else if (info.tanNumber.length > 0 && info.tanNumber.length != 10) {
      toast.error("Tan Number must be in 10 digit");
    } else if (info.ownerName == "") {
      toast.error("Owner Name is required");
    } else if (info.password == "") {
      toast.error("Owner password is required");
    } else if (
      !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,20}$/.test(
        info.password
      )
    ) {
      toast.error(
        "Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character"
      );
    } else {
      const param = new FormData();
      if (IsApprove !== "") {
        param.append("is_app", IsApprove.is_app);
        param.append("AADMIN_LOGIN_ID", IsApprove.AADMIN_LOGIN_ID);
        param.append("role_id", IsApprove.AADMIN_LOGIN_ROLE_ID);
      }
      param.append("company_name", info.name);
      param.append("company_alias", info.alias);
      param.append("company_currency_id", info.currencyName);
      param.append("company_currency_format", info.commonFormet);
      param.append("company_currency_decimal", info.decimalPoint);
      param.append("company_address_1", info.address);
      param.append("company_address_2", info.address1);
      param.append("company_address_3", info.address2);
      param.append("company_city", info.city);
      param.append("company_state", info.state);
      param.append("company_country", info.country.nicename);
      param.append("company_email", info.email);
      param.append("company_mobile_no", info.mobilenumber);
      param.append("company_maintain", info.company_maintain);
      param.append("company_financial_date", info.financefrom);
      param.append("company_book_date", info.bookfrom);
      param.append("company_owner_name", info.ownerName);
      param.append("company_owner_password", info.password);
      param.append("company_gst_number", info.gstnumber);
      param.append("company_pan_number", info.pancardNumber);
      param.append("company_tan_number", info.tanNumber);
      param.append("remarks", info.remarks);
      if (id == undefined) {
      } else {
        param.append("company_id", id);
      }
      info.isLodear = true;
      setInfo({ ...info });
      axios
        .post(
          Url +
            `${
              id == undefined
                ? "/ajaxfiles/company_add.php"
                : "/ajaxfiles/company_update.php"
            }`,
          param
        )
        .then((res) => {
          if (res.data.status == "error") {
            toast.error(res.data.message);
            info.isLodear = false;
            setInfo({ ...info });
          } else {
            toast.success(res.data.message);
            info.isLodear = false;
            setInfo({ ...info });
            navigate("/companyDetails");
          }
        });
    }
  };
  // console.log("id", id);
  return (
    <div className="app-content--inner">
      <div className="app-content--inner__wrapper mh-100-vh">
        {mainLoader.country ||
        mainLoader.commonFormet ||
        mainLoader.currencyName ||
        (id !== undefined && mainLoader.editPage) ? (
          <div>
            {" "}
            <span class="loader1"></span>
          </div>
        ) : (
          <div style={{ opacity: 1 }}>
            <Grid container>
              <Grid item md={12}>
                <p className="main-heading">
                  {id == undefined
                    ? "Create Company"
                    : "Modified Company Details"}
                  &nbsp;
                </p>
                <Paper
                  elevation={1}
                  style={{ borderRadius: "10px" }}
                  className="w-100 "
                >
                  <div className="card-header d-flex align-items-center justify-content-between card-header-alt p-3">
                    <h5 className="font-weight-bold mb-0 text-dark">
                      Basic details
                    </h5>
                  </div>
                  <div className="divider"></div>
                  <div className="card-body position-relative">
                    <Grid container spacing={1}>
                      <Grid item md={3}>
                        <TextField
                          id="standard-basic"
                          label="Name"
                          name="name"
                          error={
                            info.name == "" && infoTrue.name ? true : false
                          }
                          value={info.name}
                          onChange={input1}
                          onBlur={trueFalse}
                          helperText={
                            info.name == "" && infoTrue.name
                              ? "Name is required"
                              : ""
                          }
                          className="w-100"
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item md={3}>
                        <TextField
                          id="standard-basic"
                          label="Alias"
                          value={info.alias}
                          error={
                            info.alias == "" && infoTrue.alias ? true : false
                          }
                          helperText={
                            info.alias == "" && infoTrue.alias
                              ? "Alias is required"
                              : ""
                          }
                          onChange={input1}
                          onBlur={trueFalse}
                          name="alias"
                          className="w-100"
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item md={3}>
                        <TextField
                          id="standard-basic"
                          label="Address"
                          name="address"
                          value={info.address}
                          className="w-100"
                          variant="outlined"
                          onChange={input1}
                          onBlur={trueFalse}
                          error={
                            info.address == "" && infoTrue.address
                              ? true
                              : false
                          }
                          helperText={
                            info.address == "" && infoTrue.address
                              ? "Address is required"
                              : ""
                          }
                        />
                      </Grid>
                      <Grid item md={3}>
                        <TextField
                          id="standard-basic"
                          label="Address1"
                          name="address1"
                          onChange={input1}
                          onBlur={trueFalse}
                          value={info.address1}
                          error={
                            info.address1 == "" && infoTrue.address1
                              ? true
                              : false
                          }
                          helperText={
                            info.address1 == "" && infoTrue.address1
                              ? "Address1 is required"
                              : ""
                          }
                          className="w-100"
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item md={3}>
                        <TextField
                          id="standard-basic"
                          label="Landmark"
                          name="address2"
                          value={info.address2}
                          className="w-100"
                          variant="outlined"
                          onChange={input1}
                          onBlur={trueFalse}
                          error={
                            info.address2 == "" && infoTrue.address2
                              ? true
                              : false
                          }
                          helperText={
                            info.address2 == "" && infoTrue.address2
                              ? "Landmark is required"
                              : ""
                          }
                        />
                      </Grid>
                      <Grid item md={3}>
                        <Autocomplete
                          disablePortal
                          options={countryData.country}
                          value={info.country}
                          getOptionLabel={(option) =>
                            option ? option.nicename : ""
                          }
                          onChange={(event, newValue) => {
                            getStateData(newValue);
                            if (newValue == null) {
                              info.country = newValue;
                              setInfo({ ...info });
                            } else {
                              info.country = newValue;
                              setInfo({ ...info });
                            }
                          }}
                          sx={{ padding: "0px" }}
                          className="w-100"
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Country"
                              size="small"
                              onBlur={trueFalse}
                              name="country"
                              helperText={
                                (info.country == null || info.country == "") &&
                                infoTrue.country
                                  ? "Country is required"
                                  : ""
                              }
                              error={
                                (info.country == null || info.country == "") &&
                                infoTrue.country
                                  ? true
                                  : false
                              }
                              sx={{ padding: "0px" }}
                              variant="outlined"
                            />
                          )}
                        />
                      </Grid>
                      <Grid item md={3}>
                        <Autocomplete
                          disablePortal
                          options={countryData.state}
                          value={info.state}
                          getOptionLabel={(option) => (option ? option : "")}
                          onChange={(event, newValue) => {
                            getCityData(newValue);
                            if (newValue == null) {
                              info.state = newValue;
                              setInfo({ ...info });
                            } else {
                              info.state = newValue;
                              setInfo({ ...info });
                            }
                          }}
                          sx={{ padding: "0px" }}
                          className="w-100"
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="State"
                              onBlur={trueFalse}
                              helperText={
                                (info.state == null || info.state == "") &&
                                infoTrue.state
                                  ? "State is required"
                                  : ""
                              }
                              error={
                                (info.state == null || info.state == "") &&
                                infoTrue.state
                                  ? true
                                  : false
                              }
                              name="state"
                              size="small"
                              sx={{ padding: "0px" }}
                              variant="outlined"
                            />
                          )}
                        />
                      </Grid>{" "}
                      <Grid item md={3}>
                        <Autocomplete
                          disablePortal
                          options={countryData.city}
                          value={info.city}
                          getOptionLabel={(option) => (option ? option : "")}
                          onChange={(event, newValue) => {
                            if (newValue == null) {
                              info.city = newValue;
                              setInfo({ ...info });
                            } else {
                              info.city = newValue;
                              setInfo({ ...info });
                            }
                          }}
                          sx={{ padding: "0px" }}
                          className="w-100"
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="City"
                              size="small"
                              onBlur={trueFalse}
                              helperText={
                                (info.city == null || info.city == "") &&
                                infoTrue.city
                                  ? "City is required"
                                  : ""
                              }
                              error={
                                (info.city == null || info.city == "") &&
                                infoTrue.city
                                  ? true
                                  : false
                              }
                              name="city"
                              sx={{ padding: "0px" }}
                              variant="outlined"
                            />
                          )}
                        />
                      </Grid>
                      <Grid item md={3}>
                        <TextField
                          id="standard-basic"
                          error={
                            (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
                              info.email
                            ) ||
                              info.email == "") &&
                            infoTrue.email == true
                              ? true
                              : false
                          }
                          label="Email"
                          name="email"
                          value={info.email}
                          className="w-100"
                          variant="outlined"
                          onChange={input1}
                          onBlur={trueFalse}
                          helperText={
                            info.email == "" && infoTrue.email
                              ? "Email is required"
                              : !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
                                  info.email
                                ) && infoTrue.email
                              ? "Enter a valid email"
                              : ""
                          }
                        />
                      </Grid>
                      <Grid item md={3}>
                        <TextField
                          id="standard-basic"
                          label="Mobile Number"
                          name="mobilenumber"
                          className="w-100"
                          type="text"
                          variant="outlined"
                          value={info.mobilenumber}
                          onChange={(e) => {
                            if (
                              Number(e.target.value) > 0 ||
                              e.target.value == ""
                            ) {
                              input1(e);
                            }
                          }}
                          onBlur={trueFalse}
                          error={
                            (info.mobilenumber.toString().length < 4 ||
                              info.mobilenumber.toString().length > 12 ||
                              info.mobilenumber == "") &&
                            infoTrue.mobilenumber
                              ? true
                              : false
                          }
                          helperText={
                            info.mobilenumber == "" && infoTrue.mobilenumber
                              ? "Mobile Number is required"
                              : (info.mobilenumber.toString().length < 4 ||
                                  info.mobilenumber.toString().length > 12) &&
                                infoTrue.mobilenumber
                              ? "Mobile Number is not valid"
                              : ""
                          }
                        />
                      </Grid>
                    </Grid>
                  </div>
                </Paper>
              </Grid>
              <Grid container spacing={1} className="mt-10">
                <Grid item md={6}>
                  <Paper
                    elevation={1}
                    style={{ borderRadius: "10px" }}
                    className="w-100"
                  >
                    <div className="card-header d-flex align-items-center justify-content-between card-header-alt p-3">
                      <h5 className="font-weight-bold mb-0 text-dark">
                        Other details
                      </h5>
                    </div>
                    <div className="divider"></div>
                    <div className="card-body position-relative">
                      <Grid container spacing={1}>
                        <Grid item md={6}>
                          <TextField
                            id="standard-basic"
                            label="Company Maintain"
                            name="company_maintain"
                            className="w-100"
                            variant="outlined"
                            value={info.company_maintain}
                            onChange={input1}
                            onBlur={trueFalse}
                            error={
                              info.company_maintain == "" &&
                              infoTrue.company_maintain
                                ? true
                                : false
                            }
                            helperText={
                              info.company_maintain == "" &&
                              infoTrue.company_maintain
                                ? "Company Maintain From is required"
                                : ""
                            }
                          />
                        </Grid>
                        <Grid item md={6}>
                          <TextField
                            id="standard-basic"
                            label="Finance From"
                            name="financefrom"
                            type="date"
                            className="w-100"
                            variant="outlined"
                            value={info.financefrom}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            onChange={input1}
                            onBlur={trueFalse}
                            error={
                              info.financefrom == "" && infoTrue.financefrom
                                ? true
                                : false
                            }
                            helperText={
                              info.financefrom == "" && infoTrue.financefrom
                                ? "Finance From is required"
                                : ""
                            }
                          />
                        </Grid>
                        <Grid item md={6}>
                          <TextField
                            id="standard-basic"
                            label="Book From"
                            name="bookfrom"
                            value={info.bookfrom}
                            type="date"
                            className="w-100"
                            variant="outlined"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            onChange={input1}
                            onBlur={trueFalse}
                            error={
                              info.bookfrom == "" && infoTrue.bookfrom
                                ? true
                                : false
                            }
                            helperText={
                              info.bookfrom == "" && infoTrue.bookfrom
                                ? "Book From is required"
                                : ""
                            }
                          />
                        </Grid>
                        <Grid item md={6}>
                          <TextField
                            id="standard-basic"
                            label="Decimal Point"
                            name="decimalPoint"
                            className="w-100"
                            value={info.decimalPoint}
                            variant="outlined"
                            onChange={(e) => {
                              if (
                                Number(e.target.value) > 0 ||
                                e.target.value == ""
                              ) {
                                input1(e);
                              }
                            }}
                            onBlur={trueFalse}
                            error={
                              info.decimalPoint == "" && infoTrue.decimalPoint
                                ? true
                                : false
                            }
                            helperText={
                              info.decimalPoint == "" && infoTrue.decimalPoint
                                ? "Decimal Point is required"
                                : ""
                            }
                          />
                        </Grid>
                        <Grid item md={6}>
                          <FormControl
                            fullWidth
                            error={
                              infoTrue.currencyName && info.currencyName == ""
                                ? true
                                : false
                            }
                          >
                            <InputLabel id="demo-simple-select-label">
                              Currency Name
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              label="Currency Name"
                              name="currencyName"
                              value={info.currencyName}
                              onChange={input1}
                              onBlur={trueFalse}
                            >
                              {countryData.currencyName.map((item, index) => {
                                return (
                                  <MenuItem value={item.currency_id}>
                                    {item.currency_name}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                            {infoTrue.currencyName &&
                            info.currencyName == "" ? (
                              <FormHelperText>
                                Currency Name is required
                              </FormHelperText>
                            ) : (
                              ""
                            )}
                          </FormControl>
                        </Grid>
                        <Grid item md={6}>
                          <FormControl
                            fullWidth
                            error={
                              infoTrue.commonFormet && info.commonFormet == ""
                                ? true
                                : false
                            }
                          >
                            <InputLabel id="demo-simple-select-label">
                              Currency Formate
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              label="Currency Formate"
                              name="commonFormet"
                              value={info.commonFormet}
                              onChange={input1}
                              onBlur={trueFalse}
                            >
                              {countryData.commonFormet.map((item, index) => {
                                return <MenuItem value={item}>{item}</MenuItem>;
                              })}
                            </Select>
                            {infoTrue.commonFormet &&
                            info.commonFormet == "" ? (
                              <FormHelperText>
                                Currency Formate is required
                              </FormHelperText>
                            ) : (
                              ""
                            )}
                          </FormControl>
                        </Grid>
                      </Grid>
                    </div>
                  </Paper>
                </Grid>
                <Grid item md={3}>
                  <Paper
                    elevation={1}
                    style={{ borderRadius: "10px" }}
                    className="w-100"
                  >
                    <div className="card-header d-flex align-items-center justify-content-between card-header-alt p-3">
                      <h5 className="font-weight-bold mb-0 text-dark">
                        Tax Information
                      </h5>
                    </div>
                    <div className="divider"></div>
                    <div className="card-body position-relative">
                      <Grid container spacing={1}>
                        <Grid item md={12}>
                          <TextField
                            id="standard-basic"
                            label="GST Number (Optional)"
                            name="gstnumber"
                            value={info.gstnumber}
                            error={
                              info.gstnumber.length > 0 &&
                              infoTrue.gstnumber &&
                              info.gstnumber.length != 14
                                ? true
                                : false
                            }
                            helperText={
                              info.gstnumber.length != 14 &&
                              info.gstnumber.length > 0 &&
                              infoTrue.gstnumber
                                ? "GST Number must be in 14 digit"
                                : ""
                            }
                            className="w-100"
                            variant="outlined"
                            onChange={input1}
                            onBlur={trueFalse}
                          />
                        </Grid>
                        <Grid item md={12}>
                          <TextField
                            id="standard-basic"
                            label="PanCard Number(Optional)"
                            name="pancardNumber"
                            className="w-100"
                            variant="outlined"
                            value={info.pancardNumber}
                            onChange={input1}
                            onBlur={trueFalse}
                            error={
                              info.pancardNumber.length > 0 &&
                              infoTrue.pancardNumber &&
                              info.pancardNumber.length != 10
                                ? true
                                : false
                            }
                            helperText={
                              info.pancardNumber.length != 10 &&
                              info.pancardNumber.length > 0 &&
                              infoTrue.pancardNumber
                                ? "PanCard Number must be in 10 digit"
                                : ""
                            }
                          />
                        </Grid>
                        <Grid item md={12}>
                          <TextField
                            id="standard-basic"
                            label="Tan Number (Optional)"
                            name="tanNumber"
                            className="w-100"
                            value={info.tanNumber}
                            variant="outlined"
                            onChange={input1}
                            onBlur={trueFalse}
                            error={
                              info.tanNumber.length > 0 &&
                              infoTrue.tanNumber &&
                              info.tanNumber.length != 10
                                ? true
                                : false
                            }
                            helperText={
                              info.tanNumber.length != 10 &&
                              info.tanNumber.length > 0 &&
                              infoTrue.tanNumber
                                ? "Tan Number must be in 10 digit"
                                : ""
                            }
                          />
                        </Grid>
                      </Grid>
                    </div>
                  </Paper>
                </Grid>
                <Grid item md={3}>
                  <Paper
                    elevation={1}
                    style={{ borderRadius: "10px" }}
                    className="w-100"
                  >
                    <div className="card-header d-flex align-items-center justify-content-between card-header-alt p-3">
                      <h5 className="font-weight-bold mb-0 text-dark">
                        owner Information
                      </h5>
                    </div>
                    <div className="divider"></div>
                    <div className="card-body position-relative">
                      <Grid container spacing={1}>
                        <Grid item md={12}>
                          <TextField
                            id="standard-basic"
                            label="Owner Name"
                            name="ownerName"
                            value={info.ownerName}
                            className="w-100"
                            variant="outlined"
                            onChange={input1}
                            onBlur={trueFalse}
                            error={
                              info.ownerName == "" && infoTrue.ownerName
                                ? true
                                : false
                            }
                            helperText={
                              info.ownerName == "" && infoTrue.ownerName
                                ? "Owner Name is required"
                                : ""
                            }
                          />
                        </Grid>
                        <Grid item md={12}>
                          <TextField
                            id="standard-basic"
                            label="Owner password"
                            name="password"
                            type="password"
                            className="w-100"
                            variant="outlined"
                            value={info.password}
                            onChange={input1}
                            onBlur={trueFalse}
                            error={
                              (!info.password.match(/[A-Z]/g) ||
                                !info.password.match(/[a-z]/g) ||
                                !info.password.match(/[0-9]/g) ||
                                info.password == "" ||
                                info.password.length < 8 ||
                                info.password.length > 20 ||
                                !info.password.match(/[!@#$%^&*()_+=]/g)) &&
                              infoTrue.password == true
                                ? true
                                : false
                            }
                            helperText={
                              info.password == "" && infoTrue.password == true
                                ? "Enter your password"
                                : infoTrue.password == true &&
                                  (info.password.length < 8 ||
                                    info.password.length > 20)
                                ? "Password must contain atleast 8-20 characters"
                                : infoTrue.password == true &&
                                  (!info.password.match(/[A-Z]/g) ||
                                    !info.password.match(/[a-z]/g) ||
                                    !info.password.match(/[0-9]/g) ||
                                    !info.password.match(/[!@#$%^&*()_+=]/g))
                                ? "Atleast one lower case, upper case,special character and number required"
                                : ""
                            }
                          />
                        </Grid>
                      </Grid>
                    </div>
                  </Paper>
                </Grid>
              </Grid>
              <Grid container spacing={1} className="mt-10">
                <Grid item md={12}>
                  <Paper
                    elevation={1}
                    style={{ borderRadius: "10px" }}
                    className="w-100"
                  >
                    <div className="card-header d-flex align-items-center justify-content-between card-header-alt p-3">
                      <h5 className="font-weight-bold mb-0 text-dark">
                        Remarks
                      </h5>
                    </div>
                    <div className="divider"></div>
                    <div className="card-body position-relative">
                      <Grid container spacing={1}>
                        <Grid md={12} className="w-100">
                          <TextareaAutosize
                            aria-label="empty textarea"
                            placeholder="Remarks"
                            name="remarks"
                            value={info.remarks}
                            onChange={input1}
                            className="w-100 H-80"
                          />
                          <div className="text-right">
                            <Button
                              variant="contained"
                              color="error"
                              onClick={() => {
                                navigate("/companyDetails");
                              }}
                            >
                              CANCEL
                            </Button>
                            {info.isLodear ? (
                              <Button
                                tabindex="0"
                                size="large"
                                sx={{
                                  padding: "16.5px 50px",
                                  marginLeft: "10px",
                                }}
                                className=" btn-gradient  btn-success "
                                disabled
                              >
                                <svg class="spinner" viewBox="0 0 50 50">
                                  <circle
                                    class="path"
                                    cx="25"
                                    cy="25"
                                    r="20"
                                    fill="none"
                                    stroke-width="5"
                                  ></circle>
                                </svg>
                              </Button>
                            ) : (
                              <Button
                                variant="contained"
                                color="success"
                                onClick={submitAddFrom}
                                sx={{ marginLeft: "10px" }}
                              >
                                SUBMIT
                              </Button>
                            )}
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                  </Paper>
                </Grid>
              </Grid>
            </Grid>
          </div>
        )}
      </div>
    </div>
  );
};

export default CreateCompany;
