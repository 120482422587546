const Url = "https://accountcrm.rightqinfotech.com";

// const IsApprove = {
//   is_app: "1",
//   AADMIN_LOGIN_ID: "1",
//   AADMIN_LOGIN_ROLE_ID: "1",
// };
const UserInfo = {};
const IsApprove = "";
export { Url, IsApprove, UserInfo };
