import React, { useState } from "react";
import {
  Autocomplete,
  FormControlLabel,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import CardContent from "@mui/material/CardContent";
import { Paper } from "@mui/material";
import { styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import { ColorButton, IOSSwitch } from "../common/CustomElement";
import { Button } from "@mui/material";
import CommonTable from "../common/CommonTable";
import { useNavigate } from "react-router-dom";
import CustomImageModal from "../common/CustomImageModal";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { IsApprove, Url } from "../global";
import CommonFilter from "../common/CommonFilter";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import NewDate from "../common/NewDate";
import { Add, EditRoadRounded, Info } from "@mui/icons-material";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(0),
  },
  "& .MuiInputBase-input": {
    borderRadius: 9,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "8px 26px 8px 10px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 9,
      borderColor: "#80bdff",
    },
  },
}));
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};
const YearMaster = (permission) => {
  // console.log(prop);
  const [param, setParam] = useState({
    start_date: "",
    end_date: "",
  });
  const navigate = useNavigate();
  const [searchKeyword, setSearchKeyword] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [checkStatus, setcheckStatus] = useState("");
  const [open, setOpen] = useState(false);
  const [dialogTitle, setDialogTitle] = useState("");
  const [fullWidth, setFullWidth] = useState(true);
  const [resData, setResData] = useState({});
  const [maxWidth, setMaxWidth] = useState("sm");
  const [info, setInfo] = useState({
    company_name: "",
    location: "",
    fyear_name: "",
    month: "",
    company_list: [],
    year: "",
    isLoader: false,
    status: 1,
  });
  const [listData, setListData] = useState({
    location: [],
    year: [],
    month: [],
  });
  const input1 = (event) => {
    var { name, value } = event.target;
    setInfo((prevalue) => {
      return {
        ...prevalue,
        [name]: value,
      };
    });
  };
  const [infoTrue, setinfoTrue] = useState({
    company_name: false,
    location: false,
    fyear_name: false,
    month: false,
    year: false,
  });
  const trueFalse = (event) => {
    var { name, value } = event.target;
    setinfoTrue((prevalue) => {
      return {
        ...prevalue,
        [name]: true,
      };
    });
  };
  const [searchBy, setSearchBy] = useState([
    {
      label: "ROLE NAME",
      value: false,
      name: "role",
    },
    {
      label: "NAME",
      value: false,
      name: "user_first_name",
    },
    {
      label: "Email",
      value: false,
      name: "user_email",
    },
    {
      label: "PAYMENT METHOD",
      value: false,
      name: "payment_method",
    },
    {
      label: "Phone Number",
      value: false,
      name: "user_phone",
    },
    {
      label: "MANAGER",
      value: false,
      name: "master_manager_name",
    },
    {
      label: "ACCOUNT TARGET",
      value: false,
      name: "ac_target",
    },
    {
      label: "MONEY IN TARGET",
      value: false,
      name: "master_manager_name",
    },
  ]);
  const re = /^[A-Za-z_ ]*$/;
  const [deleteCompany, setDeleteCopany] = useState({
    isLoader: false,
    company_id: "",
  });
  const handleClose = () => {
    setOpen(false);
  };
  toast.configure();

  const formAdd = () => {
    const param = new FormData();
    if (info.company_name == "" || info.company_name == null) {
      toast.error("Company Name is required");
    } else if (info.location == "" || info.location == null) {
      toast.error("Location is required");
    } else if (info.fyear_name == "") {
      toast.error("Financial Year Name is required");
    } else if (info.month == "") {
      toast.error("Month is required");
    } else if (info.year == "") {
      toast.error("Year is required");
    } else {
      if (IsApprove !== "") {
        param.append("is_app", IsApprove.is_app);
        param.append("AADMIN_LOGIN_ID", IsApprove.AADMIN_LOGIN_ID);
        param.append("role_id", IsApprove.AADMIN_LOGIN_ROLE_ID);
      }
      param.append("company_id", info.company_name.company_id);
      param.append("location_id", info.location.location_id);
      param.append("year_name", info.fyear_name);
      param.append("financial_year_month", info.month);
      param.append("financial_year_year", info.year);
      param.append("financial_status", info.status);

      deleteCompany.isLoader = true;
      setDeleteCopany({ ...deleteCompany });
      info.isLoader = true;
      setInfo({ ...info });
      axios
        .post(Url + "/ajaxfiles/financial_year_add.php", param)
        .then((res) => {
          if (res.data.message == "Session has been expired") {
            localStorage.setItem("login", true);
            navigate("/login");
          }
          if (res.data.status == "error") {
            toast.error(res.data.message);
            info.isLoader = false;
            setInfo({ ...info });
          } else {
            toast.success(res.data.message);
            info.isLoader = false;
            setInfo({ ...info });
            setRefresh(!refresh);
            setOpen(false);
          }
        });
    }
  };
  const formDelete = () => {
    const param = new FormData();

    if (IsApprove !== "") {
      param.append("is_app", IsApprove.is_app);
      param.append("AADMIN_LOGIN_ID", IsApprove.AADMIN_LOGIN_ID);
      param.append("role_id", IsApprove.AADMIN_LOGIN_ROLE_ID);
    }
    param.append("year_id", deleteCompany.company_id);
    deleteCompany.isLoader = true;
    setDeleteCopany({ ...deleteCompany });
    axios
      .post(Url + "/ajaxfiles/fiancial_year_delete.php", param)
      .then((res) => {
        if (res.data.message == "Session has been expired") {
          localStorage.setItem("login", true);
          navigate("/login");
        }
        if (res.data.status == "error") {
          toast.error(res.data.message);
          deleteCompany.isLoader = false;
          setDeleteCopany({ ...deleteCompany });
        } else {
          toast.success(res.data.message);
          deleteCompany.isLoader = false;
          setDeleteCopany({ ...deleteCompany });
          setOpen(false);
          setRefresh(!refresh);
        }
      });
  };

  const formEdit = (prop) => {
    console.log("company_id", info.company_name, info.company_name.company_id);
    if (info.fyear_name == "") {
      toast.error("Financial Year Name is required");
    } else if (info.month == "") {
      toast.error("Month is required");
    } else if (info.year == "") {
      toast.error("Year is required");
    } else {
      const param = new FormData();

      if (IsApprove !== "") {
        param.append("is_app", IsApprove.is_app);
        param.append("AADMIN_LOGIN_ID", IsApprove.AADMIN_LOGIN_ID);
        param.append("role_id", IsApprove.AADMIN_LOGIN_ROLE_ID);
      }
      param.append("year_id", info.year_id);
      param.append("company_id", info.company_id);
      param.append("location_id", info.location_id);
      param.append("year_name", info.fyear_name);
      param.append("financial_year_month", info.month);
      param.append("financial_year_year", info.year);
      param.append("financial_status", info.status);

      info.isLoader = true;
      setInfo({ ...info });
      axios
        .post(Url + "/ajaxfiles/financial_year_update.php", param)
        .then((res) => {
          if (res.data.message == "Session has been expired") {
            localStorage.setItem("login", true);
            navigate("/login");
          }
          if (res.data.status == "error") {
            toast.error(res.data.message);
            info.isLoader = false;
            setInfo({ ...info });
          } else {
            toast.success(res.data.message);
            info.isLoader = false;
            setInfo({ ...info });
            setRefresh(!refresh);
            setOpen(false);
          }
        });
    }
  };
  const getCompanyData = () => {
    const param = new FormData();
    if (IsApprove !== "") {
      param.append("is_app", IsApprove.is_app);
      param.append("AADMIN_LOGIN_ID", IsApprove.AADMIN_LOGIN_ID);
      param.append("role_id", IsApprove.AADMIN_LOGIN_ROLE_ID);
    }
    axios.post(Url + "/ajaxfiles/get_company_list.php", param).then((res) => {
      if (res.data.message == "Session has been expired") {
        localStorage.setItem("login", true);
        navigate("/login");
      }
      if (res.data.status == "error") {
        // toast.error(res.data.message);
      } else {
        info.company_list = res.data.data;
        setInfo({ ...info });
      }
    });
  };
  console.log(info, "info");
  const chanestatus = (p1, id) => {
    const param = new FormData();
    if (IsApprove !== "") {
      param.append("is_app", IsApprove.is_app);
      param.append("AADMIN_LOGIN_ID", IsApprove.AADMIN_LOGIN_ID);
      param.append("role_id", IsApprove.AADMIN_LOGIN_ROLE_ID);
    }
    param.append("year_id", id);
    param.append("is_active", p1 == false ? 0 : 1);

    axios
      .post(Url + "/ajaxfiles/fiancial_year_status_changed.php", param)
      .then((res) => {
        if (res.data.message == "Session has been expired") {
          localStorage.setItem("login", true);
          navigate("/login");
        }
        if (res.data.status == "error") {
          toast.error(res.data.message);
        } else {
          toast.success(res.data.message);
        }
      });
  };

  const getMonthYear = () => {
    const param = new FormData();
    if (IsApprove !== "") {
      param.append("is_app", IsApprove.is_app);
      param.append("AADMIN_LOGIN_ID", IsApprove.AADMIN_LOGIN_ID);
      param.append("role_id", IsApprove.AADMIN_LOGIN_ROLE_ID);
    }
    param.append("action", "get_month_year");

    axios.post(Url + "/ajaxfiles/common_api.php", param).then((res) => {
      if (res.data.message == "Session has been expired") {
        localStorage.setItem("login", true);
        navigate("/login");
      }
      if (res.data.status == "error") {
        toast.error(res.data.message);
      } else {
        listData.month = res.data.month_list;
        listData.year = res.data.year_list;
        setListData({ ...listData });
        // info.company_list = res.data.data;
        // setInfo({ ...info });
      }
    });
  };
  const getLocations = (prop, checkL) => {
    if (prop == "" || prop == null) {
      info.location = "";
      setInfo({ ...info });
      listData.location = [];
      setListData({ ...listData });
    } else {
      const param = new FormData();
      if (IsApprove !== "") {
        param.append("is_app", IsApprove.is_app);
        param.append("AADMIN_LOGIN_ID", IsApprove.AADMIN_LOGIN_ID);
        param.append("role_id", IsApprove.AADMIN_LOGIN_ROLE_ID);
      }
      param.append("company_id", prop.company_id);

      axios
        .post(Url + "/ajaxfiles/get_location_list.php", param)
        .then((res) => {
          if (res.data.message == "Session has been expired") {
            localStorage.setItem("login", true);
            navigate("/login");
          }
          if (res.data.status == "error") {
            toast.error(res.data.message);
          } else {
            if (checkL == "yes") {
              info.location = permission.companyLocation.location;
              setInfo({ ...info });
            } else {
              info.location = "";
              setInfo({ ...info });
            }

            listData.location = res.data.data;
            listData.month = res.data.month_list;
            listData.year = res.data.year_list;
            setListData({ ...listData });
            // info.company_list = res.data.data;
            // setInfo({ ...info });
          }
        });
    }
  };
  const manageContent = () => {
    if (dialogTitle == "Delete Financial Year") {
      return (
        <>
          <div>
            <h1>Are you sure ?</h1>
            Do you want to sure delete this Location ?
          </div>
        </>
      );
    } else if (
      dialogTitle == "Add Financial Year" ||
      dialogTitle == "Edit Financial Year"
    ) {
      return (
        <>
          <Grid container spacing={1}>
            {dialogTitle == "Add Financial Year" ? (
              <Grid item md={6}>
                <Autocomplete
                  disablePortal
                  options={info.company_list}
                  value={info.company_name}
                  getOptionLabel={(option) =>
                    option ? option.company_name : ""
                  }
                  onChange={(event, newValue) => {
                    getLocations(newValue);
                    info.company_name = newValue;
                    setInfo({ ...info });
                  }}
                  sx={{ padding: "0px" }}
                  className="w-100"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Company"
                      size="small"
                      onBlur={trueFalse}
                      name="company_name"
                      helperText={
                        (info.company_name == null ||
                          info.company_name == "") &&
                        infoTrue.company_name
                          ? "Company is required"
                          : ""
                      }
                      error={
                        (info.company_name == null ||
                          info.company_name == "") &&
                        infoTrue.company_name
                          ? true
                          : false
                      }
                      sx={{ padding: "0px" }}
                      variant="outlined"
                    />
                  )}
                />
              </Grid>
            ) : (
              <Grid item md={6}>
                <TextField
                  disabled
                  label="Company"
                  size="small"
                  name="company"
                  className="w-100"
                  value={info.company_name}
                  sx={{ padding: "0px" }}
                  variant="outlined"
                />
              </Grid>
            )}
            {dialogTitle == "Add Financial Year" ? (
              <Grid item md={6}>
                <Autocomplete
                  disablePortal
                  options={listData.location}
                  value={info.location}
                  getOptionLabel={(option) =>
                    option ? option.location_name : ""
                  }
                  onChange={(event, newValue) => {
                    info.location = newValue;
                    setInfo({ ...info });
                  }}
                  sx={{ padding: "0px" }}
                  className="w-100"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Location"
                      size="small"
                      onBlur={trueFalse}
                      name="location"
                      helperText={
                        (info.location == null || info.location == "") &&
                        infoTrue.location
                          ? "Location is required"
                          : ""
                      }
                      error={
                        (info.location == null || info.location == "") &&
                        infoTrue.location
                          ? true
                          : false
                      }
                      sx={{ padding: "0px" }}
                      variant="outlined"
                    />
                  )}
                />
              </Grid>
            ) : (
              <Grid item md={6}>
                <TextField
                  disabled
                  label="Location"
                  size="small"
                  name="location"
                  className="w-100"
                  value={info.location}
                  sx={{ padding: "0px" }}
                  variant="outlined"
                />
              </Grid>
            )}

            <Grid item md={6}>
              <TextField
                id="standard-basic"
                label="Financial Year Name"
                name="fyear_name"
                error={
                  info.fyear_name == "" && infoTrue.fyear_name ? true : false
                }
                value={info.fyear_name}
                onChange={input1}
                onBlur={trueFalse}
                helperText={
                  info.fyear_name == "" && infoTrue.fyear_name
                    ? "financial year name is required"
                    : ""
                }
                className="w-100"
                variant="outlined"
              />
            </Grid>
            <Grid item md={6}>
              <FormControl
                fullWidth
                error={infoTrue.month && info.month == "" ? true : false}
              >
                <InputLabel id="demo-simple-select-label">Month</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Month"
                  name="month"
                  value={info.month}
                  onChange={input1}
                  onBlur={trueFalse}
                >
                  {listData.month.map((item, index) => {
                    return <MenuItem value={item}>{item}</MenuItem>;
                  })}
                </Select>
                {infoTrue.month && info.month == "" ? (
                  <FormHelperText>Month Formate is required</FormHelperText>
                ) : (
                  ""
                )}
              </FormControl>
            </Grid>

            <Grid item md={6}>
              <FormControl
                fullWidth
                error={infoTrue.year && info.year == "" ? true : false}
              >
                <InputLabel id="demo-simple-select-label">Year</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Year"
                  name="year"
                  value={info.year}
                  onChange={input1}
                  onBlur={trueFalse}
                >
                  {listData.year.map((item, index) => {
                    return <MenuItem value={item}>{item}</MenuItem>;
                  })}
                </Select>
                {infoTrue.year && info.year == "" ? (
                  <FormHelperText>Year is required</FormHelperText>
                ) : (
                  ""
                )}
              </FormControl>
            </Grid>
            <Grid item md={6}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Status</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Status"
                  name="status"
                  value={info.status}
                  onChange={input1}
                >
                  <MenuItem value={1}>Yes</MenuItem>;
                  <MenuItem value={0}>No</MenuItem>;
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </>
      );
    }
  };
  const manageDialogActionButton = () => {
    if (dialogTitle == "Delete Financial Year") {
      return (
        <div className="dialogMultipleActionButton">
          <Button
            variant="contained"
            className="cancelButton"
            onClick={handleClose}
          >
            Cancel
          </Button>
          {deleteCompany.isLoader ? (
            <Button
              tabindex="0"
              size="large"
              sx={{ padding: "12px 50px" }}
              className=" btn-gradient  btn-danger"
              disabled
            >
              <svg class="spinner" viewBox="0 0 50 50">
                <circle
                  class="path"
                  cx="25"
                  cy="25"
                  r="20"
                  fill="none"
                  stroke-width="5"
                ></circle>
              </svg>
            </Button>
          ) : (
            <Button
              variant="contained"
              className="btn-gradient btn-danger"
              onClick={formDelete}
            >
              Delete
            </Button>
          )}
        </div>
      );
    } else if (
      dialogTitle == "Add Financial Year" ||
      dialogTitle == "Edit Financial Year"
    ) {
      return (
        <div className="dialogMultipleActionButton">
          <Button
            variant="contained"
            className="cancelButton"
            onClick={handleClose}
          >
            Cancel
          </Button>
          {info.isLoader ? (
            <Button
              tabindex="0"
              size="large"
              sx={{ padding: "12px 40px" }}
              className=" btn-gradient  btn-danger"
              disabled
            >
              <svg class="spinner" viewBox="0 0 50 50">
                <circle
                  class="path"
                  cx="25"
                  cy="25"
                  r="20"
                  fill="none"
                  stroke-width="5"
                ></circle>
              </svg>
            </Button>
          ) : (
            <Button
              variant="contained"
              color="success"
              onClick={() => {
                if (dialogTitle == "Add Financial Year") {
                  formAdd();
                } else {
                  formEdit();
                }
              }}
            >
              {dialogTitle == "Add Financial Year" ? "Add" : "Edit"}
            </Button>
          )}
        </div>
      );
    }
  };
  const columns = [
    {
      name: "SR.NO",
      selector: (row) => {
        return <span>{row.sr_no}</span>;
      },
      reorder: true,
      wrap: true,
      grow: 0.1,
    },
    {
      name: "Year Name",
      selector: (row) => {
        return <span title={row.year_name}>{row.year_name}</span>;
      },
      sortable: true,
      reorder: true,
      grow: 0.3,
      wrap: true,
    },

    {
      name: "year month",
      selector: (row) => {
        return (
          <span title={row.financial_year_month}>
            {row.financial_year_month}
          </span>
        );
      },
      sortable: true,
      reorder: true,
      grow: 1,
      wrap: true,
    },

    {
      name: "location name",
      selector: (row) => {
        return <span title={row.location_name}>{row.location_name}</span>;
      },
      sortable: true,
      reorder: true,
      grow: 0.7,
      wrap: true,
    },
    {
      name: "company_name",
      selector: (row) => {
        return <span title={row.company_name}>{row.company_name}</span>;
      },
      sortable: true,
      reorder: true,
      grow: 0.9,
      wrap: true,
    },
    {
      name: "Year Status",
      selector: (row) => {
        return (
          <span title={row.finacial_year_status}>
            {" "}
            <FormControlLabel
              control={
                <IOSSwitch
                  sx={{ m: 2 }}
                  defaultChecked={row.finacial_year_status == 1 ? true : false}
                  //   checked={false}
                  onChange={(e) => {
                    chanestatus(e.target.checked, row.year_id);
                  }}
                />
              }
            />
          </span>
        );
      },
      sortable: true,
      reorder: true,
      grow: 0.7,
      wrap: true,
    },

    {
      name: "Action",
      selector: (row) => {
        return (
          <span style={{ display: "flex" }}>
            {" "}
            <Button>
              <span
                className="material-icons  icon_Mar"
                style={{ color: "green" }}
                onClick={() => {
                  info.company_name = row.company_name;
                  info.location = row.location_name;
                  info.fyear_name = row.year_name;
                  info.month = row.financial_month;
                  info.year = row.financial_year;
                  info.status = row.finacial_year_status;
                  info.year_id = row.year_id;
                  info.company_id = row.company_id;
                  info.location_id = row.location_id;
                  info.isLoader = false;
                  setInfo({ ...info });
                  setinfoTrue({
                    remark: false,
                    name: false,
                    alias: false,
                    company: false,
                  });
                  getMonthYear();
                  setDialogTitle("Edit Financial Year");

                  setOpen(true);
                }}
              >
                edit
              </span>
            </Button>
            <Button>
              <span
                className="material-icons  icon_Mar"
                style={{ color: "red" }}
                onClick={() => {
                  setDialogTitle("Delete Financial Year");
                  deleteCompany.isLoader = false;
                  deleteCompany.company_id = row.year_id;
                  setDeleteCopany({ ...deleteCompany });
                  setOpen(true);
                }}
              >
                delete
              </span>
            </Button>
          </span>
        );
      },
      sortable: true,
      reorder: true,
      grow: 0.3,
      wrap: true,
    },
  ];

  return (
    <div>
      <div className="app-content--inner">
        <div className="app-content--inner__wrapper mh-100-vh">
          <div style={{ opacity: 1 }}>
            <Grid container>
              <Grid item md={12} lg={12} xl={12}>
                <p className="main-heading">Financial Year</p>
                {/* <CommonFilter
                  search={searchBy}
                  setParam={setParam}
                  searchWord={setSearchKeyword}
                  setcheckStatus={setcheckStatus}
                  lastUpdatedBy={resData.modified_by_users}
                />
                <br /> */}

                <Paper
                  elevation={2}
                  style={{ borderRadius: "10px" }}
                  className="pending-all-15px"
                >
                  <CardContent className="py-3">
                    <Grid container spacing={2}>
                      <Grid item sm={12} md={12} lg={12}>
                        <ColorButton
                          onClick={() => {
                            info.isLoader = false;
                            info.company_name =
                              permission.companyLocation.company;
                            info.location = permission.companyLocation.location;
                            info.fyear_name = "";
                            info.month = "";
                            info.year = "";

                            setInfo({ ...info });
                            setinfoTrue({
                              company_name: false,
                              location: false,
                              fyear_name: false,
                              month: false,
                              year: false,
                            });
                            getCompanyData();
                            if (permission.companyLocation.company.company_id) {
                              getLocations(info.company_name, "yes");
                            }
                            setDialogTitle("Add Financial Year");

                            setOpen(true);
                          }}
                        >
                          Add Financial Year
                        </ColorButton>

                        <CommonTable
                          url={`${Url}/datatable/financial_year_list.php`}
                          column={columns}
                          sort="0"
                          refresh={refresh}
                          search={searchBy}
                          param={param}
                          searchWord={searchKeyword}
                          checkStatus={checkStatus}
                          setResData={setResData}
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Paper>
              </Grid>
              <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                className="modalWidth100"
                fullWidth={fullWidth}
                maxWidth={maxWidth}
              >
                <BootstrapDialogTitle
                  id="customized-dialog-title"
                  className="dialogTitle"
                  onClose={handleClose}
                >
                  {dialogTitle}
                </BootstrapDialogTitle>
                <DialogContent dividers>{manageContent()}</DialogContent>
                <DialogActions>{manageDialogActionButton()}</DialogActions>
              </BootstrapDialog>
            </Grid>
          </div>
        </div>
      </div>
    </div>
  );
};

export default YearMaster;
