import React, { useState, useRef, useEffect } from "react";
import {
  Route,
  Routes,
  Navigate,
  useLocation,
  useNavigate,
} from "react-router-dom";
import Login from "./login/Login";
import Sidebar from "./sidebar/Sidebar.jsx";
import Header from "./sidebar/Header.jsx";
import "./common/customImageModal.css";
import MenuSetting from "./setting/MenuSetting";
import MenuItems from "./setting/MenuItems";
import CompanyList from "./company/CompanyList";
// import Employees from "./employees/Employees";
import RoleManagement from "./role_management/RoleManagement";
import Employees from "./Staff/Employees";

// import GenerateIncome from './generate_income/GenerateIncome';

import Setting from "./setting/Setting";
import Notification from "./notification/Notification";
import ActivityLog from "./activity_log/ActivityLog";
// import FAQEditor from "./faq_editor/FAQEditor";

import CreateRole from "./role_management/CreateRole";
import store from "./store";
import { IsApprove, Url } from "./global";
import axios from "axios";
import Dashboard from "./makingFirstPage/Dashboard";
import CreateCompany from "./company/CreateCompany";
import { useSelector } from "react-redux";
import LocationCompany from "./company/LocationCompany";
import YearMaster from "./company/YearMaster";
import MainPart from "./Group/MainPart";
import GroupDetails from "./Group/GroupDetails";
import GroupMaster from "./Group/GroupMaster";

import GroupLedger from "./Group/GroupLedger";
import Currency from "./currency/Currency";
import Ledger_master from "./company/Ledger_master";
import GroupList from "./Group/GroupList";
import LedgerMasterList from "./company/LedgerMasterList";
import ReceipForm from "./company/ReceipForm";
import CashEntryList from "./cashEntry/CashEntryList";
import AddJV from "./jv/AddJV";
import JVEntryList from "./jv/JVEntryList";
import AddExpense from "./Expense/AddExpense";
import ExpenseEntryList from "./Expense/ExpenseEntryList";
import { Report } from "./Report/Report";

function useScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
}

const App = () => {
  const [changeloginStatus, setChangeloginStatus] = useState();
  const [loader, setLoader] = useState(true);
  const [companyLocation, setCompanyLocation] = useState({
    company: {
      company_id: "",
      company_name: "",
    },
    location: {
      location_id: "",
      location_name: "",
    },
  });
  store.subscribe(() => console.log("asd", store.getState()));
  const navigate = useNavigate();
  store.subscribe(() => setChangeloginStatus(store.getState()));
  console.log("changeLogin", changeloginStatus);
  useScrollToTop();
  const ref = useRef();
  const [login, setLogin] = useState(localStorage.getItem("login"));
  const [sidebar, setSidebar] = useState(false);
  const [firstCall, setFirstCall] = useState(true);
  const [permission, setPermission] = useState({});
  useEffect(async () => {
    const param = new FormData();

    if (IsApprove !== "") {
      param.append("is_app", IsApprove.is_app);
      param.append("AADMIN_LOGIN_ID", IsApprove.AADMIN_LOGIN_ID);
      param.append("role_id", IsApprove.AADMIN_LOGIN_ROLE_ID);
    }
    await axios
      .post(`${Url}/ajaxfiles/login_session_check.php`, param)
      .then((res) => {
        if (res.data.status !== "ok") {
          localStorage.setItem("login", true);
          setLogin("true");
        } else {
          localStorage.setItem("login", false);
          setLogin("false");

          permissionfuc();
        }
      });
  }, []);
  const permissionfuc = async (prop) => {
    const param = new FormData();
    if (IsApprove !== "") {
      param.append("is_app", IsApprove.is_app);
      param.append("AADMIN_LOGIN_ID", IsApprove.AADMIN_LOGIN_ID);
      param.append("role_id", IsApprove.AADMIN_LOGIN_ROLE_ID);
    }
    param.append("action", "get_admin_permissions");
    axios
      .post(Url + "/ajaxfiles/update_user_profile.php", param)
      .then((res) => {
        if (res.data.message == "Session has been expired") {
          localStorage.setItem("login", true);
          setLogin("true");

          setLoader(false);
          return;
        }
        if (res.data.status == "ok") {
          localStorage.setItem("login", false);

          setCompanyLocation({
            company: {
              company_id: res.data.company_id,
              company_name: res.data.company_name,
            },
            location: {
              location_id: res.data.location_id,
              location_name: res.data.location_name,
            },
          });

          // setFirstCall(false);
          setPermission(res.data.admin_button_permissions);
          setLoader(false);
          setLogin(false);
          if (prop) {
            navigate(prop);
          }
        }
      });
  };

  if (login == "true") {
    return (
      <div class="loginbg">
        <Login setLogin={setLogin} permissionfuc={permissionfuc} />
      </div>
    );
  } else {
    return (
      <div>
        {loader == true ? (
          <div className="loader">
            <div className="clock">
              <div className="pointers"></div>
            </div>
          </div>
        ) : (
          <div
            className={
              sidebar
                ? "app-wrapper app-sidebar-mobile-open app-sidebar-fixed app-header-fixed"
                : "app-wrapper app-sidebar-fixed app-header-fixed"
            }
          >
            <Sidebar
              cside={sidebar}
              setSidebar={setSidebar}
              setLogin={setLogin}
            />
            <div className="app-main">
              <Header setSidebar={setSidebar} setLogin={setLogin} />
              <div className="app-content">
                <Routes>
                  <Route
                    exact
                    path="/role_management"
                    element={<RoleManagement permission={permission} />}
                  />
                  <Route
                    path="*"
                    element={
                      <Navigate
                        to="dashboard"
                        replace
                        setLogin={setLogin}
                        permission={permission}
                      />
                    }
                  />
                  /
                  <Route
                    exact
                    path="/change_menu_order"
                    element={<MenuSetting />}
                  />
                  <Route
                    exact
                    path="/yearMaster"
                    element={
                      <YearMaster
                        permission={permission}
                        companyLocation={companyLocation}
                      />
                    }
                  />
                  <Route
                    exact
                    path="/companyLocation"
                    element={
                      <LocationCompany
                        permission={permission}
                        companyLocation={companyLocation}
                      />
                    }
                    groupDetails
                  />
                  <Route
                    exact
                    path="/groupMaster"
                    element={<GroupList permission={permission} />}
                    groupDetails
                  />
                  <Route
                    exact
                    path="/groupLedger"
                    element={<GroupLedger permission={permission} />}
                  />
                  <Route
                    exact
                    path="/groupDetails"
                    element={<GroupDetails permission={permission} />}
                  />
                  <Route
                    exact
                    path="/mainPart"
                    element={<MainPart permission={permission} />}
                  />
                  <Route
                    exact
                    path="/currency"
                    element={<Currency permission={permission} />}
                  />
                  <Route
                    exact
                    path="/createCompany"
                    element={<CreateCompany permission={permission} />}
                  />
                  <Route
                    exact
                    path="/reciipt_form"
                    element={
                      <ReceipForm
                        permission={permission}
                        companyLocation={companyLocation}
                      />
                    }
                  />
                  <Route
                    exact
                    path="/edit_reciipt_form/:id"
                    element={
                      <ReceipForm
                        permission={permission}
                        companyLocation={companyLocation}
                      />
                    }
                  />
                  <Route
                    exact
                    path="/addJV"
                    element={
                      <AddJV
                        permission={permission}
                        companyLocation={companyLocation}
                      />
                    }
                  />
                  <Route
                    exact
                    path="/editJV/:id"
                    element={
                      <AddJV
                        permission={permission}
                        companyLocation={companyLocation}
                      />
                    }
                  />
                  <Route
                    exact
                    path="/addExpense"
                    element={
                      <AddExpense
                        permission={permission}
                        companyLocation={companyLocation}
                      />
                    }
                  />
                  <Route
                    exact
                    path="/expenseEntryList"
                    element={<ExpenseEntryList />}
                    permission={permission}
                  />
                  <Route
                    exact
                    path="/editaddExpense/:id"
                    element={
                      <AddExpense
                        permission={permission}
                        companyLocation={companyLocation}
                      />
                    }
                  />
                  <Route
                    exact
                    path="/cashEntryList"
                    element={<CashEntryList permission={permission} />}
                  />
                  <Route
                    exact
                    path="/jvEntryList"
                    element={<JVEntryList permission={permission} />}
                  />
                  <Route
                    exact
                    path="/Ledger_master"
                    element={<Ledger_master permission={permission} />}
                  />
                  <Route
                    exact
                    path="/editLedgerMaster/:id"
                    element={<Ledger_master permission={permission} />}
                  />
                  <Route
                    exact
                    path="/editCompany/:id"
                    element={<CreateCompany permission={permission} />}
                  />
                  <Route
                    exact
                    path="/editGroupFormet/:id"
                    element={<GroupMaster permission={permission} />}
                  />
                  <Route
                    exact
                    path="/addGroupFormet"
                    element={<GroupMaster permission={permission} />}
                  />
                  <Route
                    exact
                    path="/menu_item"
                    element={<MenuItems permission={permission} />}
                  />
                  <Route
                    exact
                    path="/setting"
                    element={<Setting permission={permission} />}
                  />
                  <Route
                    exact
                    path="/dashboard"
                    element={<Dashboard permission={permission} />}
                  />
                  <Route
                    exact
                    path="/employees"
                    element={
                      <Employees
                        permission={permission}
                        companyLocation={companyLocation}
                      />
                    }
                  />
                  <Route
                    exact
                    path="/companyDetails"
                    element={<CompanyList permission={permission} />}
                  />
                  <Route
                    exact
                    path="/report"
                    element={
                      <Report
                        permission={permission}
                        companyLocation={companyLocation}
                      />
                    }
                  />
                  <Route
                    exact
                    path="/ledgerMasterList"
                    element={<LedgerMasterList permission={permission} />}
                  />
                  <Route
                    exact
                    path="/notification"
                    element={<Notification permission={permission} />}
                  />
                  <Route
                    exact
                    path="/activity_log"
                    element={<ActivityLog permission={permission} />}
                  />
                  {/* <Route
                  exact
                  path="/faq_editor"
                  element={<FAQEditor permission={permission} />}
                /> */}
                  <Route
                    exact
                    path="/createRole/:id"
                    element={<CreateRole />}
                  />
                  <Route exact path="/createRole" element={<CreateRole />} />
                </Routes>

                {/* <Footer /> */}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
};

export { App };
