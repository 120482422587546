import {
  Autocomplete,
  CardContent,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { IsApprove, Url } from "../global";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ColorButton } from "../common/CustomElement";
import "./report.css";
import CommonTable from "../common/CommonTable";
import NewDate from "../common/NewDate";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
export const Report = (permission) => {
  const navigate = useNavigate();
  const [countryData, setCountryData] = useState({
    location: [],
    company_list: [],
    getledger: [],
  });
  const [afterSubmit, setAfterSubmit] = useState({
    submit: false,
    name: "",
  });
  const [ledgerData, setLedgerData] = useState({
    data: [],
    grandData: {
      dr_amount: 0,
      cr_amount: 0,
      balance: 0,
      base_dr_amount: 0,
      base_cr_amount: 0,
      base_balance: 0,
    },
  });
  const [summaryData, setSummaryData] = useState({
    mainData: [],
    footerData: [],
    grandTotal: {},
  });
  const [trialDU, setTrialDU] = useState({
    parent: {},
    child: {},
    parentData: {},
    childData: {},
    parentLoder: [],
    childLoder: [],
    company_id: "",
    location_id: "",
    start_date: "",
  });
  const [trialData, setTrialData] = useState({
    data: [],
    grandData: "",
  });
  const [searchKeyword, setSearchKeyword] = useState("");
  const [loder, setLoder] = useState({
    view: false,
    download: false,
  });
  const [infoTrue, setinfoTrue] = useState({
    company_name: false,
    location: false,
    type: false,
    dateRadio: false,
    date: false,
    ledger: false,
  });
  const [searchBy, setSearchBy] = useState([
    {
      label: "USER NAME",
      value: false,
      name: "full_name",
    },
    {
      label: "IP ADDRESS",
      value: false,
      name: "ip_address",
    },
  ]);

  const [info, setInfo] = useState({
    company_name: "",
    location: "",
    isLodear: false,
    type: "dayBookReport",
    dateRadio: "allDate",
    date: "",
    ledger: "",
  });
  const [param, setParam] = useState({});

  const activityColumn = [
    {
      name: "sr no",
      selector: (row) => row.sr_no,
      sortable: false,
      reorder: true,
      grow: 0.1,
    },
    {
      name: "date",
      selector: (row) => {
        return (
          <span title={row.date}>
            <NewDate newDate={row.date} />
          </span>
        );
      },
      sortable: false,
      reorder: true,
      grow: 1,
    },
    {
      name: "book name",
      selector: (row) => row.book_name,
      sortable: false,
      reorder: true,
      grow: 1,
    },
    {
      name: "location",
      selector: (row) => row.location_name,
      sortable: false,
      reorder: true,
      grow: 1,
    },
    {
      name: "document Number",
      selector: (row) => row.doc_no,
      sortable: false,
      reorder: true,
      grow: 1,
    },
    {
      name: "document ref",
      selector: (row) => row.doc_ref,
      sortable: false,
      reorder: true,
      grow: 1,
    },
    {
      name: "ledger name",
      selector: (row) => row.ledger_name,
      sortable: false,
      reorder: true,
      grow: 1,
    },
    {
      name: "ledger name 2",
      selector: (row) => row.ledger_name2,
      sortable: false,
      reorder: true,
      grow: 1,
    },
    {
      name: "base currency",
      selector: (row) => row.base_currency,
      sortable: false,
      reorder: true,
      grow: 1,
    },
    {
      name: "gl currency",
      selector: (row) => row.gl_currency,
      sortable: false,
      reorder: true,
      grow: 1,
    },
    {
      name: "base amount",
      selector: (row) => row.base_amount,
      sortable: false,
      reorder: true,
      grow: 1,
    },
    {
      name: "gl amount",
      selector: (row) => row.gl_amount,
      sortable: false,
      reorder: true,
      grow: 1,
    },
    {
      name: "remarks",
      selector: (row) => row.remarks,
      sortable: false,
      reorder: true,
      grow: 1,
    },
  ];
  const input1 = (event) => {
    var { name, value } = event.target;
    setInfo((prevalue) => {
      return {
        ...prevalue,
        [name]: value,
      };
    });
  };
  const trueFalse = (event) => {
    var { name, value } = event.target;
    setinfoTrue((prevalue) => {
      return {
        ...prevalue,
        [name]: true,
      };
    });
  };
  const getCompanyData = () => {
    const param = new FormData();
    if (IsApprove !== "") {
      param.append("is_app", IsApprove.is_app);
      param.append("AADMIN_LOGIN_ID", IsApprove.AADMIN_LOGIN_ID);
      param.append("role_id", IsApprove.AADMIN_LOGIN_ROLE_ID);
    }

    axios.post(Url + "/ajaxfiles/get_company_list.php", param).then((res) => {
      if (res.data.message == "Session has been expired") {
        localStorage.setItem("login", true);
        navigate("/login");
      }
      if (res.data.status == "error") {
        // toast.error(res.data.message);
      } else {
        countryData.company_list = res.data.data;
        setCountryData({ ...countryData });
      }
    });
  };

  const getLocations = (prop, checkL) => {
    if (prop == "" || prop == null) {
      info.location = "";
      setInfo({ ...info });
      countryData.location = [];
      setCountryData({ ...countryData });
    } else {
      const param = new FormData();
      if (IsApprove !== "") {
        param.append("is_app", IsApprove.is_app);
        param.append("AADMIN_LOGIN_ID", IsApprove.AADMIN_LOGIN_ID);
        param.append("role_id", IsApprove.AADMIN_LOGIN_ROLE_ID);
      }
      param.append("company_id", prop.company_id);

      axios
        .post(Url + "/ajaxfiles/get_location_list.php", param)
        .then((res) => {
          if (res.data.message == "Session has been expired") {
            localStorage.setItem("login", true);
            navigate("/login");
          }
          if (res.data.status == "error") {
            toast.error(res.data.message);
          } else {
            if (checkL == "yes") {
              info.location = permission.companyLocation.location;
              setInfo({ ...info });
            } else {
              info.location = "";
              setInfo({ ...info });
            }

            countryData.location = res.data.data;

            setCountryData({ ...countryData });
            // info.company_list = res.data.data;
            // setInfo({ ...info });
          }
        });
    }
  };
  const getLedger = (prop) => {
    const param = new FormData();
    if (IsApprove !== "") {
      param.append("is_app", IsApprove.is_app);
      param.append("AADMIN_LOGIN_ID", IsApprove.AADMIN_LOGIN_ID);
      param.append("role_id", IsApprove.AADMIN_LOGIN_ROLE_ID);
    }
    param.append("action", "get_all_ledger");

    param.append("ledger_name", prop);

    axios.post(Url + "/ajaxfiles/common_api.php", param).then((res) => {
      if (res.data.message == "Session has been expired") {
        localStorage.setItem("login", true);
        navigate("/login");
      }
      if (res.data.status == "error") {
      } else {
        countryData.getledger = res.data.data;
        setCountryData({ ...countryData });
      }
    });
  };
  const getTableLedger = (prop) => {
    const param = new FormData();
    if (IsApprove !== "") {
      param.append("is_app", IsApprove.is_app);
      param.append("AADMIN_LOGIN_ID", IsApprove.AADMIN_LOGIN_ID);
      param.append("role_id", IsApprove.AADMIN_LOGIN_ROLE_ID);
    }
    param.append("company_id", prop.company_name?.company_id);
    param.append("location_id", prop.location?.location_id);
    param.append("ledger_id", prop.ledger?.ledger_id);

    if (info.dateRadio == "allDate") {
      param.start_date = "";
      param.append("start_date", "");
    } else {
      param.append("start_date", info.date);
    }
    loder.view = true;
    setLoder({ ...loder });
    axios.post(Url + "/ajaxfiles/ledgerBookReport.php", param).then((res) => {
      if (res.data.message == "Session has been expired") {
        localStorage.setItem("login", true);
        navigate("/login");
      }
      if (res.data.status == "error") {
        toast.error(res.data.message);
        loder.view = false;
        setLoder({ ...loder });
      } else {
        // info.company_list = res.data.data;
        // setInfo({ ...info });
        loder.view = false;
        setLoder({ ...loder });

        if (res.data.data) {
          // setLedgerData(res.data);
          var total = {
            dr_amount: 0,
            cr_amount: 0,
            balance: 0,
            base_dr_amount: 0,
            base_cr_amount: 0,
            base_balance: 0,
          };
          res.data.data.map((item, index) => {
            total.dr_amount = total.dr_amount + parseFloat(item.dr_amount);
            total.cr_amount = total.cr_amount + parseFloat(item.cr_amount);
            total.balance = total.balance + parseFloat(item.balance);
            total.base_dr_amount =
              total.base_dr_amount + parseFloat(item.base_dr_amount);
            total.base_cr_amount =
              total.base_cr_amount + parseFloat(item.base_cr_amount);
            total.base_balance =
              total.base_balance + parseFloat(item.base_balance);
          });
          ledgerData.data = res.data.data;
          ledgerData.grandData = { ...total };
          setLedgerData({ ...ledgerData });
        } else {
          setLedgerData({
            data: [],
            grandData: "",
          });
        }
        afterSubmit.submit = true;

        afterSubmit.name = "ledgerReport";
        setAfterSubmit({ ...afterSubmit });
      }
    });
  };
  const getSummaryTableLedger = (prop) => {
    const param = new FormData();
    if (IsApprove !== "") {
      param.append("is_app", IsApprove.is_app);
      param.append("AADMIN_LOGIN_ID", IsApprove.AADMIN_LOGIN_ID);
      param.append("role_id", IsApprove.AADMIN_LOGIN_ROLE_ID);
    }
    param.append("company_id", prop.company_name?.company_id);
    param.append("location_id", prop.location?.location_id);
    param.append("ledger_id", prop.ledger?.ledger_id);

    if (info.dateRadio == "allDate") {
      param.start_date = "";
      param.append("start_date", "");
    } else {
      param.append("start_date", info.date);
    }
    loder.view = true;
    setLoder({ ...loder });
    axios.post(Url + "/ajaxfiles/summaryBookReport.php", param).then((res) => {
      if (res.data.message == "Session has been expired") {
        localStorage.setItem("login", true);
        navigate("/login");
      }
      if (res.data.status == "error") {
        toast.error(res.data.message);
        loder.view = false;
        setLoder({ ...loder });
      } else {
        // info.company_list = res.data.data;
        // setInfo({ ...info });
        if (res.data.data) {
          var key = [];
          var Svalue = [];
          var footerValue = [];
          var total = {
            dr_amount: 0,
            cr_amount: 0,
            balance: 0,
            base_dr_amount: 0,
            base_cr_amount: 0,
            base_balance: 0,
          };
          loder.view = false;
          setLoder({ ...loder });
          res.data.data.map((item, index) => {
            console.log("indercxc", index);
            var newkey = true;
            total.dr_amount = total.dr_amount + parseFloat(item.dr_amount);
            total.cr_amount = total.cr_amount + parseFloat(item.cr_amount);
            total.balance = total.balance + parseFloat(item.balance);
            total.base_dr_amount =
              total.base_dr_amount + parseFloat(item.base_dr_amount);
            total.base_cr_amount =
              total.base_cr_amount + parseFloat(item.base_cr_amount);
            total.base_balance =
              total.base_balance + parseFloat(item.base_balance);
            for (var i = 0; i < key.length; i++) {
              if (key[i] == item.entered_currency) {
                console.log("sdsad", i);
                newkey = false;
                Svalue[i].push(item);
                footerValue[i] = {
                  dr_amount:
                    footerValue[i].dr_amount + parseFloat(item.dr_amount),
                  cr_amount:
                    footerValue[i].cr_amount + parseFloat(item.cr_amount),
                  balance: footerValue[i].balance + parseFloat(item.balance),
                  base_dr_amount:
                    footerValue[i].base_dr_amount +
                    parseFloat(item.base_dr_amount),
                  base_cr_amount:
                    footerValue[i].base_cr_amount +
                    parseFloat(item.base_cr_amount),
                  base_balance:
                    footerValue[i].base_balance + parseFloat(item.base_balance),
                };
                // break;
              }
            }
            if (newkey) {
              key = [...key, item.entered_currency];
              Svalue = [...Svalue, [item]];
              footerValue = [
                ...footerValue,
                {
                  dr_amount: parseFloat(item.dr_amount),
                  cr_amount: parseFloat(item.cr_amount),
                  balance: parseFloat(item.balance),
                  base_dr_amount: parseFloat(item.base_dr_amount),
                  base_cr_amount: parseFloat(item.base_cr_amount),
                  base_balance: parseFloat(item.base_balance),
                },
              ];
            }
          });
          console.log(key, Svalue, footerValue);
          summaryData.footerData = [...footerValue];
          summaryData.mainData = [...Svalue];
          summaryData.grandTotal = { ...total };

          setSummaryData({ ...summaryData });
          afterSubmit.submit = true;

          afterSubmit.name = "summaryReport";
          setAfterSubmit({ ...afterSubmit });
        } else {
          loder.view = false;
          setLoder({ ...loder });
          setSummaryData({
            mainData: [],
            footerData: [],
            grandTotal: {},
          });
          afterSubmit.submit = true;

          afterSubmit.name = "summaryReport";
          setAfterSubmit({ ...afterSubmit });
        }
      }
    });
  };
  const getPerentChild = (g_id, index) => {
    if (trialDU.parentData[index] == undefined) {
      const param = new FormData();
      if (IsApprove !== "") {
        param.append("is_app", IsApprove.is_app);
        param.append("AADMIN_LOGIN_ID", IsApprove.AADMIN_LOGIN_ID);
        param.append("role_id", IsApprove.AADMIN_LOGIN_ROLE_ID);
      }
      param.append("company_id", trialDU.company_id);
      param.append("location_id", trialDU.location_id);
      param.append("group_id", g_id);

      param.append("start_date", trialDU.start_date);

      axios.post(Url + "/ajaxfiles/trial_balance_1.php", param).then((res) => {
        if (res.data.message == "Session has been expired") {
          localStorage.setItem("login", true);
          navigate("/login");
        }
        if (res.data.status == "error") {
          toast.error(res.data.message);
        } else {
          trialDU.parentData[index] = res.data.data;
          setTrialDU({ ...trialDU });

          // info.company_list = res.data.data;
          // setInfo({ ...info });
        }
      });
      return (
        <>
          <div className="reportLoderChild">
            <svg class="spinner" viewBox="0 0 50 50">
              <circle
                class="path"
                cx="25"
                cy="25"
                r="20"
                fill="none"
                stroke-width="5"
              ></circle>
            </svg>
          </div>
        </>
      );
    } else {
      return (
        <>
          <div
            style={{
              padding: "1rem",
              overflow: "auto",
              border: "1px solid #d9d9d9",
            }}
          >
            <table className="report-table">
              <tr>
                <th></th>
                <th>Main Part</th>
                <th>Group Name</th>
                <th>Group Name O</th>
                <th>Child Group</th>
                <th>Amount</th>
                <th>Base Amount</th>
              </tr>
              <tbody>
                {trialDU.parentData[index].map((item, index1) => {
                  return (
                    <>
                      <tr>
                        <td style={{ minWidth: "30px" }}>
                          {/* {trialDU.parent[index1] == true ? (
                                      <KeyboardArrowUpIcon
                                        onClick={() => {
                                          trialDU.parent[index1] = false;
                                          setTrialDU({
                                            ...trialDU,
                                          });
                                        }}
                                      />
                                    ) : (
                                      <KeyboardArrowDownIcon
                                        onClick={() => {
                                          trialDU.parent[index1] = true;
                                          setTrialDU({
                                            ...trialDU,
                                          });
                                        }}
                                      />
                                    )} */}
                        </td>
                        <td>{item.part_name}</td>
                        <td>{item.group_name}</td>
                        <td>{item.group_o_name}</td>
                        <td>{item.child_group_name}</td>

                        <td>{parseFloat(item.total_amount).toFixed(2)}</td>
                        <td>{parseFloat(item.total_base_amount).toFixed(2)}</td>
                      </tr>
                      {/* {trialDU.parent[index] == true
                                  ? getPerentChild(item.group_id, index)
                                  : ""} */}
                    </>
                  );
                })}
              </tbody>
              {trialTotal(trialDU.parentData[index])}
            </table>
          </div>
        </>
      );
    }
  };
  const getTableTrial = (prop) => {
    const param = new FormData();
    if (IsApprove !== "") {
      param.append("is_app", IsApprove.is_app);
      param.append("AADMIN_LOGIN_ID", IsApprove.AADMIN_LOGIN_ID);
      param.append("role_id", IsApprove.AADMIN_LOGIN_ROLE_ID);
    }
    param.append("company_id", prop.company_name?.company_id);
    param.append("location_id", prop.location?.location_id);

    if (info.dateRadio == "allDate") {
      param.start_date = "";
      param.append("start_date", "");
    } else {
      param.append("start_date", info.date);
    }
    loder.view = true;
    setLoder({ ...loder });
    axios.post(Url + "/ajaxfiles/trial_balance.php", param).then((res) => {
      if (res.data.message == "Session has been expired") {
        localStorage.setItem("login", true);
        navigate("/login");
      }
      if (res.data.status == "error") {
        toast.error(res.data.message);
        loder.view = false;
        setLoder({ ...loder });
      } else {
        // info.company_list = res.data.data;
        // setInfo({ ...info });
        loder.view = false;
        setLoder({ ...loder });
        trialDU.start_date = info.date == "" ? "" : info.date;

        trialDU.location_id = prop.location?.location_id;
        trialDU.company_id = prop.company_name?.company_id;
        setTrialDU({ ...trialDU });
        if (res.data.data) {
          setTrialData(res.data);
        } else {
          setTrialData({
            data: [],
            grandData: "",
          });
        }
        afterSubmit.submit = true;

        afterSubmit.name = "trialBalance";
        setAfterSubmit({ ...afterSubmit });
      }
    });
  };
  useEffect(() => {
    console.log("csca", permission.companyLocation.company);
    if (permission.companyLocation.company.company_id) {
      info.company_name = permission.companyLocation.company;
      getLocations(permission.companyLocation.company, "yes");
      setInfo({ ...info });
    }
    getCompanyData();
  }, []);
  const viewReport = (e) => {
    if (info.type == "") {
      toast.error("Report Type is required");
    } else if (info.company_name == "" || info.company_name == null) {
      toast.error("Company is required");
    } else if (info.location == "" || info.location == null) {
      toast.error("Location is required");
    } else if (
      info.dateRadio == "" &&
      (info.type == "ledgerReport" || info.type == "dayBookReport")
    ) {
      toast.error("Date Filter is required");
    } else if (
      info.date == "" &&
      info.dateRadio == "dateWise" &&
      (info.type == "ledgerReport" || info.type == "dayBookReport")
    ) {
      toast.error("Date is required");
    } else if (
      (info.ledger == "" || info.ledger == null) &&
      info.type == "ledgerReport"
    ) {
      toast.error("Ledger is required");
    } else {
      if (e == "view") {
        if (info.type == "dayBookReport") {
          param.company_id = info.company_name?.company_id;
          param.location_id = info.location?.location_id;

          if (info.dateRadio == "allDate") {
            param.start_date = "";
          } else {
            param.start_date = info.date;
          }
          setParam({ ...param });
          afterSubmit.submit = true;
          afterSubmit.name = "dayBookReport";
          setAfterSubmit({ ...afterSubmit });
        } else if (info.type == "ledgerReport") {
          getTableLedger(info);
        } else if (info.type == "trialBalance") {
          getTableTrial(info);
          afterSubmit.submit = true;
          afterSubmit.name = "trialBalance";
          setAfterSubmit({ ...afterSubmit });
        } else if (info.type == "summaryReport") {
          getSummaryTableLedger(info);
        }
      } else if (e == "downLoad") {
        if (info.type == "dayBookReport") {
          var link =
            Url +
            "/report/dayBookReport.php" +
            `?company_id=${info.company_name?.company_id}&location_id=${
              info.location?.location_id
            }&start_date=${info.dateRadio == "allDate" ? "" : info.date}`;
          window.open(link);
        } else if (info.type == "ledgerReport") {
          var link =
            Url +
            "/report/ledgerBookReport.php" +
            `?company_id=${info.company_name?.company_id}&ledger_id=${
              info.ledger?.ledger_id
            } &location_id=${info.location?.location_id}&start_date=${
              info.dateRadio == "allDate" ? "" : info.date
            }`;
          window.open(link);
        } else if (info.type == "trialBalance") {
          var link =
            Url +
            "/report/ledgerBookReport.php" +
            `?company_id=${info.company_name?.company_id}&ledger_id=${
              info.ledger?.ledger_id
            } &location_id=${info.location?.location_id}&start_date=${
              info.dateRadio == "allDate" ? "" : info.date
            }`;
          window.open(link);
        }
      }
      // setinfoTrue({
      //   company_name: false,
      //   location: false,
      //   type: false,
      //   dateRadio: false,
      //   date: false,
      //   ledger: false,
      // });
      // setInfo({
      //   company_name: "",
      //   location: "",
      //   isLodear: false,
      //   type: "dayBookReport",
      //   dateRadio: "allDate",
      //   date: "",
      //   ledger: "",
      // });
    }
  };
  const trialTotal = (trialArray) => {
    if (trialArray.length !== 0) {
      console.log(trialArray);
      var total_amount = 0;
      var total_base_amount = 0;
      trialArray.map((item, index) => {
        total_amount = total_amount + item.total_amount;
        total_base_amount = total_base_amount + item.total_base_amount;
      });
      return (
        <tfoot>
          <tr>
            <td colspan="5">
              <b>Total</b>
            </td>
            <td>
              <b>{total_amount.toFixed(2)}</b>
            </td>
            <td>
              <b>{total_base_amount.toFixed(2)}</b>
            </td>
          </tr>
        </tfoot>
      );
    } else {
      return "";
    }
  };
  return (
    <div className="app-content--inner">
      <div className="app-content--inner__wrapper mh-100-vh">
        <div style={{ opacity: 1 }}>
          <Grid container>
            <Grid item md={12}>
              <p className="main-heading">Report</p>
              <Grid container spacing={4}>
                <Grid item md={12}>
                  <Paper
                    elevation={1}
                    style={{ borderRadius: "10px" }}
                    className="w-100 "
                  >
                    <div className="card-body position-relative">
                      <Grid container spacing={1}>
                        <Grid item md={6}>
                          <Grid container spacing={2}>
                            {" "}
                            <Grid item md={12}>
                              <FormControl>
                                <FormLabel id="demo-radio-buttons-group-label">
                                  Report Type
                                </FormLabel>
                                <RadioGroup
                                  aria-labelledby="demo-radio-buttons-group-label"
                                  name="radio-buttons-group"
                                  row
                                  value={info.type}
                                  onChange={(e) => {
                                    info.type = e.target.value;
                                    setInfo({ ...info });
                                  }}
                                >
                                  <FormControlLabel
                                    value="dayBookReport"
                                    control={<Radio />}
                                    label="Day Book Report"
                                  />
                                  <FormControlLabel
                                    value="ledgerReport"
                                    control={<Radio />}
                                    label="Ledger Report"
                                  />
                                  <FormControlLabel
                                    value="trialBalance"
                                    control={<Radio />}
                                    label="Trial Balance"
                                  />
                                  <FormControlLabel
                                    value="summaryReport"
                                    control={<Radio />}
                                    label="Summary"
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Grid>
                            <Grid item md={12}>
                              {" "}
                              <Autocomplete
                                disablePortal
                                options={countryData.company_list}
                                value={info.company_name}
                                getOptionLabel={(option) =>
                                  option ? option.company_name : ""
                                }
                                onChange={(event, newValue) => {
                                  getLocations(newValue);
                                  info.company_name = newValue;
                                  setInfo({ ...info });
                                }}
                                sx={{ padding: "0px" }}
                                className="w-100"
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Company"
                                    size="small"
                                    onBlur={trueFalse}
                                    className="w-100"
                                    name="company_name"
                                    helperText={
                                      (info.company_name == null ||
                                        info.company_name == "") &&
                                      infoTrue.company_name
                                        ? "Company is required"
                                        : ""
                                    }
                                    error={
                                      (info.company_name == null ||
                                        info.company_name == "") &&
                                      infoTrue.company_name
                                        ? true
                                        : false
                                    }
                                    sx={{ padding: "0px" }}
                                    variant="outlined"
                                  />
                                )}
                              />
                            </Grid>
                            <Grid item md={12}>
                              <Autocomplete
                                disablePortal
                                options={countryData.location}
                                value={info.location}
                                getOptionLabel={(option) =>
                                  option ? option.location_name : ""
                                }
                                onChange={(event, newValue) => {
                                  info.location = newValue;
                                  setInfo({ ...info });
                                }}
                                sx={{ padding: "0px" }}
                                className="w-100"
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Location"
                                    size="small"
                                    onBlur={trueFalse}
                                    name="location"
                                    helperText={
                                      (info.location == null ||
                                        info.location == "") &&
                                      infoTrue.location
                                        ? "Location is required"
                                        : ""
                                    }
                                    error={
                                      (info.location == null ||
                                        info.location == "") &&
                                      infoTrue.location
                                        ? true
                                        : false
                                    }
                                    sx={{ padding: "0px" }}
                                    variant="outlined"
                                  />
                                )}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item md={6}>
                          <Grid container spacing={2}>
                            <Grid item md={12}>
                              <FormControl>
                                <FormLabel id="demo-radio-buttons-group-label">
                                  Date Filter
                                </FormLabel>
                                <RadioGroup
                                  aria-labelledby="demo-radio-buttons-group-label"
                                  name="radio-buttons-group"
                                  row
                                  value={info.dateRadio}
                                  onChange={(e) => {
                                    info.dateRadio = e.target.value;
                                    setInfo({ ...info });
                                  }}
                                >
                                  <FormControlLabel
                                    value="allDate"
                                    control={<Radio />}
                                    label="All Date"
                                  />
                                  <FormControlLabel
                                    value="dateWise"
                                    control={<Radio />}
                                    label="Date Wise"
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Grid>
                            {info.dateRadio == "dateWise" ? (
                              <>
                                <Grid item md={12}>
                                  <TextField
                                    id="standard-basic"
                                    label="Date"
                                    name="date"
                                    value={info.date}
                                    type="date"
                                    className="w-100"
                                    variant="outlined"
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    onChange={input1}
                                    onBlur={trueFalse}
                                    error={
                                      info.date == "" && infoTrue.date
                                        ? true
                                        : false
                                    }
                                    helperText={
                                      info.date == "" && infoTrue.date
                                        ? "Entry Date is required"
                                        : ""
                                    }
                                  />
                                </Grid>
                              </>
                            ) : (
                              ""
                            )}
                            {info.type == "ledgerReport" ||
                            info.type == "summaryReport" ? (
                              <>
                                <Grid item md={12}>
                                  <Autocomplete
                                    disablePortal
                                    options={countryData.getledger}
                                    value={info.ledger}
                                    getOptionLabel={(option) =>
                                      option ? option.ledger_name : ""
                                    }
                                    onChange={(event, newValue) => {
                                      if (newValue == null) {
                                        info.ledger = "";
                                        countryData.getledger = [];
                                        setCountryData({ ...countryData });
                                        setInfo({ ...info });
                                      } else {
                                        info.ledger = newValue;

                                        setInfo({ ...info });
                                      }
                                    }}
                                    sx={{ padding: "0px" }}
                                    className="w-100"
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        label="Ledger"
                                        size="small"
                                        onBlur={trueFalse}
                                        onChange={(e) => {
                                          if (e.target.value.length > 2) {
                                            getLedger(e.target.value);
                                          }
                                        }}
                                        helperText={
                                          (info.ledger == null ||
                                            info.ledger == "") &&
                                          infoTrue.ledger
                                            ? "Ledger is required"
                                            : ""
                                        }
                                        error={
                                          (info.ledger == null ||
                                            info.ledger == "") &&
                                          infoTrue.ledger
                                            ? true
                                            : false
                                        }
                                        name="ledger"
                                        sx={{ padding: "0px" }}
                                        variant="outlined"
                                      />
                                    )}
                                  />
                                </Grid>
                              </>
                            ) : (
                              ""
                            )}
                          </Grid>
                        </Grid>

                        <Grid item md={12}>
                          <div className="report-botton">
                            {loder.view == true ? (
                              <ColorButton
                                tabindex="0"
                                size="large"
                                sx={{
                                  padding: "16.5px 50px",
                                  marginLeft: "10px",
                                }}
                                disabled
                              >
                                <svg class="spinner" viewBox="0 0 50 50">
                                  <circle
                                    class="path"
                                    cx="25"
                                    cy="25"
                                    r="20"
                                    fill="none"
                                    stroke-width="5"
                                  ></circle>
                                </svg>
                              </ColorButton>
                            ) : (
                              <ColorButton onClick={() => viewReport("view")}>
                                View Report
                              </ColorButton>
                            )}

                            <ColorButton onClick={() => viewReport("downLoad")}>
                              Download Report
                            </ColorButton>
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                  </Paper>
                  {afterSubmit.submit == true &&
                  afterSubmit.name == "dayBookReport" &&
                  info.type == "dayBookReport" ? (
                    <Paper
                      elevation={1}
                      style={{ borderRadius: "10px", marginTop: "10px" }}
                      className="w-100 "
                    >
                      <CardContent className="py-3">
                        <Grid container spacing={2}>
                          <Grid item sm={12} md={12} lg={12}>
                            <CommonTable
                              url={`${Url}/datatable/dayBookReport.php`}
                              column={activityColumn}
                              sort="2"
                              search={searchBy}
                              searchWord={searchKeyword}
                              param={param}
                            />
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Paper>
                  ) : (
                    ""
                  )}

                  {afterSubmit.submit == true &&
                  afterSubmit.name == "summaryReport" &&
                  info.type == "summaryReport" ? (
                    <>
                      <Paper
                        elevation={1}
                        style={{ borderRadius: "10px", marginTop: "10px" }}
                        className="w-100 "
                      >
                        <div style={{ padding: "1rem", overflow: "auto" }}>
                          {summaryData.mainData.length == 0 ? (
                            <div className="report-noData-table">
                              <span>There are no records to display</span>{" "}
                            </div>
                          ) : (
                            <table className="report-table">
                              <tr>
                                <th
                                  colspan="3"
                                  className="border-right-teble"
                                ></th>

                                <th
                                  colspan="4"
                                  className="text-center border-right-teble"
                                >
                                  Entered Currency
                                </th>
                                <th colspan="4" className="text-center">
                                  Base Currency
                                </th>
                              </tr>
                              <tr>
                                <th>Date</th>
                                <th>perticular</th>
                                <th className="border-right-teble">
                                  description
                                </th>
                                <th>Currency</th>

                                <th>Debit Amount</th>
                                <th>Credit Amount</th>
                                <th className="border-right-teble">Balance</th>
                                <th>Currency</th>

                                <th> Debit Amount</th>
                                <th> Credit Amount</th>
                                <th>Base Balance</th>
                              </tr>
                              <tbody>
                                {summaryData.mainData.map((item1, index1) => {
                                  return (
                                    <>
                                      {item1.map((item, index) => {
                                        return (
                                          <tr>
                                            <td>{item.date}</td>
                                            <td style={{ minWidth: "190px" }}>
                                              {item.perticular}
                                            </td>
                                            <td className="border-right-teble">
                                              {item.description}
                                            </td>
                                            <td>{item.entered_currency}</td>
                                            <td>
                                              {parseFloat(
                                                item.dr_amount
                                              ).toFixed(2)}
                                            </td>
                                            <td>
                                              {parseFloat(
                                                item.cr_amount
                                              ).toFixed(2)}
                                            </td>
                                            <td className="border-right-teble">
                                              {parseFloat(item.balance).toFixed(
                                                2
                                              )}
                                            </td>
                                            <td>{item.base_currency}</td>
                                            <td>
                                              {parseFloat(
                                                item.base_dr_amount
                                              ).toFixed(2)}
                                            </td>
                                            <td>
                                              {parseFloat(
                                                item.base_cr_amount
                                              ).toFixed(2)}
                                            </td>
                                            <td>
                                              {parseFloat(
                                                item.base_balance
                                              ).toFixed(2)}
                                            </td>
                                          </tr>
                                        );
                                      })}
                                      {item1.length > 0 &&
                                      summaryData.footerData[index1]
                                        .dr_amount ? (
                                        <tr className="ltdBold">
                                          {/* <td></td>
                                  <td></td> */}
                                          <td
                                            colspan="3"
                                            className="border-right-teble"
                                          >
                                            {" "}
                                            Total
                                          </td>
                                          <td></td>
                                          <td>
                                            {summaryData.footerData[
                                              index1
                                            ]?.dr_amount.toFixed(2)}
                                          </td>
                                          <td>
                                            {summaryData.footerData[
                                              index1
                                            ]?.cr_amount.toFixed(2)}
                                          </td>
                                          <td className="border-right-teble">
                                            {summaryData.footerData[
                                              index1
                                            ]?.balance.toFixed(2)}
                                          </td>
                                          <td></td>
                                          <td>
                                            {summaryData.footerData[
                                              index1
                                            ]?.base_dr_amount.toFixed(2)}
                                          </td>
                                          <td>
                                            {summaryData.footerData[
                                              index1
                                            ]?.base_cr_amount.toFixed(2)}
                                          </td>
                                          <td>
                                            {summaryData.footerData[
                                              index1
                                            ]?.base_balance.toFixed(2)}
                                          </td>
                                        </tr>
                                      ) : (
                                        ""
                                      )}
                                    </>
                                  );
                                })}
                                {summaryData.mainData.length > 0 &&
                                summaryData.grandTotal.dr_amount ? (
                                  <tr
                                    className="ltdBold"
                                    style={{
                                      background: "#747474",
                                      color: "white",
                                    }}
                                  >
                                    {/* <td></td>
                                  <td></td> */}
                                    <td
                                      colspan="3"
                                      className="border-right-teble"
                                    >
                                      {" "}
                                      Grand Total
                                    </td>
                                    <td></td>
                                    <td>
                                      {summaryData.grandTotal?.dr_amount.toFixed(
                                        2
                                      )}
                                    </td>
                                    <td>
                                      {summaryData.grandTotal?.cr_amount.toFixed(
                                        2
                                      )}
                                    </td>
                                    <td className="border-right-teble">
                                      {summaryData.grandTotal?.balance.toFixed(
                                        2
                                      )}
                                    </td>
                                    <td></td>
                                    <td>
                                      {summaryData.grandTotal?.base_dr_amount.toFixed(
                                        2
                                      )}
                                    </td>
                                    <td>
                                      {summaryData.grandTotal?.base_cr_amount.toFixed(
                                        2
                                      )}
                                    </td>
                                    <td>
                                      {summaryData.grandTotal?.base_balance.toFixed(
                                        2
                                      )}
                                    </td>
                                  </tr>
                                ) : (
                                  ""
                                )}
                              </tbody>
                              {/* <tfoot className="report-tfoot-table">
                                <tr>
                                  <td
                                    colspan="4"
                                    className="border-right-teble"
                                  >
                                    <b>Balance</b>
                                  </td>
                                  <td
                                    colspan="3"
                                    className="border-right-teble"
                                  >
                                    <b>
                                      {parseFloat(
                                        ledgerData.grandData?.balance
                                      ).toFixed(2)}
                                    </b>
                                  </td>
                                  <td colspan="3">
                                    <b>
                                      {parseFloat(
                                        ledgerData.grandData?.base_balance
                                      ).toFixed(2)}
                                    </b>
                                  </td>
                                </tr>
                              </tfoot> */}
                            </table>
                          )}
                        </div>
                      </Paper>
                    </>
                  ) : (
                    ""
                  )}

                  {afterSubmit.submit == true &&
                  afterSubmit.name == "ledgerReport" &&
                  info.type == "ledgerReport" ? (
                    <>
                      <Paper
                        elevation={1}
                        style={{ borderRadius: "10px", marginTop: "10px" }}
                        className="w-100 "
                      >
                        <div style={{ padding: "1rem", overflow: "auto" }}>
                          {ledgerData.data.length == 0 ? (
                            <div className="report-noData-table">
                              <span>There are no records to display</span>{" "}
                            </div>
                          ) : (
                            <table className="report-table">
                              <tr>
                                <th
                                  colspan="3"
                                  className="border-right-teble"
                                ></th>

                                <th
                                  colspan="4"
                                  className="text-center border-right-teble"
                                >
                                  Entered Currency
                                </th>
                                <th colspan="4" className="text-center">
                                  Base Currency
                                </th>
                              </tr>
                              <tr>
                                <th>Date</th>
                                <th>perticular</th>
                                <th className="border-right-teble">
                                  description
                                </th>
                                <th>Currency</th>

                                <th>Debit Amount</th>
                                <th>Credit Amount</th>
                                <th className="border-right-teble">Balance</th>
                                <th>Currency</th>

                                <th> Debit Amount</th>
                                <th> Credit Amount</th>
                                <th>Base Balance</th>
                              </tr>
                              <tbody>
                                {ledgerData?.data.map((item, index) => {
                                  return (
                                    <tr>
                                      <td>{item.date}</td>
                                      <td style={{ minWidth: "190px" }}>
                                        {item.perticular}
                                      </td>
                                      <td className="border-right-teble">
                                        {item.description}
                                      </td>
                                      <td>{item.entered_currency}</td>
                                      <td>
                                        {parseFloat(item.dr_amount).toFixed(2)}
                                      </td>
                                      <td>
                                        {parseFloat(item.cr_amount).toFixed(2)}
                                      </td>
                                      <td className="border-right-teble">
                                        {parseFloat(item.balance).toFixed(2)}
                                      </td>
                                      <td>{item.base_currency}</td>
                                      <td>
                                        {parseFloat(
                                          item.base_dr_amount
                                        ).toFixed(2)}
                                      </td>
                                      <td>
                                        {parseFloat(
                                          item.base_cr_amount
                                        ).toFixed(2)}
                                      </td>
                                      <td>
                                        {parseFloat(item.base_balance).toFixed(
                                          2
                                        )}
                                      </td>
                                    </tr>
                                  );
                                })}
                                {ledgerData?.data.length > 0 &&
                                ledgerData.grandData.dr_amount ? (
                                  <tr className="ltdBold">
                                    {/* <td></td>
                                  <td></td> */}
                                    <td
                                      colspan="3"
                                      className="border-right-teble"
                                    >
                                      {" "}
                                      Total
                                    </td>
                                    <td></td>
                                    <td>
                                      {ledgerData.grandData?.dr_amount.toFixed(
                                        2
                                      )}
                                    </td>
                                    <td>
                                      {ledgerData.grandData?.cr_amount.toFixed(
                                        2
                                      )}
                                    </td>
                                    <td className="border-right-teble">
                                      {ledgerData.grandData?.balance.toFixed(2)}
                                    </td>
                                    <td></td>
                                    <td>
                                      {ledgerData.grandData?.base_dr_amount.toFixed(
                                        2
                                      )}
                                    </td>
                                    <td>
                                      {ledgerData.grandData?.base_cr_amount.toFixed(
                                        2
                                      )}
                                    </td>
                                    <td>
                                      {ledgerData.grandData?.base_balance.toFixed(
                                        2
                                      )}
                                    </td>
                                  </tr>
                                ) : (
                                  ""
                                )}
                              </tbody>
                              {/* <tfoot className="report-tfoot-table">
                                <tr>
                                  <td
                                    colspan="4"
                                    className="border-right-teble"
                                  >
                                    <b>Balance</b>
                                  </td>
                                  <td
                                    colspan="3"
                                    className="border-right-teble"
                                  >
                                    <b>
                                      {parseFloat(
                                        ledgerData.grandData?.balance
                                      ).toFixed(2)}
                                    </b>
                                  </td>
                                  <td colspan="3">
                                    <b>
                                      {parseFloat(
                                        ledgerData.grandData?.base_balance
                                      ).toFixed(2)}
                                    </b>
                                  </td>
                                </tr>
                              </tfoot> */}
                            </table>
                          )}
                        </div>
                      </Paper>
                    </>
                  ) : (
                    ""
                  )}
                  {info.type == "trialBalance" &&
                  afterSubmit.submit == true &&
                  afterSubmit.name == "trialBalance" ? (
                    <Paper
                      elevation={1}
                      style={{ borderRadius: "10px", marginTop: "10px" }}
                      className="w-100 "
                    >
                      <div style={{ padding: "1rem", overflow: "auto" }}>
                        <table className="report-table">
                          <tr>
                            <th> </th>
                            <th>Main Part</th>
                            <th>Group Name</th>
                            <th>Group Name O</th>
                            <th>Child Group</th>
                            <th>Amount</th>
                            <th>Base Amount</th>
                          </tr>
                          <tbody>
                            {trialData?.data.map((item, index) => {
                              return (
                                <>
                                  <tr>
                                    <td style={{ minWidth: "30px" }}>
                                      {trialDU.parent[index] == true ? (
                                        <KeyboardArrowUpIcon
                                          onClick={() => {
                                            trialDU.parent[index] = false;
                                            setTrialDU({
                                              ...trialDU,
                                            });
                                          }}
                                        />
                                      ) : (
                                        <KeyboardArrowDownIcon
                                          onClick={() => {
                                            trialDU.parent[index] = true;
                                            setTrialDU({
                                              ...trialDU,
                                            });
                                          }}
                                        />
                                      )}
                                    </td>
                                    <td>{item.part_name}</td>
                                    <td>{item.group_name}</td>
                                    <td>{item.group_o_name}</td>
                                    <td>{item.child_group_name}</td>

                                    <td>
                                      {parseFloat(item.total_amount).toFixed(2)}
                                    </td>
                                    <td>
                                      {parseFloat(
                                        item.total_base_amount
                                      ).toFixed(2)}
                                    </td>
                                  </tr>

                                  {trialDU.parent[index] == true ? (
                                    <tr>
                                      <td colSpan="7">
                                        {getPerentChild(item.group_id, index)}
                                      </td>
                                    </tr>
                                  ) : (
                                    ""
                                  )}
                                </>
                              );
                            })}
                          </tbody>
                          {trialTotal(trialData?.data)}
                        </table>
                      </div>
                    </Paper>
                  ) : (
                    ""
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
};
