import {
  Autocomplete,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextareaAutosize,
  TextField,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ColorButton, popupCenter } from "../common/CustomElement";
import { IsApprove, Url } from "../global";
import { useSelector, useDispatch } from "react-redux";
import { checkLogin } from "../redux/Action";
import CommonLogin from "../common/CommonLogin";

const AddExpense = (permission) => {
  // const changeLogin = useSelector((state) => state.changeLogin);
  const dispatch = useDispatch();
  const { id } = useParams();
  const navigate = useNavigate();

  const [mainLoader, setMainLoader] = useState({
    country: false,
    city: false,
    currencyName: false,
    commonFormet: false,
    state: false,
    editPage: false,
    salesman: true,
  });
  const [countryData, setCountryData] = useState({
    country: [],
    city: [],
    state: [],
    location: [],
    company_list: [],
    option: "",
    nameBank: [],
    getledger: [],
    currencyName: [],
    commonFormet: [],
    salesman: [],
  });
  const [info, setInfo] = useState({
    address: [],
    nameBank: "",
    particulars: "",
    currencyName: "",
    company_name: "",
    location: "",
    doc: "",
    salesman: "",
    option: "credit",
    docChq: "",
    remarks: "",
    entryDate: "",
    isLodear: false,
  });
  const input1 = (event) => {
    var { name, value } = event.target;
    setInfo((prevalue) => {
      return {
        ...prevalue,
        [name]: value,
      };
    });
  };

  const [infoTrue, setinfoTrue] = useState({
    nameBank: false,
    currency: false,
    doc: false,
    docChq: false,
    entryDate: false,
    remarks: false,
  });
  const trueFalse = (event) => {
    var { name, value } = event.target;
    setinfoTrue((prevalue) => {
      return {
        ...prevalue,
        [name]: true,
      };
    });
  };
  const getLedger = (prop) => {
    const param = new FormData();
    var test = "";
    info.address.map((item, index) => {
      if (index == info.address.length - 1) {
        test += `${item.Particulars.ledger_id}`;
      } else {
        test += `${item.Particulars.ledger_id},`;
      }
    });
    if (IsApprove !== "") {
      param.append("is_app", IsApprove.is_app);
      param.append("AADMIN_LOGIN_ID", IsApprove.AADMIN_LOGIN_ID);
      param.append("role_id", IsApprove.AADMIN_LOGIN_ROLE_ID);
    }
    param.append("skip_ledger_id", test);

    param.append("action", "get_all_ledger");
    param.append("ledger_name", prop);
    param.append(
      "skip_ledger_id",
      info.nameBank == "" || info.nameBank == null || info.nameBank == undefined
        ? ""
        : info.nameBank.ledger_id
    );

    axios.post(Url + "/ajaxfiles/common_api.php", param).then((res) => {
      if (res.data.message == "Session has been expired") {
        localStorage.setItem("login", true);
        navigate("/login");
      }
      if (res.data.status == "error") {
        // toast.error(res.data.message);
      } else {
        countryData.getledger = res.data.data;
        setCountryData({ ...countryData });
      }
    });
  };
  useEffect(() => {
    getCompanyData();
    getSalesman();
    const param = new FormData();
    mainLoader.country = true;
    if (IsApprove !== "") {
      param.append("is_app", IsApprove.is_app);
      param.append("AADMIN_LOGIN_ID", IsApprove.AADMIN_LOGIN_ID);
      param.append("role_id", IsApprove.AADMIN_LOGIN_ROLE_ID);
    }
    setMainLoader({ ...mainLoader });
    axios.post(Url + "/datatable/get_countries.php", param).then((res) => {
      if (res.data.message == "Session has been expired") {
        localStorage.setItem("login", true);
        navigate("/login");
      }
      if (res.data.status == "error") {
        // toast.error(res.data.message);
      } else {
        mainLoader.country = false;
        setMainLoader({ ...mainLoader });
        countryData.country = res.data.aaData;
        setCountryData({ ...countryData });
      }
    });
  }, []);
  const getSalesman = () => {
    const param1 = new FormData();
    if (IsApprove !== "") {
      param1.append("is_app", IsApprove.is_app);
      param1.append("AADMIN_LOGIN_ID", IsApprove.AADMIN_LOGIN_ID);
      param1.append("role_id", IsApprove.AADMIN_LOGIN_ROLE_ID);
    }

    param1.append("action", "get_salesman_list");
    // mainLoader.currencyName = true;
    // setMainLoader({ ...mainLoader });
    axios.post(Url + "/ajaxfiles/employee_manage.php", param1).then((res) => {
      if (res.data.message == "Session has been expired") {
        localStorage.setItem("login", true);
        navigate("/login");
      }
      if (res.data.status == "error") {
        // toast.error(res.data.message);
      } else {
        mainLoader.salesman = false;
        setMainLoader({ ...mainLoader });
        countryData.salesman = res.data.data;
        setCountryData({ ...countryData });
      }
    });
  };
  const getCurrenctRate = (prop) => {
    const param1 = new FormData();
    if (IsApprove !== "") {
      param1.append("is_app", IsApprove.is_app);
      param1.append("AADMIN_LOGIN_ID", IsApprove.AADMIN_LOGIN_ID);
      param1.append("role_id", IsApprove.AADMIN_LOGIN_ROLE_ID);
    }
    param1.append("company_id", prop.company_id);

    param1.append("action", "get_currency_rate");
    // mainLoader.currencyName = true;
    // setMainLoader({ ...mainLoader });
    axios.post(Url + "/ajaxfiles/common_api.php", param1).then((res) => {
      if (res.data.message == "Session has been expired") {
        localStorage.setItem("login", true);
        navigate("/login");
      }
      if (res.data.status == "error") {
        // toast.error(res.data.message);
      } else {
        // mainLoader.currencyName = false;
        // setMainLoader({ ...mainLoader });
        countryData.currencyName = res.data.data;
        setCountryData({ ...countryData });
      }
    });
  };
  useEffect(() => {
    if (!id) {
      console.log("sa", permission.companyLocation.company.company_id);
      if (permission.companyLocation.company.company_id) {
        info.company_name = permission.companyLocation.company;
        setInfo({ ...info });
        getLocations(permission.companyLocation.company, "yes");
      }
    } else {
      const param = new FormData();
      if (IsApprove !== "") {
        param.append("is_app", IsApprove.is_app);
        param.append("AADMIN_LOGIN_ID", IsApprove.AADMIN_LOGIN_ID);
        param.append("role_id", IsApprove.AADMIN_LOGIN_ROLE_ID);
      }
      param.append("entry_id", id);
      mainLoader.editPage = true;
      setMainLoader({ ...mainLoader });
      axios
        .post(Url + "/ajaxfiles/view_receipt_payment_entry.php", param)
        .then((res) => {
          if (res.data.status == "error") {
            toast.error(res.data.message);
          } else {
            getCurrenctRate({
              company_id: res.data.data.company_id,
              company_name: res.data.data.company_name,
            });
            toast.success(res.data.message);
            info.nameBank = {
              ledger_id: res.data.data.ledger_id,
              ledger_name: res.data.data.ledger_name,
            };
            info.currencyName = {
              currency_id: res.data.data.currency_id,
              currency_shortname: res.data.data.currency_name,
              currency_name: res.data.data.currency_full_name,

              rate: res.data.data.currency_rate,
            };
            info.particulars = res.data.data.perticulars[0].Particulars;
            info.company_name = {
              company_id: res.data.data.company_id,
              company_name: res.data.data.company_name,
            };
            info.location = {
              location_id: res.data.data.location_id,
              location_name: res.data.data.location_name,
            };
            info.salesman = {
              sales_manager_id: res.data.data.sales_manager_id,
              sales_manager_name: res.data.data.sales_manager_name,
            };
            info.doc = res.data.data.document_no;
            info.option = res.data.data.entry_cr_dr == 1 ? "credit" : "debit";
            info.docChq = res.data.data.document_refrence;
            info.remarks = res.data.data.remarks;
            info.entryDate = res.data.data.entry_date;
            info.address = res.data.data.perticulars;

            info.isLodear = false;
            setInfo({ ...info });
            mainLoader.editPage = false;
            setMainLoader({ ...mainLoader });

            // getCityData(res.data.data.company_state);
          }
        });
    }
  }, [id]);
  const getBankLedger = (prop) => {
    const param = new FormData();
    param.append("action", "get_all_ledger");
    param.append("skip_ledger_id", 0);

    param.append("ledger_name", prop);
    if (IsApprove !== "") {
      param.append("is_app", IsApprove.is_app);
      param.append("AADMIN_LOGIN_ID", IsApprove.AADMIN_LOGIN_ID);
      param.append("role_id", IsApprove.AADMIN_LOGIN_ROLE_ID);
    }
    axios.post(Url + "/ajaxfiles/common_api.php", param).then((res) => {
      if (res.data.message == "Session has been expired") {
        localStorage.setItem("login", true);
        navigate("/login");
      }
      if (res.data.status == "error") {
        // toast.error(res.data.message);
      } else {
        countryData.nameBank = res.data.data;
        setCountryData({ ...countryData });
      }
    });
  };
  const particularsDetete = (index) => {
    const address = info.address.filter((e, ind) => {
      return index != ind;
    });
    info.address = address;
    info.particulars = "";
    setInfo({ ...info });
  };
  const submitAddFrom = () => {
    const pTrue = () => {
      var true1 = false;
      for (var i = 0; i < info.address.length; i++) {
        if (info.address[i].amount == "" || info.address[i].amount == 0) {
          true1 = info.address[i].Particulars.ledger_name;
          // console.log(i, info);
          return true1;
        }

        if (i == info.address.length - 1 && info.address[i].amount !== "") {
          console.log(i, info);
          true1 = "Nothing";
          return true1;
        }
      }

      return false;
    };

    const pTrueRate = () => {
      var true1 = false;
      for (var i = 0; i < info.address.length; i++) {
        if (info.address[i].rate == "" || info.address[i].rate == 0) {
          true1 = info.address[i].Particulars.ledger_name;
          // console.log(i, info);
          return true1;
        }

        if (i == info.address.length - 1 && info.address[i].rate !== "") {
          console.log(i, info);
          true1 = "Nothing";
          return true1;
        }
      }

      return false;
    };
    console.log(pTrue());
    const parArray = () => {
      var arr = [];
      info.address.map((item, index) => {
        arr.push({
          Particulars: item.Particulars,
          amount: item.amount,
          base_amount: item.amount / item.rate,
          rate: item.rate,
        });
      });
      return JSON.stringify(arr);
    };
    if (info.company_name == "" || info.company_name == null) {
      toast.error("Company Name is required");
    } else if (info.location == "" || info.location == null) {
      toast.error("Location Name is required");
    } else if (info.option == "") {
      toast.error("Select Payment or Receipt");
    } else if (info.nameBank == "") {
      toast.error("Name Of Account is required");
    } else if (info.currency == "") {
      toast.error("currency is required");
    } else if (info.entryDate == "") {
      toast.error("Entry Date is required");
    } else if (
      info.address == "" ||
      info.address == [] ||
      info.address.length == 0 ||
      info.address.length == null
    ) {
      toast.error("Particulars is required");
    } else if (pTrue() !== "Nothing") {
      toast.error(`${pTrue()} of amount is required`);
    } else if (pTrueRate() !== "Nothing") {
      toast.error(`${pTrueRate()} of Conversion Rate is required`);
    } else {
      const param = new FormData();
      if (IsApprove !== "") {
        param.append("is_app", IsApprove.is_app);
        param.append("AADMIN_LOGIN_ID", IsApprove.AADMIN_LOGIN_ID);
        param.append("role_id", IsApprove.AADMIN_LOGIN_ROLE_ID);
      }
      param.append("company_id", info.company_name.company_id);
      param.append("location_id", info.location.location_id);
      param.append("entry_cr_dr", info.option == "debit" ? 2 : 1);
      param.append("entry_type", 4);

      param.append("bank_ledger_id", info.nameBank.ledger_id);
      param.append("currency_id", info.currencyName.currency_id);

      param.append("document_no", info.doc);
      param.append("document_refrence", info.docChq);
      param.append("entry_date", info.entryDate);
      param.append("manager_id", info.salesman ? info.salesman.manager_id : "");

      param.append("particulars", parArray());
      param.append("remarks", info.remarks);
      if (id == undefined) {
      } else {
        param.append("entry_id", id);
      }
      info.isLodear = true;
      setInfo({ ...info });
      axios
        .post(
          Url +
            `${
              id == undefined
                ? "/ajaxfiles/expense_add.php"
                : "/ajaxfiles/expense_update.php"
            }`,
          param
        )
        .then((res) => {
          if (res.data.status == "error") {
            toast.error(res.data.message);
            info.isLodear = false;
            setInfo({ ...info });
          } else {
            toast.success(res.data.message);
            info.isLodear = false;
            setInfo({ ...info });
            navigate("/expenseEntryList");
          }
        });
    }
  };
  const getCompanyData = () => {
    const param = new FormData();
    if (IsApprove !== "") {
      param.append("is_app", IsApprove.is_app);
      param.append("AADMIN_LOGIN_ID", IsApprove.AADMIN_LOGIN_ID);
      param.append("role_id", IsApprove.AADMIN_LOGIN_ROLE_ID);
    }

    axios.post(Url + "/ajaxfiles/get_company_list.php", param).then((res) => {
      if (res.data.message == "Session has been expired") {
        localStorage.setItem("login", true);
        navigate("/login");
      }
      if (res.data.status == "error") {
        // toast.error(res.data.message);
      } else {
        countryData.company_list = res.data.data;
        setCountryData({ ...countryData });
      }
    });
  };
  const getLocations = (prop, checkL) => {
    if (prop == "" || prop == null) {
      info.location = "";
      setInfo({ ...info });
      countryData.location = [];
      setCountryData({ ...countryData });
    } else {
      const param = new FormData();
      if (IsApprove !== "") {
        param.append("is_app", IsApprove.is_app);
        param.append("AADMIN_LOGIN_ID", IsApprove.AADMIN_LOGIN_ID);
        param.append("role_id", IsApprove.AADMIN_LOGIN_ROLE_ID);
      }
      param.append("company_id", prop.company_id);

      axios
        .post(Url + "/ajaxfiles/get_location_list.php", param)
        .then((res) => {
          if (res.data.message == "Session has been expired") {
            localStorage.setItem("login", true);
            navigate("/login");
          }
          if (res.data.status == "error") {
            toast.error(res.data.message);
          } else {
            if (checkL == "yes") {
              info.location = permission.companyLocation.location;
              setInfo({ ...info });
            } else {
              info.location = "";
              setInfo({ ...info });
            }
            countryData.location = res.data.data;

            setCountryData({ ...countryData });
            // info.company_list = res.data.data;
            // setInfo({ ...info });
          }
        });
    }
  };
  // console.log("id", id);
  return (
    <div className="app-content--inner">
      <div className="app-content--inner__wrapper mh-100-vh">
        {mainLoader.country ||
        mainLoader.currencyName ||
        mainLoader.salesman ||
        (id !== undefined && mainLoader.editPage) ? (
          <div>
            {" "}
            <span class="loader1"></span>
          </div>
        ) : (
          <div style={{ opacity: 1 }}>
            <Grid container>
              <Grid item md={12}>
                <p className="main-heading">
                  {id == undefined ? "Add Expense" : "Edit Expense"}
                  &nbsp;
                </p>
                <Grid container spacing={4}>
                  <Grid item md={12}>
                    <Paper
                      elevation={1}
                      style={{ borderRadius: "10px" }}
                      className="w-100 "
                    >
                      <div className="card-header d-flex align-items-center justify-content-between card-header-alt p-3">
                        <h5 className="font-weight-bold mb-0 text-dark">
                          Company Details
                        </h5>
                      </div>
                      <div className="divider"></div>
                      <div className="card-body position-relative">
                        <Grid container spacing={1}>
                          {id == undefined ? (
                            <Grid item md={6}>
                              <Autocomplete
                                disablePortal
                                options={countryData.company_list}
                                value={info.company_name}
                                getOptionLabel={(option) =>
                                  option ? option.company_name : ""
                                }
                                onChange={(event, newValue) => {
                                  getLocations(newValue);
                                  getCurrenctRate(newValue);
                                  info.company_name = newValue;
                                  setInfo({ ...info });
                                }}
                                sx={{ padding: "0px" }}
                                className="w-100"
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Company"
                                    size="small"
                                    onBlur={trueFalse}
                                    name="company_name"
                                    helperText={
                                      (info.company_name == null ||
                                        info.company_name == "") &&
                                      infoTrue.company_name
                                        ? "Company is required"
                                        : ""
                                    }
                                    error={
                                      (info.company_name == null ||
                                        info.company_name == "") &&
                                      infoTrue.company_name
                                        ? true
                                        : false
                                    }
                                    sx={{ padding: "0px" }}
                                    variant="outlined"
                                  />
                                )}
                              />
                            </Grid>
                          ) : (
                            <Grid item md={6}>
                              <TextField
                                disabled
                                label="Company"
                                size="small"
                                name="company"
                                className="w-100"
                                value={info.company_name.company_name}
                                sx={{ padding: "0px" }}
                                variant="outlined"
                              />
                            </Grid>
                          )}
                          {id == undefined ? (
                            <Grid item md={6}>
                              <Autocomplete
                                disablePortal
                                options={countryData.location}
                                value={info.location}
                                getOptionLabel={(option) =>
                                  option ? option.location_name : ""
                                }
                                onChange={(event, newValue) => {
                                  info.location = newValue;
                                  setInfo({ ...info });
                                }}
                                sx={{ padding: "0px" }}
                                className="w-100"
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Location"
                                    size="small"
                                    onBlur={trueFalse}
                                    name="location"
                                    helperText={
                                      (info.location == null ||
                                        info.location == "") &&
                                      infoTrue.location
                                        ? "Location is required"
                                        : ""
                                    }
                                    error={
                                      (info.location == null ||
                                        info.location == "") &&
                                      infoTrue.location
                                        ? true
                                        : false
                                    }
                                    sx={{ padding: "0px" }}
                                    variant="outlined"
                                  />
                                )}
                              />
                            </Grid>
                          ) : (
                            <Grid item md={6}>
                              <TextField
                                disabled
                                label="Location"
                                size="small"
                                name="location"
                                className="w-100"
                                value={info.location.location_name}
                                sx={{ padding: "0px" }}
                                variant="outlined"
                              />
                            </Grid>
                          )}
                        </Grid>
                      </div>
                    </Paper>
                  </Grid>
                  <Grid item md={6}>
                    <Paper
                      elevation={1}
                      style={{ borderRadius: "10px" }}
                      className="w-100 "
                    >
                      <div className="card-header d-flex align-items-center justify-content-between card-header-alt p-3">
                        <h5 className="font-weight-bold mb-0 text-dark">
                          Payable To Expense
                        </h5>
                        <ColorButton
                          // onClick={() => {
                          //   const myWindow =  (
                          //     "/Ledger_master",
                          //     "",
                          //     "width=800,height=320"
                          //   );
                          // }}
                          onClick={() => {
                            popupCenter({
                              url: "/Ledger_master",
                              title: "Add Ledger",
                              w: 900,
                              h: 500,
                            });
                          }}
                        >
                          Add Ledger
                        </ColorButton>
                      </div>
                      <div className="divider"></div>
                      <div className="card-body position-relative">
                        <Grid container spacing={1}>
                          <Grid item md={12}>
                            <FormControl>
                              <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                name="option"
                                row
                                value={info.option}
                                onChange={input1}
                              >
                                <FormControlLabel
                                  value="credit"
                                  control={<Radio />}
                                  label="Credit"
                                />
                                <FormControlLabel
                                  value="debit"
                                  control={<Radio />}
                                  label="Debit"
                                />
                              </RadioGroup>
                            </FormControl>
                          </Grid>
                          <Grid item md={12}>
                            <Autocomplete
                              disablePortal
                              options={countryData.nameBank}
                              value={info.nameBank}
                              getOptionLabel={(option) =>
                                option ? option.ledger_name : ""
                              }
                              onChange={(event, newValue) => {
                                if (newValue == null) {
                                  info.nameBank = newValue;
                                  setInfo({ ...info });
                                } else {
                                  info.nameBank = newValue;
                                  setInfo({ ...info });
                                }
                              }}
                              sx={{ padding: "0px" }}
                              className="w-100"
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Name Of Account"
                                  size="small"
                                  onBlur={trueFalse}
                                  name="nameBank"
                                  onChange={(e) => {
                                    if (e.target.value.length > 2) {
                                      getBankLedger(e.target.value);
                                    }
                                  }}
                                  helperText={
                                    (info.nameBank == null ||
                                      info.nameBank == "") &&
                                    infoTrue.nameBank
                                      ? "Name Of Account is required"
                                      : ""
                                  }
                                  error={
                                    (info.nameBank == null ||
                                      info.nameBank == "") &&
                                    infoTrue.nameBank
                                      ? true
                                      : false
                                  }
                                  sx={{ padding: "0px" }}
                                  variant="outlined"
                                />
                              )}
                            />
                          </Grid>
                          <Grid item md={12}>
                            <Autocomplete
                              disablePortal
                              options={countryData.currencyName}
                              value={info.currencyName}
                              getOptionLabel={(option) =>
                                option ? option.currency_name : ""
                              }
                              onChange={(event, newValue) => {
                                info.address = info.address.map(
                                  (item, index) => {
                                    if (
                                      newValue == null ||
                                      newValue == "" ||
                                      newValue == undefined
                                    ) {
                                      item.rate = "";
                                      item.base_amount = "";
                                      return item;
                                    } else {
                                      item.rate = newValue.rate;
                                      item.base_amount =
                                        item.amount / newValue.rate;

                                      return item;
                                    }
                                  }
                                );
                                if (newValue == null) {
                                  info.currencyName = newValue;
                                  setInfo({ ...info });
                                } else {
                                  info.currencyName = newValue;
                                  setInfo({ ...info });
                                }
                              }}
                              sx={{ padding: "0px" }}
                              className="w-100"
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Currency"
                                  size="small"
                                  onBlur={trueFalse}
                                  name="currencyName"
                                  helperText={
                                    (info.currencyName == null ||
                                      info.currencyName == "") &&
                                    infoTrue.currencyName
                                      ? "currency is required"
                                      : ""
                                  }
                                  error={
                                    (info.currencyName == null ||
                                      info.currencyName == "") &&
                                    infoTrue.currencyName
                                      ? true
                                      : false
                                  }
                                  sx={{ padding: "0px" }}
                                  variant="outlined"
                                />
                              )}
                            />
                          </Grid>
                        </Grid>
                      </div>
                    </Paper>
                  </Grid>
                  <Grid item md={6}>
                    <Paper
                      elevation={1}
                      style={{ borderRadius: "10px" }}
                      className="w-100 "
                    >
                      <div className="card-header d-flex align-items-center justify-content-between card-header-alt p-3">
                        <h5 className="font-weight-bold mb-0 text-dark">
                          Other Details
                        </h5>
                      </div>
                      <div className="divider"></div>
                      <div className="card-body position-relative">
                        <Grid container spacing={1}>
                          <Grid item md={12}>
                            <TextField
                              id="standard-basic"
                              label="Entry Date"
                              name="entryDate"
                              value={info.entryDate}
                              type="date"
                              className="w-100"
                              variant="outlined"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              onChange={input1}
                              onBlur={trueFalse}
                              error={
                                info.entryDate == "" && infoTrue.entryDate
                                  ? true
                                  : false
                              }
                              helperText={
                                info.entryDate == "" && infoTrue.entryDate
                                  ? "Entry Date is required"
                                  : ""
                              }
                            />
                          </Grid>
                          <Grid item md={12}>
                            <Autocomplete
                              disablePortal
                              options={countryData.salesman}
                              value={info.salesman}
                              getOptionLabel={(option) =>
                                option ? option.manager_name : ""
                              }
                              onChange={(event, newValue) => {
                                info.salesman = newValue;
                                setInfo({ ...info });
                              }}
                              sx={{ padding: "0px" }}
                              className="w-100"
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Salesman Name (Optional)"
                                  size="small"
                                  name="salesman"
                                  sx={{ padding: "0px" }}
                                  variant="outlined"
                                />
                              )}
                            />
                          </Grid>
                          <Grid item md={12}>
                            <TextField
                              id="standard-basic"
                              label="Document"
                              name="doc"
                              value={info.doc}
                              className="w-100"
                              variant="outlined"
                              onChange={input1}
                              onBlur={trueFalse}
                            />
                          </Grid>
                          <Grid item md={12}>
                            <TextField
                              id="standard-basic"
                              label="DOC/CHQ REF"
                              name="docChq"
                              value={info.docChq}
                              className="w-100"
                              variant="outlined"
                              onChange={input1}
                              onBlur={trueFalse}
                            />
                          </Grid>
                        </Grid>
                      </div>
                    </Paper>
                  </Grid>
                </Grid>
              </Grid>

              <Grid container spacing={1} className="mt-10">
                <Grid item md={12}>
                  <Paper
                    elevation={1}
                    style={{ borderRadius: "10px" }}
                    className="w-100"
                  >
                    <div className="card-header d-flex align-items-center justify-content-between card-header-alt p-3">
                      <h5 className="font-weight-bold mb-0 text-dark">
                        Ledger List
                      </h5>
                    </div>
                    <div className="divider"></div>
                    <div className="card-body position-relative">
                      <Grid container spacing={1}>
                        <Grid item md={12}>
                          <Autocomplete
                            disablePortal
                            options={countryData.getledger}
                            value={info.particulars}
                            disabled={
                              info.particulars == "" ||
                              info.particulars == null ||
                              info.particulars == undefined
                                ? false
                                : true
                            }
                            getOptionLabel={(option) =>
                              option ? option.ledger_name : ""
                            }
                            onChange={(event, newValue) => {
                              if (newValue == null) {
                                info.particulars = "";
                                setInfo({ ...info });
                              } else {
                                info.particulars = newValue;
                                info.address.push({
                                  Particulars: newValue,
                                  amount: "",
                                  base_amount: "",
                                  rate: info.currencyName.rate,
                                });
                                setInfo({ ...info });
                              }
                              countryData.getledger = [];
                              setCountryData({ ...countryData });
                            }}
                            sx={{ padding: "0px" }}
                            className="w-100"
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Particulars"
                                size="small"
                                onBlur={trueFalse}
                                onChange={(e) => {
                                  if (e.target.value.length > 2) {
                                    getLedger(e.target.value);
                                  }
                                }}
                                name="particulars"
                                sx={{ padding: "0px" }}
                                variant="outlined"
                              />
                            )}
                          />
                        </Grid>
                        {info.address.length == 0 ? (
                          ""
                        ) : (
                          <Grid item md={12}>
                            <div style={{ overflowY: "scroll" }}>
                              <Table aria-label="simple table">
                                <TableHead>
                                  <TableRow>
                                    <TableCell align="center">
                                      Particulars
                                    </TableCell>
                                    <TableCell align="center">Amount</TableCell>
                                    <TableCell align="center">
                                      Conversion Rate
                                    </TableCell>
                                    <TableCell align="center">
                                      Base Amount
                                    </TableCell>
                                    <TableCell align="center">Action</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {info.address.map((item, index) => {
                                    return (
                                      <TableRow
                                        key={index}
                                        className="trcashEntry"
                                      >
                                        <TableCell align="center">
                                          {item.Particulars.ledger_name}
                                        </TableCell>
                                        <TableCell align="center">
                                          <TextField
                                            id="standard-basic"
                                            label="Enter Amount"
                                            name="amount"
                                            value={item.amount}
                                            className="w-100"
                                            variant="outlined"
                                            onChange={(e) => {
                                              if (
                                                Number(e.target.value) ||
                                                e.target.value == "-"
                                              ) {
                                                info.address[index].amount =
                                                  e.target.value;
                                                info.address[
                                                  index
                                                ].base_amount =
                                                  item.amount /
                                                  info.currencyName.rate;
                                                setInfo({ ...info });
                                              } else if (e.target.value == "") {
                                                info.address[index].amount = "";
                                                info.address[
                                                  index
                                                ].base_amount = "";
                                                setInfo({ ...info });
                                              }
                                            }}
                                          />
                                        </TableCell>
                                        <TableCell align="center">
                                          <TextField
                                            id="standard-basic"
                                            label="Conversion Rate"
                                            name="rate"
                                            value={item.rate}
                                            className="w-100"
                                            InputLabelProps={{
                                              shrink: true,
                                            }}
                                            variant="outlined"
                                            onChange={(e) => {
                                              if (Number(e.target.value)) {
                                                info.address[index].rate =
                                                  e.target.value;
                                                info.address[
                                                  index
                                                ].base_amount =
                                                  item.amount / e.target.value;
                                                setInfo({ ...info });
                                              } else if (e.target.value == "") {
                                                info.address[index].rate = "";
                                                info.address[
                                                  index
                                                ].base_amount = "";
                                                setInfo({ ...info });
                                              }
                                            }}
                                          />
                                        </TableCell>
                                        <TableCell align="center">
                                          {info.currencyName == "" ||
                                          info.currencyName == null ||
                                          info.currencyName == undefined ||
                                          item.rate == "" ? (
                                            ""
                                          ) : (
                                            <>
                                              {" "}
                                              {item.amount == "" ||
                                              info.currencyName == ""
                                                ? ""
                                                : (
                                                    item.amount / item.rate
                                                  ).toFixed(2)}
                                            </>
                                          )}
                                        </TableCell>
                                        <TableCell align="center">
                                          <Button className="cursor-pointer p-0 p-md-2 rounded-circle text-muted">
                                            <DeleteIcon
                                              sx={{ color: "red" }}
                                              onClick={() => {
                                                particularsDetete(index);
                                              }}
                                            />
                                          </Button>
                                        </TableCell>
                                      </TableRow>
                                    );
                                  })}
                                  <TableRow className="trcashEntry">
                                    <TableCell align="center">
                                      <b>Total Amount</b>
                                    </TableCell>
                                    <TableCell align="center">
                                      <b>
                                        {" "}
                                        {info.address.reduce(
                                          (a, v) =>
                                            (a = Number(a) + Number(v.amount)),
                                          ""
                                        )}
                                      </b>
                                    </TableCell>
                                    <TableCell></TableCell>

                                    <TableCell align="center">
                                      {info.currencyName == "" ||
                                      info.currencyName == null ||
                                      info.currencyName == undefined ? (
                                        ""
                                      ) : (
                                        <b>
                                          {info.address
                                            .reduce(
                                              (a, v) =>
                                                (a =
                                                  Number(a) +
                                                  Number(v.base_amount)),
                                              ""
                                            )
                                            .toFixed(2)}
                                        </b>
                                      )}
                                    </TableCell>
                                    <TableCell align="center">
                                      {/* <Button className="cursor-pointer p-0 p-md-2 rounded-circle text-muted">
                                        <DeleteIcon sx={{ color: "red" }} />
                                      </Button> */}
                                    </TableCell>
                                  </TableRow>
                                </TableBody>
                              </Table>
                            </div>
                          </Grid>
                        )}
                      </Grid>
                    </div>
                  </Paper>
                </Grid>
              </Grid>
              <Grid container spacing={1} className="mt-10">
                <Grid item md={12}>
                  <Paper
                    elevation={1}
                    style={{ borderRadius: "10px" }}
                    className="w-100"
                  >
                    <div className="card-header d-flex align-items-center justify-content-between card-header-alt p-3">
                      <h5 className="font-weight-bold mb-0 text-dark">
                        Remarks
                      </h5>
                    </div>
                    <div className="divider"></div>
                    <div className="card-body position-relative">
                      <Grid container spacing={1}>
                        <Grid md={12}>
                          <TextareaAutosize
                            aria-label="empty textarea"
                            placeholder="Remarks"
                            name="remarks"
                            value={info.remarks}
                            onChange={input1}
                            className="w-100 H-80"
                          />
                          <div className="text-right">
                            <Button
                              variant="contained"
                              color="error"
                              onClick={() => {
                                navigate("/companyDetails");
                              }}
                            >
                              CANCEL
                            </Button>
                            {info.isLodear ? (
                              <Button
                                tabindex="0"
                                size="large"
                                sx={{
                                  padding: "16.5px 50px",
                                  marginLeft: "10px",
                                }}
                                className=" btn-gradient  btn-success "
                                disabled
                              >
                                <svg class="spinner" viewBox="0 0 50 50">
                                  <circle
                                    class="path"
                                    cx="25"
                                    cy="25"
                                    r="20"
                                    fill="none"
                                    stroke-width="5"
                                  ></circle>
                                </svg>
                              </Button>
                            ) : (
                              <Button
                                variant="contained"
                                color="success"
                                onClick={submitAddFrom}
                                sx={{ marginLeft: "10px" }}
                              >
                                SUBMIT
                              </Button>
                            )}
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                  </Paper>
                </Grid>
              </Grid>
            </Grid>
          </div>
        )}
      </div>
    </div>
  );
};

export default AddExpense;
